/*Nav bar menu - Mobile view styles*/
.mobile-menu-theme {
	margin-left: -5px;
	display: none;
}

.responsive-menu {
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.responsive-menu__more-icon {
	margin-right: 10px;
}

.responsive-menu__wrapper {
	background-color: white;
}

.responsive-menu__container {
	color: var(--color-grey-400);
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	min-width: 320px;
	max-width: 320px;
	min-height: 316px;
	overflow: hidden;
	height: auto;
	transition: height 300ms linear;
	display: flex;
}

.homepage-navbar__mobile-nav-link {
	padding: 16px 0;
	border-bottom: 1px solid var(--color-grey-200);
	display: flex;
	justify-content: space-between;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	color: var(--color-grey-600);
	cursor: pointer;
}

.homepage-navbar__mobile-nav-link:first-child {
	padding-top: 0;
}

.responsive-menu__header {
	display: none;
	padding: 40px 32px 32px 32px;
	color: var(--color-grey-400);
}

.responsive-menu__logo {
	color: black;
}

.responsive-menu__footer {
	padding-top: 24px;
	display: flex;
}

.responsive-menu__level1 {
	padding: 24px 40px;
	margin-left: 0;
	opacity: 1;
	transition: margin-left 300ms linear;
	min-width: 320px;
}

.slide-to-left {
	margin-left: -320px;
}

.responsive-menu__level2 {
	min-width: 320px;
	padding: 0 40px 24px 40px;
	max-height: 316px;
	transition: max-height 300ms linear;
}

.responsive-menu__level2--on {
	max-height: 100%;
}

.responsive-menu__level2-header {
	display: flex;
	align-items: center;
	text-transform: uppercase;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	color: var(--color-grey-700);
	border-bottom: 1px solid var(--color-grey-200);
	padding: 8px 12px;
	margin-bottom: 24px;
	width: calc(100% + 80px);
	margin-left: -40px;
}

.homepage-navbar__mobile-sub-nav {
	display: none;
}

.homepage-navbar__mobile-sub-nav--show {
	display: block;
}

/*Nav bar menu - Media Queries*/
@media all and (min-width: 960px) {
	.responsive-menu {
		display: none;
	}
}

@media all and (max-width: 959px) {
	.mobile-menu-theme {
		display: block;
	}

	.responsive-menu {
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}

	.responsive-menu__header {
		display: none;
	}
}

/*Show another header inside the dropdown till iphone plus screens*/
@media (max-width: 767px) {
	.tippy-popper {
		max-width: 100vw !important;
	}
	.responsive-menu__header {
		display: flex;
		justify-content: space-between;
	}
	.responsive-menu__container {
		box-shadow: none;
	}
	.responsive-menu__wrapper {
		margin-top: -48px;
		box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	}
	.responsive-menu__container {
		padding-bottom: 50px;
		min-width: 100vw;
		max-width: 100vw;
	}
	.responsive-menu__level1,
	.responsive-menu__level2 {
		min-width: 100vw;
	}

	.slide-to-left {
		margin-left: -100vw;
	}
}
