.dropdown {
	position: relative;
	display: inline-block;
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	padding: 3px 8px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-800);
	outline: none;
	width: 100%;
	transition: border 300ms ease-in;
	background: white;
	margin-top: 8px;
}

.dropdown__label-row {
	display: flex;
	align-items: center;
}

.dropdown__label {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-800);
	display: block;
	width: 100%;
	font-weight: var(--font-weight-regular);
}

.dropdown__icon {
	display: flex;
	align-items: center;
	color: var(--color-grey-400);
	position: absolute;
	right: 5px;
}

.dropdown__options-container {
	position: absolute;
	/* top: 18px; */
	left: 0;
	z-index: 1;
	background-color: white;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	min-width: 280px;
	width: 100%;
	max-height: 600px;
	overflow-y: auto;
	overflow-x: hidden;
	opacity: 0;
	animation: fadeIn 2000ms var(--timing-function-ease-out-expo) 1 forwards;
	transform-origin: 0% 0%;
	margin-top: 5px;
}

.dropdown__options-container--open {
	opacity: 1;
	padding: 8px 0;
}

.dropdown__option {
	min-height: 40px;
	padding: 8px 0;
	transition-property: background-color;
	transition-duration: 300ms;
}

.dropdown__option--none {
	border-bottom: 1px solid var(--color-grey-200);
}

.dropdown__option-button {
	border: none;
	outline: none;
	width: 100%;
	padding: 0 16px;
	color: var(--color-grey-500);
	cursor: pointer;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	text-align: left;
	transition-property: color;
	transition-duration: 300ms;
}

.dropdown__option:hover {
	background-color: var(--color-brand-lightest);
}

.dropdown__option:hover .dropdown__option-button {
	color: var(--color-grey-800);
}
