.area-experts-grid {
	padding-top: 32px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	grid-auto-rows: 140px;
}

.area-experts-grid .product-person:hover {
	outline: none;
	box-shadow: none;
}