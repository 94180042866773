.edit-product-features-upload {
	height: 154px;
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	background-color: var(--color-grey-100);
	padding: 32px;
	color: var(--color-grey-400);
	text-align: center;
}

.edit-product-features-upload:focus {
	outline: none;
}

.edit-product-features-upload__label {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	text-align: center;
}

.edit-product-features-upload svg {
	margin: 0 0 8px;
}

.edit-product-features-upload__file-text {
	color: var(--color-grey-700);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	text-align: center;
}

.edit-product-features-upload__upload-text {
	color: var(--color-brand);
	text-transform: uppercase;
	margin: 20px 0 30px;
	display: block;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	cursor: pointer;
}

.edit-product-features-upload__label-container {
	margin-bottom: 8px;
	display: flex;
}

.edit-product-features-upload__label-max-size {
	color: var(--color-grey-700);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	margin-left: auto;
}

.edit-product-features-upload__label-text {
	height: 13px;
	width: 151px;
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	text-transform: uppercase;
	letter-spacing: 0.8px;
}

.edit-product-features-upload__loader div {
	margin: 10px auto 8px;
}

.edit-product-features-upload__video-dialog {
	width: auto;
	height: auto;
	padding: 40px 0px 0px 0px;
	position: relative;
}

.edit-product-features-upload__video-dialog .button {
	position: absolute;
	right: 8px;
  top: 8px;
  color: var(--color-grey-500);
  background: white;
}

.edit-product-features-upload__video-dialog .button:hover {
  color: var(--color-grey-800);
}

.edit-product-features-upload__action-links {
  display: flex;
}

.edit-product-features-upload__action-links > * {
  margin: 20px auto 30px;
}

.edit-product-features-upload__action-links .button {
  color: var(--color-grey-700);
}