.area-detail-edit__stat-field:first-of-type {
	width: 100%;
	margin: 0 0 8px 0;
}

.area-detail-edit__stat {
	display: flex;
	justify-content: flex-start;
	margin-bottom: -4px;
	flex-direction: column;
}

.area-detail-edit__stat + .area-detail-edit__stat {
	border-top: 1px solid var(--color-grey-200);
	padding-top: 16px;
}

.area-detail-edit__no-stats {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	letter-spacing: 0.3px;
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
	margin-bottom: 23px;
}

.area-detail__fieldset {
	display: flex;
	padding: 0;
	outline: none;
	border: none;
	margin: 0 0 24px 0;
}

.area-detail__products-section-title {
	text-transform: uppercase;
}