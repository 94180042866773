.error-dialog {
	height: auto;
	max-height: 560px;
}

.error-dialog__title-icon {
	color: var(--color-danger);
	margin-right: 10px;
}

.error-dialog__content {
	overflow-y: auto;
}

.error-dialog__content p {
	margin-bottom: 16px;
}

.error-dialog__content pre {
	background: var(--color-grey-100);
	padding: 10px;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	overflow-x: auto;
}
