.file__link {
	display: inline-flex;
	align-items: center;
	transition: color 333ms var(--timing-function-ease-out-expo);
}

.file__link svg {
    margin-left: 10px;
}

.file__link:hover,
.file__link:hover svg {
	color: var(--color-brand);
}
