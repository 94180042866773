.selected-items__list {
  margin-top: 16px;
}

.selected-items__list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px 8px 0;
}

.selected-items__list-delete-button {
	color: var(--color-grey-400);
}

.selected-items__list-delete-button:hover {
	color: var(--color-danger);
}
