.product-team-section {
	margin-top: 48px;
	padding: 0 16px;
}

.product-person__email a {
	max-width: fit-content;
}

.light-theme .page-section-title {
	margin-bottom: 15px;
}

.product-team__grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	margin-top: 32px;
}

.product-team__grid .grid-list__item {
	min-height: 92px;
	max-height: 92px;
}

.product-team__load-more {
	text-align: right;
	margin-top: 10px;
	margin-bottom: 20px;
}

.product-team-explanatory {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-light);
	margin-bottom: 24px;
}

.product-person.light-theme {
	background-color: #f7f7f7;
}

.light-theme__title {
	color: var(--color-grey-500);
}

.light-theme__name,
.light-theme__phone,
.light-theme__email {
	color: var(--color-grey-700);
}

@media (max-width: 767px) {
	.product-person__image {
		height: 92px;
		min-width: 92px;
		width: 92px;
	}

	.product-person {
		height: 92px;
	}

	.product-person__info {
		padding: 8px;
		overflow: hidden;
	}

	.product-person__email,
	.product-person__phone,
	.product-person__title {
		font-size: var(--font-size-xxs);
		line-height: var(--line-height-xxs);
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.product-person__name {
		font-size: var(--font-size-xs);
		line-height: var(--line-height-xs);
		max-height: 25px;
		min-height: auto;
	}

	.grid-list__item {
		overflow: hidden;
	}
}

@media all and (min-width: 768px) {
	.product-team-section {
		margin-top: 80px;
		padding: 0 40px;
	}
	.product-team__grid {
		grid-column-gap: 23px;
		grid-row-gap: 23px;
	}

	.product-team__grid .grid-list__item {
		min-height: 160px;
		max-height: 160px;
	}
}

@media (min-width: 960px) {
	.product-team__grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 1167px) {
	.product-team__grid {
		grid-column-gap: 30px;
		grid-row-gap: 30px;
	}
}

@media (min-width: 1360px) {
	.product-team__grid {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 32px;
		grid-row-gap: 32px;
	}
}
