.product-detail-section {
	position: relative;
	background-color: var(--color-grey-700);
	border: 4px solid var(--color-grey-700);
	min-height: 240px;
	max-height: 240px;
	padding: 30px 32px 32px 40px;
	cursor: pointer;
	transition: border-color;
	transition-duration: 300ms;
	width: calc(100% + 72px);
	margin-left: -40px;
	margin-top: -40px;
}

.product-detail-section--selected {
	border-color: var(--color-brand);
}

.product-detail-section__row {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.product-detail-section__left {
	display: flex;
	flex-direction: column;
	width: 45%;
	max-width: 45%;
}

.product-detail-section__right{
	height: 100%;
}

.product-detail-section__no-name-block,
.product-detail-section__no-description-block {
	height: 64px;
	width: 316px;
	border: 1px solid var(--color-grey-600);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-500);
}

.product-detail-section__no-name-block {
	height: 40px;
	width: 250px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	margin-bottom: 39px;
	transition-property: border;
	transition-duration: 300ms;
}

.product-detail-section__name {
	margin-top: 18px;
	color: white;
	font-size: var(--font-size-xxl);
	line-height: var(--line-height-xxl);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	transition-property: border;
	transition-duration: 300ms;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 320px;
	overflow: hidden;
}

.product-detail-section__description {
	color: white;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	min-height: 64px;
	max-height: 64px;
	width: 100%;
	max-width: 100%;
	outline: 1px solid var(--color-grey-600);
	padding: 12px;
	transition-property: outline;
	transition-duration: 300ms;
}

.product-detail-section__description--selected,
.product-detail-section__no-name-block.selected {
	outline: 2px solid var(--color-brand);
}

.product-detail-section__version {
	margin-top: 8px;
	margin-bottom: 23px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-500);
	letter-spacing: 0.3px;
}

.product-detail-section__version-value {
	color: var(--color-grey-300);
}

.product-detail-section__hero-block svg {
	width: 40px;
}

.product-detail-section__hero-block {
	height: 170px;
	width: 316px;
	border: 1px solid var(--color-grey-600);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-500);
	letter-spacing: 0.3px;
	position: relative;
}

.product-detail-section__hero-block .video-preview {
	position: relative;
}

.product-detail-section__hero-block--no-border {
	border: 0px;
}

.product-detail-section__hero-block img,
.product-detail-section__hero-block video {
	max-height: 100%;
	max-width: 100%;
	width: auto;
	height: auto;
}
