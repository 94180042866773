
.auth-error__wrapper{
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	justify-content: center;
}

.auth-error__title {
	margin-bottom: 70px;
	color: var(--color-brand);
	font-size: var(--font-size-xxl);
	font-weight: var(--font-weight-bold);
}

.auth-error--type {
	text-transform: uppercase;
	margin-bottom: 10px;
	font-size: var(--font-size-l);
	font-weight: var(--font-weight-medium);
}

.auth-error__description {
	max-width: 600px;
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-medium);
}

.auth-error__try-again-button {
	border-radius: 1px;
	background-color: var(--color-brand-lightest);
	height: 56px;
	width: 281px;
	margin-top: 50px;
	border: none;
	cursor: pointer;
}
