.button {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	text-transform: uppercase;
	outline: 0;
	border: 0;
	background-color: transparent;
	display: inline-block;
	text-align: center;
	border-radius: 1px;
	padding: 8px 16px;
	transition: all 333ms var(--timing-function-ease-out-expo);
	cursor: pointer;
}

.button:disabled {
	pointer-events: none;
	cursor: not-allowed;
}

.button--primary {
	background-color: var(--color-brand);
	color: white;
}

.button--primary:focus {
	box-shadow: 0 0 0 3px rgba(92, 178, 71, 0.5);
}

.button--primary:hover {
	background-color: var(--color-brand-dark);
}

.button--primary:active {
	background-color: var(--color-brand-darkest);
}

.button--primary:disabled {
	background-color: var(--color-grey-300);
}

.button--secondary {
	color: var(--color-grey-700);
}

.button--secondary:focus {
	box-shadow: 0 0 0 3px rgba(204, 204, 204, 0.5);
}

.button--secondary:hover {
	color: var(--color-brand-dark);
}

.button--secondary:active {
	color: var(--color-brand-darkest);
}

.button--secondary:disabled {
	color: var(--color-grey-300);
}

.button--danger {
	color: var(--color-danger);
}

.button--danger:focus {
	box-shadow: 0 0 0 3px var(--color-danger);
}

.button--danger:hover {
	color: var(--color-danger-dark);
}

.button--danger:disabled {
	color: var(--color-grey-300);
}

.button--icon {
	display: inline-flex;
	border: none;
	padding: 0;
	color: var(--color-grey-400);
}

.button--icon + .button--icon {
	margin-left: 16px;
}

.button--icon:hover,
.button--icon:focus {
	color: var(--color-grey-700);
	outline: none;
}

.button--add {
	display: inline-flex;
	align-items: center;
	margin-left: auto;
}

.button--add svg {
	margin-right: 5px;
}
