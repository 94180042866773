.product-attachments__section {
	padding-top: 60px;
}

.product-attachments__grid {
	margin-top: 25px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 24px;
	grid-row-gap: 24px;
}

.product-attachments__grid-item {
	/* padding: 16px; */
	border-radius: 1px;
	background-color: white;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	border: 2px solid white;
	transition: border-color 300ms ease-in-out;
}

.product-attachments__grid-item:hover,
.product-attachments__grid-item--active {
	border-color: var(--color-brand);
}

.product-attachments__add-button.active button {
	border: 1px solid transparent;
	background-color: var(--color-grey-100);
	color: var(--color-grey-300);
	cursor: default;
}


.product-attachements__new {
  transition: all 300ms ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	border: 2px solid var(--color-brand);
	background-color: var(--color-grey-100);
}
