.app-navbar {
	background-color: var(--color-grey-800);
	width: 100vw;
}

.app-navbar__alix-ip-logo {
	color: white;
}

.app-navbar__settings {
	top: 22px;
	right: 40px;
	color: var(--color-grey-400);
}

.app-navbar__separator {
	height: 24px;
	margin: 3px 7px 6px 2px;
	width: 1px;
	background-color: var(--color-grey-600);
}

.app-navbar__separator--green {
	background-color: var(--color-brand);
}

.app-navbar__separator--black {
	background-color: var(--color-grey-800);
}

.app-navbar__title {
	color: white;
	font-size: var(--font-size-m);
	letter-spacing: 1.17px;
	line-height: var(--line-height-xxs);
}
