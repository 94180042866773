.product-features {
	padding-top: 60px;
}

.product-features__container--dragging {
	padding-bottom: 148px;
	margin-bottom: 15px;
}

.product-features__drag-handle {
	color: var(--color-grey-300);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
}

.product-features__drag-handle--disabled {
	color: var(--color-grey-200);
	pointer-events: none;
}

.product-features__media svg {
	width: 30px;
}

.product-features__media {
	height: 100px;
	width: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--color-grey-300);
}

.product-features__media.product-features__media--default {
	align-items: center;
	justify-content: center;
}

.product-features__media img,
.product-features__media video {
	max-height: 100px;
	max-width: 100%;
	width: auto;
	height: auto;
}

.product-features__row {
	display: flex;
	align-items: center;
	background-color: var(--color-grey-100);
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	margin-top: 16px;
	padding: 24px 40px 15px 24px;
	max-height: 148px;
	min-height: 148px;
	position: relative;
	overflow: hidden;
}

.product-features__media-column {
	position: relative;
	display: flex;
	flex-direction: column;
	margin-left: auto; 
}

.product-features__title {
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	margin-top: 7px;
}

.product-features__info {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-top: auto;
}

.product-features__name {
	min-height: 24px;
	max-height: 96px;
	min-width: 9vw;
	max-width: 9vw;
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
}

.product-features__description {
	padding: 10px 8px;
	background: white;
	border: 1px solid var(--color-grey-300);
	color: var(--color-grey-800);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-regular);
	letter-spacing: 0.8px;
	min-height: 100px;
	min-width: 25vw;
	max-height: 100px;
	max-width: 25vw;
	margin: 0 23px;
}

.product-features__new-feature {
	height: 80px;
	margin-top: 24px;
	padding-top: 28px;
	border: 1px solid silver;
	color: var(--color-grey-500);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	text-align: center;
	display: none;
}

.product-features__button--removed {
	overflow: hidden;
}

.product-features__button--removed .product-features__row {
	transition: all 0.2s ease-out;
	margin-bottom: -164px;
	opacity: 0;
	border: 2px solid transparent;
}

.product-features__add-button.active button {
	border: 1px solid transparent;
	background-color: var(--color-grey-100);
	color: var(--color-grey-300);
	cursor: default;
}

.product-features__add-button.active ~ .product-features__new-feature {
	border: 2px solid var(--color-brand);
	display: block;
}

.product-features__button > div {
	border: 2px solid transparent;
}

.product-features__button.active > div {
	border: 2px solid var(--color-brand);
	background-color: var(--color-grey-100);
}

.product-features__close-button {
	position: absolute;
	top: 8px;
	right: 0;
}

.product-features__close-button:hover {
	color: var(--color-danger);
	cursor: pointer;
}
