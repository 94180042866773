.home-page-service-list__title {
	text-transform: uppercase;
	height: 32px;
	width: 90px;
	color: var(--color-grey-400);
	font-size: var(--font-size-xxs);
	letter-spacing: 0.03px;
	line-height: var(--line-height-m);
}

.home-page-service-list__content {
	background-color: #ffffff;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	height: 100%;
	padding: 20px 24px;
	width: 100%;
}

.home-page-service-list__row {
	align-items: center;
	border-bottom: 1px solid var(--color-grey-300);
	cursor: pointer;
	display: flex;
	color: var(--color-grey-600);
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-medium);
	height: 60px;
	justify-content: space-between;
	letter-spacing: 0.03px;
	line-height: var(--line-height-m);
	padding: 0 8px 0 14px;
	width: 100%;
}

.home-page-service-list__row:hover {
	color: var(--color-grey-800);
	font-weight: bold;
}

.home-page-service-list__row.show-more {
	color: var(--color-grey-700);
	height: 16px;
	margin: 25px 0 5px 0;
	width: 115px;
}

.home-page-service-list__row:last-child {
	border-bottom: 0;
}


.home-page-service-list__arrow {
	background: url("/images/green-arrow.svg") no-repeat;
	cursor: pointer;
	height: 12px;
	width: 14px;
}


.home-page-service-list__more {
	color: var(--color-grey-600);
	font-size: var(--font-size-xs);
	font-weight: 500;
	letter-spacing: 0.3px;
	line-height: var(--line-height-xxs);
}

.home-page-service-list__collapsible-icon {
	height: 19px;
}

.home-page-service-list__collapsible-icon svg {
	color: var(--color-grey-600);
}


@media (max-width: 767px) {
	.home-page-service-list__content {
		padding: 8px 24px;
	}

	.home-page-service-list__row {
		font-size: 13px;
		height: 40px;
		letter-spacing: 0.8px;
		line-height: 19px;
	}
}

