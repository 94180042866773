.tippy-popper .app-tooltip-theme.settings-tooltip-theme {
	padding: 0;
	border-radius: 1px;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.popout-menu-theme.settings-tooltip-theme .tippy-tooltip-content {
	padding: 0;
}

.popout-menu {
	color: var(--color-grey-400);
	animation: color 300ms ease-in;
}

.popout-menu.dark-nav:hover,
.popout-menu.dark-nav.active {
	color: var(--color-grey-600);
}

.popout-menu.light-nav.active {
	color: var(--color-brand-dark);
}

.popout-menu__list-item {
	background: white;
	animation: background-color 300ms ease-in;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-height: 32px;
	min-width: 120px;
}

.popout-menu__list-item:hover {
	background-color: var(--color-brand-lightest);
}

.popout-menu__list-item:hover .popout-menu__list-button {
	color: var(--color-grey-800);
}

.popout-menu__list-button {
	color: var(--color-grey-500);
	appearance: none;
	outline: 0;
	border: 0;
	padding: 0;
	transition-property: color;
	transition-duration: 333ms;
	transition-timing-function: var(--timing-function-ease-out-expo);
	cursor: pointer;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	text-align: start;
	width: 100%;
	padding: 8px 16px;
}
