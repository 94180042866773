.editor-page-layout {
	display: flex;
	overflow: hidden;
}

.editor-page-layout,
.editor-page-layout__left,
.editor-page-layout__right {
	height: calc(100vh - 64px); /*Header nav height*/
}

.editor-page-layout__left-inner {
	height: fit-content;
	padding: 40px 32px 40px 40px;
	max-height: calc(100vh - 64px);
	overflow-x: hidden;
	overflow-y: auto;
}

.editor-page-layout__right-inner {
	height: fit-content;
	min-height: 100%;
	background-color: var(--color-grey-100);
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
	padding: 40px 40px 0 32px;
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 64px);
	overflow-x: hidden;
	overflow-y: auto;
}

.editor-page-layout__left {
	width: 68%;
	overflow: auto;
}

.editor-page-layout__right {
	width: 32%;
	overflow: auto;
}

.editor-page-fieldset {
	padding: 0;
	outline: none;
	border: none;
	margin: 0 0 24px 0;
}

.editor-page-separator {
	height: 1px;
	width: 100%;
	background-color: var(--color-grey-200);
	margin-bottom: 27px;
}

.editor-page-separator.dark {
	background-color: var(--color-grey-300);
}

.editor-page-section-title {
	color: var(--color-grey-800);
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-bold);
	margin-bottom: 16px;
}

.editor-page-section-title--admin {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-medium);
	padding-bottom: 10px;
	color: var(--color-grey-400);
}

.editor-section-actions {
	min-height: 64px;
	max-height: 64px;
	display: flex;
	align-items: center;
	border-top: 1px solid var(--color-grey-200);
	padding-left: 32px;
	padding-right: 40px;
	margin-left: -32px; /*for full border*/
	width: calc(100% + 72px); /*padding left and right of the right section*/
	background-color: var(--color-grey-100);
	z-index: 1;
}

.editor-section-actions--fixed {
	position: sticky;
	bottom: 0;
}

.editor-section-actions .button + .button {
	margin-left: 5px;
}

.editor-section-info-label{
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-top: 24px;
	margin-bottom: 19px;
}

.editor-page-order-change-label {
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	cursor: pointer;
	text-transform: uppercase;
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	line-height: var(--line-height-xxs);
	margin-left: 30px;
}
