@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes modalScaleIn {
	0% {
		opacity: 0;
		transform: scale(0.9);
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate(0px, 0px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, -25px);
	}
}

@keyframes scaleInRotateRight {
	from {
		transform: scale(0.8) rotate(-120deg);
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes scaleIn {
	from {
		transform: scale(0.8);
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

@keyframes show {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

