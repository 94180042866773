.SingleDatePicker .DayPicker_transitionContainer {
	min-height: 334px;
}

.SingleDatePicker .SingleDatePickerInput {
	border-color: var(--color-grey-300);
}

.SingleDatePicker .SingleDatePickerInput:hover,
.SingleDatePicker .SingleDatePickerInput:focus {
	border-color: var(--color-grey-400);
}

.SingleDatePicker .SingleDatePickerInput:focus {
	outline: 0;
}

.SingleDatePicker .SingleDatePickerInput__withBorder {
	border-radius: 1px;
}

.SingleDatePickerInput_calendarIcon {
	color: var(--color-grey-400);
	padding: 0;
	outline: none;
}

.SingleDatePickerInput_calendarIcon:focus,  .SingleDatePickerInput_calendarIcon:active{
	color: var(--color-brand);
}

.SingleDatePicker .DayPicker__withBorder {
	border-radius: 1px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px var(--color-grey-300);
}

.SingleDatePicker .DateInput_fang {
	left: 8px;
}

.SingleDatePicker .DateInput_fangStroke {
	stroke: var(--color-grey-300);
}

.SingleDatePicker .DateInput {
	width: 160px;
}

.SingleDatePicker .DateInput_input {
	border-bottom: 0;
	padding: 6px 11px;
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-regular);
	color: var(--color-grey-700);
}

.SingleDatePicker .SingleDatePickerInput_arrow {
	display: none;
}

.SingleDatePicker .CalendarDay__default {
	color: var(--color-grey-700);
}

.SingleDatePicker .CalendarDay__hovered_span,
.SingleDatePicker .CalendarDay__hovered_span:hover {
	background-color: #c0f2b3;
	border-color: #95e880;
	color: #028700;
}

.SingleDatePicker .CalendarDay__selected,
.SingleDatePicker .CalendarDay__selected:active,
.SingleDatePicker .CalendarDay__selected:hover {
	background-color: #21a600;
	border-color: #21a600;
	color: white;
}

.SingleDatePicker .CalendarDay__selected_span {
	background-color: #7de366;
	border-color: #53d932;
	color: white;
}

.SingleDatePicker .CalendarDay__selected_span:active,
.SingleDatePicker .CalendarDay__selected_span:hover {
	background-color: #53d932;
	border-color: #53d932;
	color: white;
}

.SingleDatePicker .DayPickerNavigation_button {
	border-color: var(--color-grey-300);
}

.SingleDatePicker .DayPickerNavigation_button:hover,
.SingleDatePicker .DayPickerNavigation_button:focus {
	border-color: var(--color-grey-400);
}

.SingleDatePicker .DayPickerNavigation_button:focus {
	outline: 0;
	background-color: transparent;
}
