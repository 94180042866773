.delete-item-dialog {
	height: auto;
}

.delete-item-dialog__paragraph {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
}

.delete-item-dialog__name {
	font-weight: var(--font-weight-bold);
}
