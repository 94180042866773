.area__page {
	font-weight: var(--font-weight-bold);
	color: white;
	font-size: var(--font-size-xxl);
	line-height: var(--line-height-xxl);
	letter-spacing: 0.3px;
	padding: 20px 40px;
	background-color: #000000;
	height: 300px;
	width: 100%;
}

.area-page__footer-space {
	margin-top: 64px;
}
