.max-width__layout {
	max-width: 1600px;
  margin: 0 auto;
  height: 100%;
}

.max-width__layout--full-width {
  min-width: 100%;
}


@media (min-width: 1600px) {
	.max-width__layout--full-width {
    min-width: 1600px;
  }
}