
.auth-error__wrapper{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	min-height: 100vh;
	-webkit-justify-content: center;
	        justify-content: center;
}

.auth-error__title {
	margin-bottom: 70px;
	color: var(--color-brand);
	font-size: var(--font-size-xxl);
	font-weight: var(--font-weight-bold);
}

.auth-error--type {
	text-transform: uppercase;
	margin-bottom: 10px;
	font-size: var(--font-size-l);
	font-weight: var(--font-weight-medium);
}

.auth-error__description {
	max-width: 600px;
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-medium);
}

.auth-error__try-again-button {
	border-radius: 1px;
	background-color: var(--color-brand-lightest);
	height: 56px;
	width: 281px;
	margin-top: 50px;
	border: none;
	cursor: pointer;
}

.overlay {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	-webkit-animation: show 666ms 250ms var(--timing-function-ease-out-expo) both;
	        animation: show 666ms 250ms var(--timing-function-ease-out-expo) both;
}

.overlay--no-data {
	background-color: transparent;
	-webkit-animation: none;
	        animation: none;
}

.overlay--no-data svg {
	color: var(--color-grey-500);
}

.overlay__message {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	text-align: center;
	letter-spacing: 0.8px;
	display: block;
	padding-left: 5px;
	color: var(--color-grey-500);
}

.loader {
	border-radius: 50%;
	border-style: solid;
	border-color: rgba(92, 178, 71, 0.5);
	border-top-color: var(--color-brand);
	opacity: 0;
	-webkit-animation: scaleInRotateRight 200ms linear 1 forwards, spinner 666ms 200ms linear infinite;
	        animation: scaleInRotateRight 200ms linear 1 forwards, spinner 666ms 200ms linear infinite;
}

.page {
  padding-top: 64px;
}
.home-page-service-list__title {
	text-transform: uppercase;
	height: 32px;
	width: 90px;
	color: var(--color-grey-400);
	font-size: var(--font-size-xxs);
	letter-spacing: 0.03px;
	line-height: var(--line-height-m);
}

.home-page-service-list__content {
	background-color: #ffffff;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	height: 100%;
	padding: 20px 24px;
	width: 100%;
}

.home-page-service-list__row {
	-webkit-align-items: center;
	        align-items: center;
	border-bottom: 1px solid var(--color-grey-300);
	cursor: pointer;
	display: -webkit-flex;
	display: flex;
	color: var(--color-grey-600);
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-medium);
	height: 60px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	letter-spacing: 0.03px;
	line-height: var(--line-height-m);
	padding: 0 8px 0 14px;
	width: 100%;
}

.home-page-service-list__row:hover {
	color: var(--color-grey-800);
	font-weight: bold;
}

.home-page-service-list__row.show-more {
	color: var(--color-grey-700);
	height: 16px;
	margin: 25px 0 5px 0;
	width: 115px;
}

.home-page-service-list__row:last-child {
	border-bottom: 0;
}


.home-page-service-list__arrow {
	background: url("/images/green-arrow.svg") no-repeat;
	cursor: pointer;
	height: 12px;
	width: 14px;
}


.home-page-service-list__more {
	color: var(--color-grey-600);
	font-size: var(--font-size-xs);
	font-weight: 500;
	letter-spacing: 0.3px;
	line-height: var(--line-height-xxs);
}

.home-page-service-list__collapsible-icon {
	height: 19px;
}

.home-page-service-list__collapsible-icon svg {
	color: var(--color-grey-600);
}


@media (max-width: 767px) {
	.home-page-service-list__content {
		padding: 8px 24px;
	}

	.home-page-service-list__row {
		font-size: 13px;
		height: 40px;
		letter-spacing: 0.8px;
		line-height: 19px;
	}
}


.tippy-popper .tippy-tooltip.light-theme.app-tooltip-theme {
	box-shadow: 0 6px 20px 0 rgba(0,0,0,0.2);
	color: var(--color-grey-700);
}

.tippy-popper .app-tooltip-theme {
	padding: 0;
	border-radius: 1px;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	min-width: 320px;
}

.app-tooltip-theme .tippy-tooltip-content {
	padding: 0 0;
}

.app-dropdown {
	color: var(--color-grey-400);
	-webkit-animation: color 300ms ease-in;
	        animation: color 300ms ease-in;
	cursor: pointer;
}

.app-dropdown.dark-nav:hover,
.app-dropdown.dark-nav.active {
	color: white;
}
.menu-dropdown__label-row {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-align-items: center;
	        align-items: center;
}

.menu-dropdown__label {
	font-size: var(--font-size-xl);
	line-height: var(--line-height-xl);
	color: var(--color-brand);
	font-weight: var(--font-weight-light);
}

.menu-dropdown__icon {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	color: var(--color-grey-400);
}

.menu-dropdown__options-container {
	position: absolute;
	top: -12px;
	right: 0;
	z-index: 1;
	background-color: white;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	min-width: 280px;
	max-height: 600px;
	overflow-y: auto;
	overflow-x: hidden;
	opacity: 0;
	-webkit-animation: fadeIn 200ms var(--timing-function-ease-out-expo) 1 forwards;
	        animation: fadeIn 200ms var(--timing-function-ease-out-expo) 1 forwards;
	-webkit-transform-origin: 0% 0%;
	        transform-origin: 0% 0%;
	margin-top: -8px;
	padding: 8px 0;
}

.menu-dropdown__options-container--open {
	opacity: 1;
	padding: 8px 0;
}

.menu-dropdown__option {
	min-height: 40px;
	padding: 8px 0;
	transition-property: background-color;
	transition-duration: 300ms;
}

.menu-dropdown__option-button {
	border: none;
	outline: none;
	width: 100%;
	padding: 0 16px;
	color: var(--color-grey-500);
	cursor: pointer;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	text-align: left;
	transition-property: color;
	transition-duration: 300ms;
}

.menu-dropdown__option:hover {
	background-color: var(--color-brand-lightest);
}

.menu-dropdown__option:hover .menu-dropdown__option-button {
	color: var(--color-grey-800);
}

.tippy-popper .settings-tooltip-theme {
	min-width: 180px;
}

.settings-dropdown.light-nav.active {
	color: var(--color-brand-dark);
}

.settings-dropdown__list {
	padding: 8px 0;
}

.settings-dropdown__list-item {
	background: white;
	-webkit-animation: background-color 300ms ease-in;
	        animation: background-color 300ms ease-in;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	min-height: 32px;
	min-width: 180px;
}

.settings-dropdown__list-item:hover {
	background-color: var(--color-brand-lightest);
}

.settings-dropdown__list-item:hover .settings-dropdown__list-button {
	color: var(--color-grey-800);
}

.settings-dropdown__list-button {
	color: var(--color-grey-500);
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	outline: 0;
	border: 0;
	padding: 0;
	transition-property: color;
	transition-duration: 333ms;
	transition-timing-function: var(--timing-function-ease-out-expo);
	cursor: pointer;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	text-align: start;
	width: 100%;
	padding: 8px 16px;
}

.app-navbar {
	background-color: var(--color-grey-800);
	width: 100vw;
}

.app-navbar__alix-ip-logo {
	color: white;
}

.app-navbar__settings {
	top: 22px;
	right: 40px;
	color: var(--color-grey-400);
}

.app-navbar__separator {
	height: 24px;
	margin: 3px 7px 6px 2px;
	width: 1px;
	background-color: var(--color-grey-600);
}

.app-navbar__separator--green {
	background-color: var(--color-brand);
}

.app-navbar__separator--black {
	background-color: var(--color-grey-800);
}

.app-navbar__title {
	color: white;
	font-size: var(--font-size-m);
	letter-spacing: 1.17px;
	line-height: var(--line-height-xxs);
}

/*Nav bar menu - Mobile view styles*/
.mobile-menu-theme {
	margin-left: -5px;
	display: none;
}

.responsive-menu {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.responsive-menu__more-icon {
	margin-right: 10px;
}

.responsive-menu__wrapper {
	background-color: white;
}

.responsive-menu__container {
	color: var(--color-grey-400);
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	min-width: 320px;
	max-width: 320px;
	min-height: 316px;
	overflow: hidden;
	height: auto;
	transition: height 300ms linear;
	display: -webkit-flex;
	display: flex;
}

.homepage-navbar__mobile-nav-link {
	padding: 16px 0;
	border-bottom: 1px solid var(--color-grey-200);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	color: var(--color-grey-600);
	cursor: pointer;
}

.homepage-navbar__mobile-nav-link:first-child {
	padding-top: 0;
}

.responsive-menu__header {
	display: none;
	padding: 40px 32px 32px 32px;
	color: var(--color-grey-400);
}

.responsive-menu__logo {
	color: black;
}

.responsive-menu__footer {
	padding-top: 24px;
	display: -webkit-flex;
	display: flex;
}

.responsive-menu__level1 {
	padding: 24px 40px;
	margin-left: 0;
	opacity: 1;
	transition: margin-left 300ms linear;
	min-width: 320px;
}

.slide-to-left {
	margin-left: -320px;
}

.responsive-menu__level2 {
	min-width: 320px;
	padding: 0 40px 24px 40px;
	max-height: 316px;
	transition: max-height 300ms linear;
}

.responsive-menu__level2--on {
	max-height: 100%;
}

.responsive-menu__level2-header {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	text-transform: uppercase;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	color: var(--color-grey-700);
	border-bottom: 1px solid var(--color-grey-200);
	padding: 8px 12px;
	margin-bottom: 24px;
	width: calc(100% + 80px);
	margin-left: -40px;
}

.homepage-navbar__mobile-sub-nav {
	display: none;
}

.homepage-navbar__mobile-sub-nav--show {
	display: block;
}

/*Nav bar menu - Media Queries*/
@media all and (min-width: 960px) {
	.responsive-menu {
		display: none;
	}
}

@media all and (max-width: 959px) {
	.mobile-menu-theme {
		display: block;
	}

	.responsive-menu {
		display: -webkit-inline-flex;
		display: inline-flex;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
	}

	.responsive-menu__header {
		display: none;
	}
}

/*Show another header inside the dropdown till iphone plus screens*/
@media (max-width: 767px) {
	.tippy-popper {
		max-width: 100vw !important;
	}
	.responsive-menu__header {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
	}
	.responsive-menu__container {
		box-shadow: none;
	}
	.responsive-menu__wrapper {
		margin-top: -48px;
		box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	}
	.responsive-menu__container {
		padding-bottom: 50px;
		min-width: 100vw;
		max-width: 100vw;
	}
	.responsive-menu__level1,
	.responsive-menu__level2 {
		min-width: 100vw;
	}

	.slide-to-left {
		margin-left: -100vw;
	}
}

.ap-modal-content {
	max-width: 611px;
	width: 611px;
}

.new-engagement {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 16px 0;
}

.new-engagement__actions {
	display: none;
	-webkit-justify-content: center;
	        justify-content: center;
}

.new-engagement__actions-info {
	display: -webkit-flex;
	display: flex;
	color: var(--color-grey-800);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
}

.new-engagement-block {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.new-engagement-block__image {
	height: 221px;
	width: 230px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.new-engagement-block__image img {
	max-width: 161px;
	max-height: 161px;
	width: auto;
	height: auto;
}

.new-engagement-block__title {
	color: var(--color-grey-700);
	font-size: var(--font-size-m);
	line-height: 31px;
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.25px;
	text-align: center;
	max-width: 182px;
}

.new-engagement-block__separator {
	width: 1px;
	background-color: var(--color-grey-300);
	min-height: 388px;
	margin: 0 30px;
}

.new-engagement-block__email-ets-image {
	height: 160px;
	width: 160px;
	border: 1px solid var(--color-grey-300);
	background-color: var(--color-grey-100);
	border-radius: 50%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.new-engagement-block__email-ets-image img {
	-webkit-transform: translateX(4px);
	        transform: translateX(4px);
}

@media (min-width: 768px) {
	.new-engagement__actions {
		display: -webkit-flex;
		display: flex;
	}
	.new-engagement__actions-info {
		display: none;
	}
}

.homepage-navbar {
	width: 100vw !important;
}

.homepage-navbar__list {
	display: none;
	position: relative;
}

.homepage-navbar__list .menu-dropdown__options-container {
	top: 64px;
}

.homepage-navbar__item.active .menu-dropdown__label {
	color: #ffffff;
}

.homepage-navbar__list .menu-dropdown__label:hover {
	color: var(--color-brand);
	cursor: pointer;
}

.homepage-navbar__list .menu-dropdown__label:hover + .menu-dropdown__icon svg {
	color: var(--color-brand);
}

.homepage-navbar__list .menu-dropdown__icon svg:hover {
	fill: var(--color-brand);
}

.homepage-navbar__item {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-align-items: center;
	        align-items: center;
	color: var(--color-grey-400);
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	margin-right: 24px;
}

.homepage-navbar__item:first-child {
	padding-right: 8px;
}

.homepage-navbar__item .menu-dropdown__label,
.homepage-navbar__link {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
	outline: 0;
	height: 64px;
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-align-items: center;
	        align-items: center;
	transition: color 333ms var(--timing-function-ease-out-expo);
	color: inherit;
}

.homepage-navbar__link:hover {
	color: var(--color-brand);
}

.homepage-navbar__link:focus,
.homepage-navbar__link.active {
	color: white;
}

.homepage-navbar__link + .homepage-navbar__link {
	margin-left: 16px;
	margin-right: 24px;
}

/* Make left part narrow */
.homepage-navbar > div > div:first-child {
	max-width: 150px;
}

.homepage-navbar__nav-link {
	display: -webkit-flex;
	display: flex;
	-webkit-align-self: stretch;
	        align-self: stretch;
	-webkit-align-items: center;
	        align-items: center;
}

.homepage-navbar__item.my-engagements,
.homepage-navbar__item.companies {
	cursor: pointer;
	color: var(--color-grey-400);
	font-size: var(--font-size-s);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height-s);
	margin-right: 32px;
}

.homepage-navbar__item.my-engagements.active {
	color: white;
}

.homepage-navbar__item.my-engagements:hover,
.homepage-navbar__item.companies:hover {
	color: var(--color-brand);
}

.homepage-navbar__item.my-engagements.active:hover {
	color: white;
}


@media (min-width: 960px) {
	.homepage-navbar__list {
		display: -webkit-inline-flex;
		display: inline-flex;
	}
}

.home-page-container {
	color: var(--color-grey-800);
	font-size: var(--font-size-m);
	letter-spacing: 0.03px;
	width: 100vw;
}

.home-page-container section {
	margin-left: auto;
	margin-right: auto;
	max-width: 1600px;
	overflow: hidden;
}

/* Top block styles */
.home-page-container__top-block-container {
	background: #ebeff2;
	height: 570px;
}
.home-page-container__top-block {
	display: -webkit-flex;
	display: flex;
	height: 100%;
	padding: 20px 0;
	width: 100vw;
}
.home-page-container__top-block-info,
.home-page-container__top-block-logo-container {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	-webkit-flex: 1 1 50%;
	        flex: 1 1 50%;
}

.home-page-container__top-block-logo-container {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

.home-page-container__top-block-logo {
	background: url('/images/AlixIP-Home-Banner.png') no-repeat;
	background-size: contain;
	height: 458px;
	width: 720px;
}

.home-page-container__top-block-info {
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-top: 10px;
}

.home-page-container__top-block-text {
	letter-spacing: 0.02px;
	line-height: 35px;
	font-size: 27px;
	height: 105px;
	max-width: 548px;
	padding-right: 50px;
}

.home-page-container__top-block-title {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	height: 110px;
	width: 304px;
}

.home-page-container__top-block-title svg {
	height: 59px;
	width: 192px;
}

/* Services block styles */

.home-page-container__services-block {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	overflow: visible;
	position: relative;
	width: 100%;
	z-index: 5;
}

.home-page-container__services-block-button .button--primary {
	height: 32px;
	padding-top: 7px;
	width: 204px;
}

.home-page-container__services-block-left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
	-webkit-flex: 1 0 41.6%;
	        flex: 1 0 41.6%;
	margin-top: 50px;
}

.home-page-container__services-block-left-content {
	-webkit-align-items: flex-end;
	        align-items: flex-end;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	margin: 85px 0 0 30px;
	position: relative;
	max-width: 432px;
}

.home-page-container__services-block-text {
	height: 92px;
	line-height: 27px;
	text-align: right;
	max-width: 432px;
}
.home-page-container__services-block-title {
	font-size: var(--font-size-giant-xxs);
	font-weight: bold;
	height: 147px;
	letter-spacing: 0.02px;
	line-height: 49px;
	text-align: right;
	max-width: 432px;
}

.home-page-container__services-block-right {
	padding-left: 32px;
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	-webkit-flex: 1 0 58.4%;
	        flex: 1 0 58.4%;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	height: auto;
	margin-top: 50px;
	min-height: 450px;
}

.home-page-container__services-block-right-content {
	position: relative;
	height: auto;
	margin-bottom: 110px;
	width: 100%;
}

.home-page-container_honeycomb-background {
	position: absolute;
	z-index: 1;
	-webkit-align-items: center;
	        align-items: center;
	background: url('/images/AlixIP-Home-Pattern.svg') no-repeat left top;
	background: linear-gradient(270deg, rgba(255, 255, 255, 0) 30%, #ffffff 100%),
		url('/images/AlixIP-Home-Pattern.svg') no-repeat center right;
	background-size: contain;
	display: -webkit-flex;
	display: flex;
	background-position: center right;
	margin-left: 0;
	margin-right: 0;
	background-size: contain;
	width: 70%;
	height: 100%;
	margin-top: -3%;
}

.home-page-container__services-list-container {
	position: relative;
	top: calc(50% - 225px);
	width: 57.1%;
	z-index: 2;
}

/* Knowledge base section styles */
.home-page-container__knowledge-base-block {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	margin-top: 40px;
	position: relative;
	width: 100%;
	z-index: 3;
}

.home-page-container__knowledge-base-background-container {
	background-color: var(--color-grey-100);
	height: 437px;
	position: relative;
	width: calc(85.7% + 16px);
}

.home-page-container__knowledge-base-tiles-container {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	position: absolute;
	right: 0;
	width: calc(85.7% + 32px);
}

.home-page-container__info-block-title {
	font-size: var(--font-size-giant-xxs);
	font-weight: bold;
	letter-spacing: 0.02px;
	line-height: 49px;
	max-width: 431px;
}

.home-page-container__info-block-text {
	line-height: 27px;
	max-width: 432px;
}

.home-page-container__knowledge-base-right .home-page-container__info-block-text,
.home-page-container__knowledge-base-right .home-page-container__info-block-title {
	padding-right: 20px;
}

.home-page-container__knowledge-base-tile {
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	height: 328px;
	margin-left: 32px;
	width: calc(32.33% - 32px);
}

.home-page-container__knowledge-base-tile-bottom {
	background-color: #ffffff;
	height: 50%;
	padding: 20px 15px;
	position: relative;
}

.home-page-container__knowledge-base-tile-benchmarks,
.home-page-container__knowledge-base-tile-commodities,
.home-page-container__knowledge-base-tile-registers {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	height: 50%;
}

.home-page-container__knowledge-base-tile:hover {
	box-shadow: 0 6px 20px 3px rgba(0, 0, 0, 0.4);
}

.home-page-container__knowledge-base-tile-benchmarks {
	background-color: #4d55b3;
}

.home-page-container__knowledge-base-tile-commodities {
	background-color: var(--color-brand);
}

.home-page-container__knowledge-base-tile-registers {
	background-color: #4db3a0;
}

.home-page-container__knowledge-base-left {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	-webkit-flex-basis: calc(58.4%);
	        flex-basis: calc(58.4%);
}

.home-page-container__knowledge-base-right {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-flex: 1 1 41.6%;
	        flex: 1 1 41.6%;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	margin-left: 34px;
	margin-top: 80px;
}

.home-page-container__knowledge-tile-header {
	color: var(--color-grey-700);
	font-size: var(--font-size-l);
	font-weight: 800;
	line-height: 27px;
	margin-bottom: 10px;
}

.home-page-container__knowledge-tile-text {
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	font-weight: 400;
	letter-spacing: 0.04px;
	line-height: var(--line-height-s);
}

.home-page-container__knowledge-tile-arrow,
.home-page-container__involved-tile-arrow {
	bottom: 15px;
	right: 15px;
	position: absolute;
}

.home-page-container__arrow {
	background: url('/images/green-arrow.svg') no-repeat;
	cursor: pointer;
	height: 12px;
	width: 14px;
}

.home-page-container__benchmark-logo {
	background: url('/images/benchmark_80px.svg') no-repeat;
	height: 73.3px;
	width: 60px;
}

.home-page-container__commodities-logo {
	background: url('/images/commodities_80px.svg') no-repeat;
	height: 80px;
	width: 80px;
}

.home-page-container__registers-logo {
	background: url('/images/registers_80px.svg') no-repeat;
	height: 75px;
	width: 75px;
}

.home-page-container__benchmark-logo,
.home-page-container__commodities-logo,
.home-page-container__registers-logo {
	background-size: contain;
	margin: 0 auto;
}

/* Build tool styles */
.home-page-container__build-tool-block {
	display: -webkit-flex;
	display: flex;
	margin-top: 70px;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 100%;
}

.home-page-container__build-tool-right-content {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	position: relative;
	height: 510px;
	width: 100%;
}

.home-page-container__build-tool-left,
.home-page-container__build-tool-right {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
	-webkit-flex: 1 0 41.6%;
	        flex: 1 0 41.6%;
}

.home-page-container__build-tool-right {
	-webkit-flex: 1 0 58.4%;
	        flex: 1 0 58.4%;
	padding-left: 32px;
	position: relative;
}

.home-page-container__build-tool-left-content {
	-webkit-align-items: flex-end;
	        align-items: flex-end;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	margin-top: -10px;
	position: relative;
	text-align: right;
}

.home-page-container__info-block-button .button--primary {
	height: 32px;
	margin-top: 10px;
	padding-top: 7px;
	width: 111px;
}

.home-page-container__build-tool-background-block {
	-webkit-align-items: center;
	        align-items: center;
	background-color: #f7f7f7;
	display: -webkit-flex;
	display: flex;
	height: 458px;
	position: absolute;
	padding-top: 56.25%;
	right: 0;
	width: 71.4%;
}

.home-page-container__built-tool-container {
	-webkit-align-items: center;
	        align-items: center;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	height: 337px;
	padding-left: 30px;
	position: relative;
	top: auto;
	width: calc(85.7% - 32px);
}

.home-page-container__build-tool-schema-block {
	background: url('/images/AlixIP-Home-BuildYourOwn-Process.svg') no-repeat;
	background-size: contain;
	padding-top: 40%;
	width: 100%;
}

/* Involved info block styles */

.home-page-container__involved-block {
	margin-top: 50px;
	width: 100%;
}

.home-page-container__involved-title {
	font-size: var(--font-size-giant-xxs);
	font-weight: bold;
	letter-spacing: 0.02px;
	line-height: 49px;
	text-align: center;
}

.home-page-container__involved-text {
	line-height: 27px;
	margin: 0 auto;
	text-align: center;
	max-width: 664px;
}

.home-page-container__involved-actions-container {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	margin: 40px auto 100px;
	width: 75%;
}

.home-page-container__involved-tile {
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	margin: 0;
	width: calc(33.3% - 16px);
	height: 288px;
}

.home-page-container__involved-tile:nth-child(2) {
	margin: 0 32px;
}

.home-page-container__tell-us-image {
	background: url('/images/bottom-feedback.jpg') no-repeat;
}

.home-page-container__cdo-image {
	background: url('/images/bottom-CDO.jpg') no-repeat;
}

.home-page-container__vote-image {
	background: url('/images/bottom-Idea.jpg') no-repeat;
	background-position-y: -20px;
}

.home-page-container__cdo-image,
.home-page-container__vote-image,
.home-page-container__tell-us-image {
	-webkit-align-items: center;
	        align-items: center;
	background-size: cover;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	height: 100%;
	position: absolute;
	width: 100%;
	z-index: 1;
}

.home-page-container__involved-tile--top {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	height: 61%;
	overflow: hidden;
	position: relative;
}

.home-page-container__involved-tile:hover {
	box-shadow: 0 6px 20px 3px rgba(0, 0, 0, 0.4);
}

.home-page-container__involved-tile--bottom {
	background-color: #ffffff;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	height: 39%;
	padding: 25px 15px 15px;
}

.home-page-container_involved-tile-title {
	color: var(--color-grey-700);
	font-size: var(--font-size-l);
	font-weight: 500;
	letter-spacing: 0.04px;
	line-height: 31px;
}

.home-page-container__cdo-logo {
	background: url('/images/CDO_80px.svg') no-repeat;
	height: 60px;
	width: 60px;
	z-index: 2;
}

.home-page-container__feedback-logo {
	background: url('/images/feedback_80px.svg') no-repeat;
	height: 66.67px;
	width: 66.67px;
	z-index: 2;
}

.home-page-container__idea-logo {
	background: url('/images/idea_80px.svg') no-repeat;
	height: 66.67px;
	width: 60px;
	z-index: 2;
}

.home-page-container__codelibrary-icon {
	background: url('/images/code-icon.svg') no-repeat;
	height: 66.67px;
	-webkit-transform: scale(1.3);
	        transform: scale(1.3);
	width: 60px;
	z-index: 2;
}

.home-page-container__green-text {
	color: var(--color-brand);
}

.home-page-container__codelibrary-logo {
	background: url('/images/python.jpeg') no-repeat;
	height: 100%;
	width: 100%;
	background-size: cover;
	z-index: 1;
	position: absolute;
	-webkit-filter: brightness(0.5);
	        filter: brightness(0.5);
}

/* 1440 -> 1599 */
@media (max-width: 1599px) {
	.home-page-container_honeycomb-background {
		background: url('/images/AlixIP-Home-Pattern.svg') no-repeat left top;
		background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
			url('/images/AlixIP-Home-Pattern.svg') no-repeat center right;
		display: -webkit-flex;
		display: flex;
	}

	.home-page-container__top-block-container {
		height: 520px;
	}

	.home-page-container__built-tool-container {
		height: 337px;
	}

	.home-page-container__build-tool-left-content {
		margin-top: -10px;
	}

	.home-page-container__services-list-container {
		width: 55.3%;
	}

	.home-page-container__involved-tile {
		height: 266px;
		width: calc(33.3% - 44px);
	}

	.home-page-container__vote-image {
		background-position-y: 0;
	}

	.home-page-container__services-block-right {
		min-height: 490px;
	}
}

@media (max-width: 1440px) {
	.home-page-container__top-block-logo {
		max-width: 100%;
		max-height: 100%;
		height: 100%;
		margin-top: 3%;
		width: 100%;
	}

	.home-page-container__services-block-right {
		min-height: 505px;
	}
}

/* 1167 - 1365 */
@media (max-width: 1365px) {
	.home-page-container__services-block-right {
		min-height: 520px;
	}

	.home-page-container__services-block-right-content {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
	}

	.home-page-container__knowledge-base-tiles-container {
		width: calc(85.7% + 48px);
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
	}

	.home-page-container__knowledge-base-tile {
		width: calc(33.3% - 24px);
		margin-left: 0;
	}

	.home-page-container__knowledge-base-tile:nth-child(2) {
		margin: 0 32px;
	}

	.home-page-container__involved-actions-container {
		width: 92%;
	}

	.home-page-container__top-block-logo {
		height: 0;
		padding-bottom: 75%;
		width: 100%;
	}
}

@media (max-width: 1166px) {
	.home-page-container__knowledge-base-block {
		-webkit-flex-direction: column-reverse;
		        flex-direction: column-reverse;
		margin-top: 10px;
	}

	.home-page-container__knowledge-base-right {
		-webkit-align-items: center;
		        align-items: center;
		margin-top: 60px;
		margin-left: 0;
		text-align: center;
	}

	.home-page-container__info-block-title {
		font-size: var(--font-size-giant-xxs);
		line-height: 49px;
		height: auto;
		margin-bottom: 12px;
		max-width: 583px;
		width: 100%;
	}

	.home-page-container__info-block-title .home-page-container__green-text {
		display: inline;
		margin-left: 10px;
	}

	.home-page-container__knowledge-base-left {
		-webkit-align-items: start;
		        align-items: start;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-flex: 1 1 100%;
		        flex: 1 1 100%;
		-webkit-justify-content: center;
		        justify-content: center;
	}

	.home-page-container__knowledge-base-background-container {
		height: 232px;
		margin-top: 18%;
		width: 100%;
	}

	.home-page-container__knowledge-base-tiles-container {
		width: calc(79% - 64px);
		-webkit-justify-content: center;
		        justify-content: center;
		left: 32px;
		right: auto;
		margin-left: calc(50% - (79% - 32px) / 2);
	}

	.home-page-container__knowledge-base-tile {
		height: 317px;
		margin: 0;
	}

	.home-page-container__knowledge-base-tile:nth-child(2) {
		margin: 0 32px;
	}

	.home-page-container__build-tool-block {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

	.home-page-container__build-tool-left {
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-flex: 1 1 100%;
		        flex: 1 1 100%;
		text-align: center;
	}

	.home-page-container__build-tool-left-content {
		-webkit-align-items: center;
		        align-items: center;
		-webkit-flex-direction: column;
		        flex-direction: column;
		margin-top: 20px;
		max-width: 100%;
		width: 100%;
	}

	.home-page-container__info-block-title {
		font-size: var(--font-size-giant-xxs);
		line-height: 49px;
		height: auto;
		margin-bottom: 12px;
		max-width: 583px;
		text-align: center;
	}

	.home-page-container__info-block-text {
		font-size: var(--font-size-m);
		line-height: var(--line-height-m);
		height: auto;
		max-width: 583px;
		text-align: center;
		width: 100%;
	}

	.home-page-container__build-tool-right {
		padding-left: 0;
	}

	.home-page-container__build-tool-right-content {
		height: 445px;
		margin-top: 0;
	}

	.home-page-container__build-tool-background-block {
		width: 100%;
	}

	.home-page-container__built-tool-container {
		margin: 0 auto;
		width: calc(68.3% - 64px);
	}

	.home-page-container__build-tool-background-block {
		height: 232px;
		padding-top: 0;
		width: 100%;
		margin-top: 8%;
	}

	.home-page-container__services-block-right {
		height: auto;
		min-height: 450px;
	}

	.home-page-container__built-tool-container {
		height: 285px;
	}

	.home-page-container__involved-block {
		-webkit-align-items: center;
		        align-items: center;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

	.home-page-container__involved-title {
		max-width: 588px;
		text-align: center;
	}

	.home-page-container__involved-text {
		max-width: 582px;
		font-size: var(--font-size-m);
		line-height: var(--line-height-m);
		margin-top: 15px;
	}

	.home-page-container__involved-actions-container {
		width: calc(100% - 64px);
	}

	.home-page-container__involved-tile {
		margin: 0;
		width: calc(33.3% - 16px);
		height: 286px;
	}

	.home-page-container__involved-tile:nth-child(2) {
		margin: 0 32px;
	}

	.home-page-container__cdo-image,
	.home-page-container__vote-image,
	.home-page-container__tell-us-image {
		background-size: cover;
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: 1;
	}

	.home-page-container__involved-tile--top {
		height: 60%;
	}

	.home-page-container__involved-tile--bottom {
		height: 115px;
	}

	.home-page-container__services-block-left {
		-webkit-flex-grow: 1;
		        flex-grow: 1;
		-webkit-flex-shrink: 1;
		        flex-shrink: 1;
		-webkit-flex-basis: calc(50% - 32px);
		        flex-basis: calc(50% - 32px);
	}

	.home-page-container__services-block-right {
		-webkit-flex-grow: 1;
		        flex-grow: 1;
		-webkit-flex-shrink: 1;
		        flex-shrink: 1;
		-webkit-flex-basis: calc(50% + 32px);
		        flex-basis: calc(50% + 32px);
	}

	.home-page-container__services-block-left-content {
		margin-top: 40px;
	}

	.home-page-container__services-list-container {
		top: 0;
		width: calc(83.3% - 48px);
	}
}

@media (max-width: 959px) {
	.home-page-container__top-block-container {
		height: auto;
	}

	.home-page-container__top-block {
		-webkit-flex-direction: column-reverse;
		        flex-direction: column-reverse;
	}

	.home-page-container__top-block-info {
		-webkit-align-items: center;
		        align-items: center;
		margin-top: 0;
	}

	.home-page-container__top-block-title {
		margin-top: 65px;
		width: 188px;
	}

	.home-page-container__top-block-text {
		font-size: 27px;
		padding-right: 0;
		width: 548px;
	}

	.home-page-container__top-block-logo {
		height: 488px;
		margin-right: 0;
		width: 100%;
	}

	.home-page-container__services-block {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}

	.home-page-container__services-block-left {
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-flex: 1 1 100%;
		        flex: 1 1 100%;
	}

	.home-page-container__services-block-left-content {
		-webkit-align-items: center;
		        align-items: center;
		max-width: 100%;
		-webkit-flex-direction: column;
		        flex-direction: column;
		margin-left: 0;
		width: 100%;
	}

	.home-page-container__services-block-title {
		font-size: var(--font-size-giant-xxs);
		line-height: 49px;
		height: auto;
		margin-bottom: 12px;
		max-width: 583px;
		text-align: center;
	}

	.home-page-container__services-block-text {
		font-size: var(--font-size-m);
		line-height: var(--line-height-m);
		height: auto;
		margin-bottom: 22px;
		max-width: 583px;
		text-align: center;
	}

	.home-page-container__services-list-container {
		margin-top: 3%;
		max-width: 500px;
		width: 70%;
	}

	.home-page-container__services-block-right {
		margin-top: 30px;
		min-height: 460px;
		padding-left: 0;
	}

	.home-page-container__services-block-right-content {
		margin-top: 0;
		width: calc(91.6% - 32px);
	}

	.home-page-container__knowledge-base-tiles-container {
		width: calc(100% - 64px);
		-webkit-justify-content: center;
		        justify-content: center;
		left: 32px;
		margin-left: 0;
		right: auto;
	}

	.home-page-container__built-tool-container {
		margin: 0 auto;
		width: calc(83.3% - 48px);
	}

	.home-page-container__knowledge-base-background-container {
		margin-top: 22%;
	}

	.home-page-container__involved-tile:nth-child(2) {
		margin: 0 24px;
	}

	.home-page-container__build-tool-right-content {
		height: 400px;
	}

	.home-page-container__top-block-logo {
		margin-top: 0;
		padding-bottom: 68px;
	}

	.home-page-container__involved-title {
		max-width: 582px;
	}

	.home-page-container__vote-image {
		background-position: -50px;
	}
}

@media (max-width: 800px) {
	.home-page-container__cdo-image {
		background-position: -29px;
	}

	.home-page-container__vote-image {
		background-position: -70px;
	}

	.home-page-container__tell-us-image {
		background-position: -29px;
	}
}

@media (max-width: 767px) {
	.home-page-container__top-block-text {
		font-size: var(--font-size-m);
		font-weight: 500;
		line-height: var(--line-height-m);
		max-width: 90%;
		text-align: center;
	}

	.home-page-container__top-block-title {
		height: auto;
		margin: 56px 0 40px 0;
		width: auto;
	}

	.home-page-container__top-block-logo-container {
		-webkit-justify-content: center;
		        justify-content: center;
	}

	.home-page-container__top-block-logo {
		height: 0;
		min-width: 410px;
		padding-top: 60%;
	}

	.home-page-container__services-block-left {
		margin-top: 62px;
	}

	.home-page-container__services-block-left-content {
		margin: 0;
	}

	.home-page-container__services-block-right {
		-webkit-justify-content: center;
		        justify-content: center;
		margin: 0;
		min-height: auto;
		padding-left: 0;
	}

	.home-page-container__services-block-right-content {
		height: auto;
		-webkit-justify-content: center;
		        justify-content: center;
		margin-bottom: 56px;
		width: 90%;
	}

	.home-page-container_honeycomb-background {
		display: none;
	}

	.home-page-container__services-list-container {
		margin-top: 24px;
		max-width: 550px;
		position: relative;
		width: 100%;
	}

	.home-page-container__knowledge-base-block {
		margin-top: 0;
	}

	.home-page-container__knowledge-base-right {
		-webkit-justify-content: center;
		        justify-content: center;
		margin: 0;
		padding: 0;
	}

	.home-page-container__knowledge-base-right .home-page-container__info-block-text,
	.home-page-container__knowledge-base-right .home-page-container__info-block-title {
		padding-right: 0;
	}

	.home-page-container__involved-title,
	.home-page-container__info-block-title,
	.home-page-container__services-block-title,
	.home-page-container__info-block-title {
		font-size: 23px;
		font-weight: 500;
		line-height: 31px;
		letter-spacing: 0.3px;
		text-align: center;
		margin-bottom: 16px;
		max-width: 90%;
	}

	.home-page-container__involved-text,
	.home-page-container__services-block-text,
	.home-page-container__info-block-text {
		font-size: var(--font-size-xs);
		letter-spacing: 0.8px;
		line-height: var(--line-height-xs);
		margin: 0 0 32px 0;
		max-width: 90%;
		text-align: center;
	}

	.home-page-container__info-block-title .home-page-container__green-text {
		display: block;
		margin-bottom: 16px;
		margin-left: 0;
	}

	.home-page-container__info-block-text {
		margin-bottom: 40px;
	}

	.home-page-container__info-block-title {
		margin-bottom: 0;
	}

	.home-page-container__build-tool-left-content .home-page-container__info-block-text {
		margin: 16px 0 32px 0;
	}

	.home-page-container__info-block-button .button--primary {
		height: 29px;
		margin-top: 0;
		margin-bottom: 32px;
		padding-top: 5px;
		width: 120px;
	}

	.home-page-container__build-tool-right {
		-webkit-flex: 1 1 100%;
		        flex: 1 1 100%;
		margin-bottom: 24px;
	}

	.home-page-container__build-tool-right-content {
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		height: auto;
	}

	.home-page-container__build-tool-background-block {
		-webkit-align-self: flex-end;
		        align-self: flex-end;
		height: 0;
		margin: 24px 0 0 0;
		padding-top: 38%;
	}

	.home-page-container__built-tool-container {
		height: 0;
		max-width: 550px;
		padding-top: 45%;
		position: relative;
		width: 90%;
	}

	.home-page-container__build-tool-schema-block {
		position: absolute;
		height: 90%;
		left: 5%;
		padding: 0;
		top: 10%;
		width: 90%;
	}

	.home-page-container__involved-block {
		margin-top: 56px;
	}

	.home-page-container__knowledge-base-tiles-container {
		-webkit-align-items: center;
		        align-items: center;
		-webkit-align-self: center;
		        align-self: center;
		-webkit-flex-direction: column;
		        flex-direction: column;
		left: 0;
		max-width: 550px;
		position: relative;
		width: 90%;
	}

	.home-page-container__knowledge-base-tile {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		height: 88px;
		width: 100%;
	}

	.home-page-container__knowledge-base-tile-benchmarks,
	.home-page-container__knowledge-base-tile-commodities,
	.home-page-container__knowledge-base-tile-registers {
		-webkit-align-items: center;
		        align-items: center;
		display: -webkit-flex;
		display: flex;
		height: 100%;
		width: 16.66%;
	}

	.home-page-container__knowledge-base-tile-bottom {
		height: 100%;
		padding: 16px 24px;
		width: 83.34%;
	}

	.home-page-container__knowledge-base-tile:nth-child(2) {
		margin: 16px 0;
	}

	.home-page-container__knowledge-base-background-container {
		position: absolute;
		height: 280px;
		margin-top: 24px;
		padding-top: 0;
		width: 100%;
	}

	.home-page-container__benchmark-logo {
		height: 22px;
		width: 18px;
	}

	.home-page-container__commodities-logo {
		height: 14px;
		width: 20px;
	}

	.home-page-container__registers-logo {
		height: 18px;
		width: 18px;
	}

	.home-page-container__knowledge-base-left {
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
		margin-bottom: 24px;
	}

	.home-page-container__knowledge-tile-header {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		margin-bottom: 4px;
	}

	.home-page-container__knowledge-tile-text {
		font-size: var(--font-size-xxs);
		line-height: var(--line-height-xxs);
	}

	.home-page-container__knowledge-tile-arrow,
	.home-page-container__involved-tile-arrow {
		bottom: 10px;
		right: 12px;
	}

	.home-page-container__involved-actions-container {
		-webkit-flex-direction: column;
		        flex-direction: column;
		margin: 0 auto 90px;
		max-width: 550px;
		width: 90%;
	}

	.home-page-container__involved-tile {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		height: 72px;
		width: 100%;
	}

	.home-page-container_involved-tile-title {
		font-size: var(--font-size-s);
		font-weight: bold;
		line-height: var(--line-height-s);
		max-width: 85%;
	}

	.home-page-container__involved-tile--bottom {
		height: 100%;
		padding: 16px 16px;
		width: calc(100% - 77px);
	}

	.home-page-container__involved-tile--top {
		height: 100%;
		width: 77px;
	}

	.home-page-container__involved-tile:nth-child(2) {
		margin: 16px 0;
	}

	.home-page-container__involved-tile:first-child
		.home-page-container_involved-tile-title {
		max-width: 70%;
	}

	.home-page-container__cdo-logo {
		background-size: cover;
		height: 18px;
		width: 18px;
	}

	.home-page-container__feedback-logo {
		background-size: cover;
		height: 20px;
		width: 20px;
	}

	.home-page-container__idea-logo {
		background-size: cover;
		height: 20px;
		width: 18px;
	}

	.home-page-container__codelibrary-icon {
		background-size: cover;
		height: 20px;
		width: 18px;
		-webkit-transform: scale(1.3);
		        transform: scale(1.3);
	}

	.home-page-container__vote-image,
	.home-page-container__tell-us-image,
	.home-page-container__cdo-image {
		background-position: -42px;
		width: 107px;
	}

	.home-page-container__tell-us-image {
		background-position: -13px;
	}

	.home-page-container__vote-image {
		background-position: -29px;
	}

	.home-page-container__build-tool-block {
		margin-top: 56px;
	}
}

@media (max-width: 750px) {
	.home-page-container__top-block-title svg {
		height: 40px;
		width: 126.67px;
	}
}

.modal {
	position: relative;
	z-index: 0;
}

.modal__overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
	-webkit-animation: fadeIn 666ms var(--timing-function-ease-out-expo);
	        animation: fadeIn 666ms var(--timing-function-ease-out-expo);
}

.modal__wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 3;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	pointer-events: none;
	-webkit-animation: modalScaleIn 666ms var(--timing-function-ease-out-expo);
	        animation: modalScaleIn 666ms var(--timing-function-ease-out-expo);
}

.modal__wrapper > * {
	pointer-events: auto;
}

.dialog {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	background: white;
	width: 500px;
	height: 340px;
	margin: 10px;
	border-radius: 2px;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.dialog__header {
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	height: 63px;
	padding: 18px 16px 18px 32px;
	border-bottom: 1px solid var(--color-grey-300);
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-bold);
}

.dialog__title {
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height-m);
	color: var(--color-grey-800);
	letter-spacing: 0.3px;
}

.dialog__close-button {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-left: auto;
	width: 24px;
	height: 24px;
	color: var(--color-grey-400);
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	border: 0;
	padding: 0;
	transition: color 133ms var(--timing-function-ease-out-expo),
		-webkit-transform 133ms var(--timing-function-ease-out-expo);
	transition: color 133ms var(--timing-function-ease-out-expo),
		transform 133ms var(--timing-function-ease-out-expo);
	transition: color 133ms var(--timing-function-ease-out-expo),
		transform 133ms var(--timing-function-ease-out-expo),
		-webkit-transform 133ms var(--timing-function-ease-out-expo);
	margin-right: 8px;
}

.dialog__close-button:hover,
.dialog__close-button:focus {
	color: var(--color-grey-700);
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
	outline: none;
}

.dialog__close-button:active {
	-webkit-transform: scale(1);
	        transform: scale(1);
}

.dialog__content {
	-webkit-flex: 1 1;
	        flex: 1 1;
	padding: 32px;

	/* NOTE:
		Sometimes this element may contain children which handles its own overflow.
		There's a flexbox item bug which means that when a scrollable element is
		inside an unkown height element, it never scrolls.
		The "min-height" property forces this element to stretch to the remaining
		height and the children can use "height: 100%" and "overflow-y: auto":
		https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336
	*/
	min-height: 0;
}

.dialog__footer {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	padding: 12px 32px;
	border-top: 1px solid var(--color-grey-300);
}

.dialog__footer button + button {
	margin-left: 16px;
}

.error-dialog {
	height: auto;
	max-height: 560px;
}

.error-dialog__title-icon {
	color: var(--color-danger);
	margin-right: 10px;
}

.error-dialog__content {
	overflow-y: auto;
}

.error-dialog__content p {
	margin-bottom: 16px;
}

.error-dialog__content pre {
	background: var(--color-grey-100);
	padding: 10px;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	overflow-x: auto;
}

.button {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	text-transform: uppercase;
	outline: 0;
	border: 0;
	background-color: transparent;
	display: inline-block;
	text-align: center;
	border-radius: 1px;
	padding: 8px 16px;
	transition: all 333ms var(--timing-function-ease-out-expo);
	cursor: pointer;
}

.button:disabled {
	pointer-events: none;
	cursor: not-allowed;
}

.button--primary {
	background-color: var(--color-brand);
	color: white;
}

.button--primary:focus {
	box-shadow: 0 0 0 3px rgba(92, 178, 71, 0.5);
}

.button--primary:hover {
	background-color: var(--color-brand-dark);
}

.button--primary:active {
	background-color: var(--color-brand-darkest);
}

.button--primary:disabled {
	background-color: var(--color-grey-300);
}

.button--secondary {
	color: var(--color-grey-700);
}

.button--secondary:focus {
	box-shadow: 0 0 0 3px rgba(204, 204, 204, 0.5);
}

.button--secondary:hover {
	color: var(--color-brand-dark);
}

.button--secondary:active {
	color: var(--color-brand-darkest);
}

.button--secondary:disabled {
	color: var(--color-grey-300);
}

.button--danger {
	color: var(--color-danger);
}

.button--danger:focus {
	box-shadow: 0 0 0 3px var(--color-danger);
}

.button--danger:hover {
	color: var(--color-danger-dark);
}

.button--danger:disabled {
	color: var(--color-grey-300);
}

.button--icon {
	display: -webkit-inline-flex;
	display: inline-flex;
	border: none;
	padding: 0;
	color: var(--color-grey-400);
}

.button--icon + .button--icon {
	margin-left: 16px;
}

.button--icon:hover,
.button--icon:focus {
	color: var(--color-grey-700);
	outline: none;
}

.button--add {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-left: auto;
}

.button--add svg {
	margin-right: 5px;
}

.legacy-overlay {
	position: fixed;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: white;
	z-index: 10000;
	overflow: hidden;
}

.legacy-overlay + .page,
.legacy-overlay + .home {
	display: none;
}

.legacy-overlay__logo {
	color: black;
	padding-right: 5px;
}

.legacy-overlay__copy {
	color: var(--color-grey-500);
	font-size: 19px;
	letter-spacing: 0.36px;
	line-height: 24px;
}

.legacy-overlay__copy--highlighted {
	color: var(--color-grey-700);
	font-weight: var(--font-weight-medium);
}

.legacy-overlay__header {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	padding: 56px 0 73px 0;
}

.legacy-overlay__image {
	background-image: url(/images/legacy-browser-overlay.jpg);
	width: 627px;
	height: 271px;
	background-repeat: no-repeat;
	background-size: contain;
}

.legacy-overlay__copy-url {
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	line-height: 19px;
	padding-top: 35px;
	text-decoration: underline;
	display: block;
}

.editable-list__item {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.editable-list__item--divider {
	border-bottom: 1px solid var(--color-grey-300);
}

.editable-list__status {
	margin-right: 14px;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	background-color: currentColor;
}

.editable-list__status--active {
	color: #9fda93;
}

.editable-list__status--inactive {
	color: var(--color-grey-300);
}

.editable-list__status--orphan {
	color: var(--color-danger);
}

.editable-list__button {
	outline: 0;
	border: 0;
	text-align: left;
	border-radius: 0;
	-webkit-flex: 1 1;
	        flex: 1 1;
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	padding: 10px 0;
	color: var(--color-grey-700);
	transition: all 333ms var(--timing-function-ease-out-expo);
}

.editable-list__button:disabled {
	pointer-events: none;
}

.editable-list__button:focus,
.editable-list__button:hover {
	color: var(--color-brand);
}

.editable-list__button:active {
	color: var(--color-brand-darkest);
}

.editable-list__footer {
	display: -webkit-flex;
	display: flex;
	padding-top: 16px;
}

.editable-list--dragging {
	margin-bottom: 48px; /*one item height - This needs to be added not to displace the bottom button*/
}

.editable-list__item-drag-handle {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	color: var(--color-grey-400);
	margin-right: 5px;
}

.editable-list__item-drag-handle:hover {
	color: var(--color-grey-700);
}

.tab__header {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	border-bottom: 1px solid var(--color-grey-200);
	padding-bottom: 40px;
	padding: 24px 40px;
}

.tab__title {
	font-size: var(--font-size-xl);
	line-height: var(--line-height-xl);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
}

.tab__content {
	padding: 20px 40px;
	min-height: 250px;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: calc(100vh - 150px);
}

.tab__section + .tab__section {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid var(--color-grey-200);
}

.tab__section-title {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-medium);
	padding-bottom: 10px;
	color: var(--color-grey-400);
}

.tab__fieldset-row {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 32px;
	grid-row-gap: 32px;
}

.tab__fieldset-row .tab__fieldset + .tab__fieldset {
	margin-top: 0;	
}

.tab__fieldset {
	border: 0;
	padding: 0;
	margin: 0;
}

.tab__fieldset + .tab__fieldset {
	margin-top: 24px;
}

.tab__fieldset-title {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-medium);
	color: var(--color-grey-800);
	display: block;
	margin-bottom: 10px;
}

.tab__input {
	outline: none;
	width: 100%;
	padding: 6px 11px;
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-regular);
	color: var(--color-grey-700);
	border: 1px solid var(--color-grey-300);
}

.tab__input:hover,
.tab__input:focus {
	border-color: var(--color-grey-400);
}

.tab__textarea {
	padding: 16px;
	border: 1px solid var(--color-grey-300);
	border-radius: 2px;
	outline: 0;
	background-color: white;
	min-width: 100%;
	width: 100%;
	min-height: 180px;
}

.tab__validation-error {
	display: block;
	padding: 10px 0 0;
	color: var(--color-danger);
}

.tab__actions {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-left: auto;
}

.tab__actions button + button {
	margin-left: 16px;
}

.delete-item-dialog {
	height: auto;
}

.delete-item-dialog__paragraph {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
}

.delete-item-dialog__name {
	font-weight: var(--font-weight-bold);
}

.title-input {
	padding: 0;
	border: 0;
	border-bottom: 1px solid var(--color-grey-300);
	max-width: 500px;
	width: 100%;
	background-color: transparent;
	transition: border-color 100ms ease-out;
}

.title-input--theme-admin {
	font-size: var(--font-size-xl);
	line-height: var(--line-height-xl);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	margin-bottom: -1px;
	margin-right: 8px;
}

.title-input--theme-dialog {
	font-size: var(--font-size-xl);
	line-height: var(--line-height-xl);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	margin-right: 24px;
}

.title-input:focus {
	border-color: var(--color-grey-700);
	outline: none;
}

.label {
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	letter-spacing: 0.8px;
	text-transform: uppercase;
}

.label + input,
.label + textarea,
.label + select,
.label + .select {
	margin-top: 8px;
}

.searchable-select {
	background-color: white;
}

.searchable-select__wrapper {
	position: relative;
	margin-top: 16px;
}

.searchable-select__placeholder {
	position: absolute;
	top: 4.5px;
	left: 8.5px;
	margin: 0;
	color: var(--color-grey-800);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	z-index: 0;
}

.searchable-select .searchable-select__menu {
	z-index: 99999;
	margin-top: 0;
	border-radius: 0;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.searchable-select .searchable-select__group-heading {
	padding: 10px 16px;
	background-color: var(--color-brand-lightest);
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	margin-bottom: 8px;
	text-transform: capitalize;
}
.searchable-select .searchable-select__option {
	padding: 8px 16px;
	color: var(--color-grey-500);
	font-size: var(--font-size-s);
	letter-spacing: 0.3px;
	line-height: var(--line-height-s);
}

.searchable-select .searchable-select__menu-list {
	padding-top: 0;
}

.searchable-select .searchable-select__group {
	padding-top: 0;
}

.searchable-select .searchable-select__multi-value {
	display: none;
}

.searchable-select .searchable-select__control {
	height: 32px;
	min-height: 32px;
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	transition-property: border-color;
	transition-duration: 333ms;
	background-color: transparent;
	z-index: 1;
}
.searchable-select .searchable-select__control:hover,
.searchable-select .searchable-select__control--is-focused {
	border-color: var(--color-grey-400);
	box-shadow: none;
}

.searchable-select__value-container {
	padding: 1.5px 8px;
}

.searchable-select__indicator-separator {
	display: none;
}

.searchable-select .searchable-select__dropdown-indicator {
	padding: 0 8px;
	color: var(--color-grey-400);
}

.searchable-select__dropdown-indicator .arrow-icon {
	visibility: visible;
	opacity: 1;
	position: absolute;
	top: 3.5px;
	right: 4.5px;
	transition: visibility opacity 666ms var(--timing-function-ease-out-expo);
}

.searchable-select__dropdown-indicator .search-icon {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	top: 7.5px;
	right: 4.5px;
	transition: visibility opacity 666ms var(--timing-function-ease-out-expo);
}

.searchable-select__control--menu-is-open
	.searchable-select__dropdown-indicator
	.arrow-icon {
	visibility: hidden;
	opacity: 0;
}

.searchable-select__control--menu-is-open .search-icon {
	visibility: visible;
	opacity: 1;
}

.editor-page-layout {
	display: -webkit-flex;
	display: flex;
	overflow: hidden;
}

.editor-page-layout,
.editor-page-layout__left,
.editor-page-layout__right {
	height: calc(100vh - 64px); /*Header nav height*/
}

.editor-page-layout__left-inner {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	padding: 40px 32px 40px 40px;
	max-height: calc(100vh - 64px);
	overflow-x: hidden;
	overflow-y: auto;
}

.editor-page-layout__right-inner {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	min-height: 100%;
	background-color: var(--color-grey-100);
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
	padding: 40px 40px 0 32px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	max-height: calc(100vh - 64px);
	overflow-x: hidden;
	overflow-y: auto;
}

.editor-page-layout__left {
	width: 68%;
	overflow: auto;
}

.editor-page-layout__right {
	width: 32%;
	overflow: auto;
}

.editor-page-fieldset {
	padding: 0;
	outline: none;
	border: none;
	margin: 0 0 24px 0;
}

.editor-page-separator {
	height: 1px;
	width: 100%;
	background-color: var(--color-grey-200);
	margin-bottom: 27px;
}

.editor-page-separator.dark {
	background-color: var(--color-grey-300);
}

.editor-page-section-title {
	color: var(--color-grey-800);
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-bold);
	margin-bottom: 16px;
}

.editor-page-section-title--admin {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-medium);
	padding-bottom: 10px;
	color: var(--color-grey-400);
}

.editor-section-actions {
	min-height: 64px;
	max-height: 64px;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	border-top: 1px solid var(--color-grey-200);
	padding-left: 32px;
	padding-right: 40px;
	margin-left: -32px; /*for full border*/
	width: calc(100% + 72px); /*padding left and right of the right section*/
	background-color: var(--color-grey-100);
	z-index: 1;
}

.editor-section-actions--fixed {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
}

.editor-section-actions .button + .button {
	margin-left: 5px;
}

.editor-section-info-label{
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-top: 24px;
	margin-bottom: 19px;
}

.editor-page-order-change-label {
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	cursor: pointer;
	text-transform: uppercase;
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	line-height: var(--line-height-xxs);
	margin-left: 30px;
}

.experts-reorder-list {
	margin-bottom: 24px;
}

.expert-list__item {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.expert-list__name {
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
	overflow: hidden;
	outline: 0;
	border: 0;
	text-align: left;
	border-radius: 0;
	-webkit-flex: 1 1;
	        flex: 1 1;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	padding: 10px 0;
	color: var(--color-grey-700);
	transition: all 333ms var(--timing-function-ease-out-expo);
	letter-spacing: 0.3px;
}

.expert-list__name.inActive {
	pointer-events: none;
	color: var(--color-grey-500);
}

.expert-list__footer {
	display: -webkit-flex;
	display: flex;
	padding-top: 16px;
}

.expert-list__item-drag-handle {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	color: var(--color-grey-400);
	margin-right: 24px;
}

.expert-list__item-drag-handle:hover {
	color: var(--color-grey-700);
}

.expert-list__item-delete:hover {
	color: var(--color-danger);
}

.expert-list--dragging {
	margin-bottom: 68px; /*one item height - This should not disturb bottom section moving as item is displaced*/
}
.toggle {
	position: relative;
	display: -webkit-inline-flex;
	display: inline-flex;
}

.toggle__checkbox {
	opacity: 0;
	width: 0;
	height: 0;
}

.toggle__slider {
	position: absolute;
	cursor: pointer;
	width: 32px;
	height: 18px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	outline: none;
	background-color: white;
	border: 2px solid var(--color-grey-300);
	border-radius: 15px;
	transition: all 333ms var(--timing-function-ease-out-expo);
}

.toggle__label {
	color: var(--color-grey-800);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-left: 42px;
}

.toggle__checkbox:focus + .toggle__slider::after,
.toggle__checkbox:active + .toggle__slider::after {
	display: block;
}

.toggle__slider::after {
	content: '';
	display: none;
	position: absolute;
	top: -5px;
	left: -5px;
	border-radius: 15px;
	height: 24px;
	width: 38px;
	border: 2px solid #ccc;
}

.toggle__slider::before {
	content: '';
	position: absolute;
	height: 10px;
	width: 10px;
	left: 2px;
	bottom: 2px;
	background-color: var(--color-grey-300);
	border-radius: 50%;
	transition: all 333ms var(--timing-function-ease-out-expo);
}

.toggle__checkbox:checked + .toggle__slider {
	border-color: var(--color-brand);
}

.toggle__checkbox:checked + .toggle__slider::before {
	-webkit-transform: translateX(14px);
	        transform: translateX(14px);
	background-color: var(--color-brand);
}

.toggle__checkbox:not([disabled]) + .toggle__slider:hover::before {
	background-color: var(--color-brand-dark);
}

.toggle__checkbox:disabled + .toggle__slider {
	border-color: var(--color-grey-200);
}

.toggle__checkbox:disabled + .toggle__slider::before {
	background-color: var(--color-grey-300);
}

.SingleDatePicker .DayPicker_transitionContainer {
	min-height: 334px;
}

.SingleDatePicker .SingleDatePickerInput {
	border-color: var(--color-grey-300);
}

.SingleDatePicker .SingleDatePickerInput:hover,
.SingleDatePicker .SingleDatePickerInput:focus {
	border-color: var(--color-grey-400);
}

.SingleDatePicker .SingleDatePickerInput:focus {
	outline: 0;
}

.SingleDatePicker .SingleDatePickerInput__withBorder {
	border-radius: 1px;
}

.SingleDatePickerInput_calendarIcon {
	color: var(--color-grey-400);
	padding: 0;
	outline: none;
}

.SingleDatePickerInput_calendarIcon:focus,  .SingleDatePickerInput_calendarIcon:active{
	color: var(--color-brand);
}

.SingleDatePicker .DayPicker__withBorder {
	border-radius: 1px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px var(--color-grey-300);
}

.SingleDatePicker .DateInput_fang {
	left: 8px;
}

.SingleDatePicker .DateInput_fangStroke {
	stroke: var(--color-grey-300);
}

.SingleDatePicker .DateInput {
	width: 160px;
}

.SingleDatePicker .DateInput_input {
	border-bottom: 0;
	padding: 6px 11px;
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-regular);
	color: var(--color-grey-700);
}

.SingleDatePicker .SingleDatePickerInput_arrow {
	display: none;
}

.SingleDatePicker .CalendarDay__default {
	color: var(--color-grey-700);
}

.SingleDatePicker .CalendarDay__hovered_span,
.SingleDatePicker .CalendarDay__hovered_span:hover {
	background-color: #c0f2b3;
	border-color: #95e880;
	color: #028700;
}

.SingleDatePicker .CalendarDay__selected,
.SingleDatePicker .CalendarDay__selected:active,
.SingleDatePicker .CalendarDay__selected:hover {
	background-color: #21a600;
	border-color: #21a600;
	color: white;
}

.SingleDatePicker .CalendarDay__selected_span {
	background-color: #7de366;
	border-color: #53d932;
	color: white;
}

.SingleDatePicker .CalendarDay__selected_span:active,
.SingleDatePicker .CalendarDay__selected_span:hover {
	background-color: #53d932;
	border-color: #53d932;
	color: white;
}

.SingleDatePicker .DayPickerNavigation_button {
	border-color: var(--color-grey-300);
}

.SingleDatePicker .DayPickerNavigation_button:hover,
.SingleDatePicker .DayPickerNavigation_button:focus {
	border-color: var(--color-grey-400);
}

.SingleDatePicker .DayPickerNavigation_button:focus {
	outline: 0;
	background-color: transparent;
}

.select {
	position: relative;
}

.select__hidden-select {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	opacity: 0;
	z-index: 1;
	border: 0;
	outline: 0;
}

.select__label {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	padding: 6px 11px;
	border-radius: 1px;
	transition: all 333ms var(--timing-function-ease-out-expo);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-regular);
	color: var(--color-grey-800);
	border: 1px solid var(--color-grey-300);
	background-color: white; 
}

.select--theme-admin .select__label {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-regular);
	color: var(--color-grey-700);
	border: 1px solid var(--color-grey-300);
	background-color: white;
}

.select__label::after {
	content: '';
	margin-left: 5px;
	display: inline-block;
	transition: all 333ms var(--timing-function-ease-out-expo);
	color: var(--color-grey-300);
	/* The code below is generated from polished triangle mixin */
	width: 0;
	height: 0;
	border-style: solid;
	border-top-color: currentColor;
	border-right-color: transparent;
	border-bottom-color: transparent;
	border-left-color: transparent;
	border-width: 5px 5px 0 5px;
}

.select--theme-admin .select__label::after {
	border-width: 6px 6px 0 6px;
}

.select__hidden-select:hover + .select__label,
.select__hidden-select:focus + .select__label {
	border-color: var(--color-grey-400);
}

.select__hidden-select:hover + .select__label::after,
.select__hidden-select:focus + .select__label::after {
	color: var(--color-grey-400);
}

.file__link {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-align-items: center;
	        align-items: center;
	transition: color 333ms var(--timing-function-ease-out-expo);
}

.file__link svg {
    margin-left: 10px;
}

.file__link:hover,
.file__link:hover svg {
	color: var(--color-brand);
}

.selected-items__list {
  margin-top: 16px;
}

.selected-items__list-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 8px 4px 8px 0;
}

.selected-items__list-delete-button {
	color: var(--color-grey-400);
}

.selected-items__list-delete-button:hover {
	color: var(--color-danger);
}

.products-select .searchable-select__menu {
	position: relative;
}

.areas-select .searchable-select__menu {
	position: relative;
}

.admin-person-tab .editor-page-fieldset label {
	text-transform: none;
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-medium);
	color: var(--color-grey-800);
}

.admin-person-tab .products-select-list,
.admin-person-tab .areas-select-list {
  margin-top: 24px;
}

.admin {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.admin__header {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	height: 64px;
	padding: 0 40px;
	background-color: var(--color-grey-700);
}

.admin__logo-link {
	display: -webkit-flex;
	display: flex;
	-webkit-align-self: stretch;
	        align-self: stretch;
	-webkit-align-items: center;
	        align-items: center;
}

.admin .admin__navbar {
	display: -webkit-inline-flex;
	display: inline-flex;
	position: relative;
}

.admin__navbar-item {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-align-items: center;
	        align-items: center;
	color: var(--color-grey-400);
}

.admin__navbar-item + .admin__navbar-item {
	margin-left: 26px;
}

.admin__navbar-link {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
	outline: 0;
	height: 64px;
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-align-items: center;
	        align-items: center;
	padding: 0 10px;
	transition-property: color;
	transition-duration: 333ms;
	transition-timing-function: var(--timing-function-ease-out-expo);
}

.admin__navbar-link:focus,
.admin__navbar-link:hover {
	color: white;
}

.admin__navbar-link.active {
	color: white;
}

.admin__navbar-link + .admin__navbar-link {
	margin-left: 16px;
	margin-right: 24px;
}

.admin__icon-button {
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	outline: 0;
	border: 0;
	padding: 0;
	transition-property: color;
	transition-duration: 333ms;
	transition-timing-function: var(--timing-function-ease-out-expo);
}

.admin__icon-button:focus,
.admin__icon-button:hover {
	color: white;
}

.admin__icon-button + .admin__icon-button {
	padding-left: 18px;
}

.admin__icon-button svg {
	display: -webkit-flex;
	display: flex;
}

.admin__main {
	-webkit-flex: 1 1;
	        flex: 1 1;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	background-color: var(--color-grey-100);
	position: relative;
}

.admin__subnav-panel {
	width: 25vw;
	background: white;
	box-shadow: 7px 0px 15px 0px rgba(0, 0, 0, 0.05);
	z-index: 2;
}

.admin__tab-panel {
	width: 75vw;
	position: relative;
	overflow-x: hidden;
}

.admin__subnav {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	padding-top: 40px;
}

.admin__subnav-link {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	padding: 12px 16px 12px 28px;
	border-left: 4px solid transparent;
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	outline: none;
	transition-property: border-left-color;
	transition-duration: 333ms;
	transition-timing-function: var(--timing-function-ease-out-expo);
}

.admin__subnav-link--sub::before {
	content: '';
	width: 20px;
	height: 1px;
	margin-right: 16px;
	background-color: var(--color-grey-300);
	transition-property: background-color;
	transition-duration: 333ms;
	transition-timing-function: var(--timing-function-ease-out-expo);
}

.admin__subnav-link.active {
	border-left-color: var(--color-brand);
	background-color: var(--color-brand-lightest);
}

.admin__subnav-link--sub.active::before {
	background-color: var(--color-brand);
}

.admin__subnav-link:hover,
.admin__subnav-link:focus {
	background-color: var(--color-brand-lightest);
}

.grid-select {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 33px;
}
 
.grid-select:hover .grid-select-item.transition-enter-done {
  -webkit-transform: none;
          transform: none;
}

.grid-select:after {
  content: ' ';
  width: 32%;
}

.grid-select-add-button {
	position: relative;
	width: 32%;
	min-height: 144px;
	margin-bottom: 32px;
	padding: 24px 32px;
	border: 1px solid transparent;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
  background-color: var(--color-brand-light);
  color: var(--color-brand);
	cursor: pointer;
  outline: none;
  transition: all .3s ease-out;
}

.grid-select-add-button:hover {
  border: 1px solid var(--color-brand-dark);
	color: var(--color-brand-dark);
}

.tippy-popper .app-tooltip-theme.settings-tooltip-theme {
	padding: 0;
	border-radius: 1px;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.popout-menu-theme.settings-tooltip-theme .tippy-tooltip-content {
	padding: 0;
}

.popout-menu {
	color: var(--color-grey-400);
	-webkit-animation: color 300ms ease-in;
	        animation: color 300ms ease-in;
}

.popout-menu.dark-nav:hover,
.popout-menu.dark-nav.active {
	color: var(--color-grey-600);
}

.popout-menu.light-nav.active {
	color: var(--color-brand-dark);
}

.popout-menu__list-item {
	background: white;
	-webkit-animation: background-color 300ms ease-in;
	        animation: background-color 300ms ease-in;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	min-height: 32px;
	min-width: 120px;
}

.popout-menu__list-item:hover {
	background-color: var(--color-brand-lightest);
}

.popout-menu__list-item:hover .popout-menu__list-button {
	color: var(--color-grey-800);
}

.popout-menu__list-button {
	color: var(--color-grey-500);
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	outline: 0;
	border: 0;
	padding: 0;
	transition-property: color;
	transition-duration: 333ms;
	transition-timing-function: var(--timing-function-ease-out-expo);
	cursor: pointer;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	text-align: start;
	width: 100%;
	padding: 8px 16px;
}

.grid-select-menu__button {
	display: block;
	width: 100%;
	padding: 14px;
	margin: 0;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	text-align: left;
	font-size: 14px;
	font-size: var(--font-size-sm);
}

.grid-select-menu__more-icon {
	padding: 10px 20px 0 0;
	height: 40px;
}
.area-grid-select-item {
	position: relative;
	width: 32%;
	min-height: 143px;
	margin-bottom: 32px;
	padding: 40px 32px 16px;
	border: 1px solid var(--color-grey-300);
	transition: all 0.3s ease-out;
}

.area-grid-select-item:hover {
	border-color: var(--color-grey-400);
	cursor: pointer;
}

.area-grid-select-item__contents {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	min-height: 50px;
}

.area-grid-select-item__title {
	font-size: 20px;
	font-weight: 500;
	max-height: 60px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	color: var(--color-grey-800);
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-regular);
}

.area-grid-select-item__menu {
	position: absolute;
	right: 15px;
	top: -2px;
}

.area-grid-select-item__draft-label,
.area-grid-select-item__live-label {
	margin-right: auto;
}

.area-grid-select-item__draft-label,
.area-grid-select-item__published-label,
.area-grid-select-item__live-label {
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.8px;
}

.area-grid-select-item__draft-label,
.area-grid-select-item__published-label {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	text-align: left;
	font-weight: var(--font-weight-regular);
}

.area-grid-select-item__published-label {
	text-align: right;
}

.area-grid-select-item__footer {
	border-top: 1px solid var(--color-grey-200);
	min-height: 36px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.area-grid-select-item__footer-line {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

.product-grid-select-item__footer-type {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
}

.max-width__layout {
	max-width: 1600px;
  margin: 0 auto;
  height: 100%;
}

.max-width__layout--full-width {
  min-width: 100%;
}


@media (min-width: 1600px) {
	.max-width__layout--full-width {
    min-width: 1600px;
  }
}
.page-section-title {
  margin-bottom: 24px;
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	color: var(--color-grey-800);
	text-transform: uppercase;
	transition: all 300ms ease-in;
}

.page-section-title--no-margin {
	margin-bottom: 0 !important;
}

@media (min-width: 768px) {
	.page-section-title {
		margin-bottom: 32px;
		font-size: var(--font-size-xl);
		line-height: var(--line-height-xl);
		font-weight: var(--font-weight-medium);
	}
}
.reorder-items-dialog {
	height: auto;
	max-height: 75%;
	width: 50%;
}

.reorder-items-dialog .dialog__content {
	overflow-y: auto;
}

.reorder-items-dialog .dialog__content li {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
}

.reorder-items-dialog__item-drag-handle {
	margin-right: 20px;
	color: var(--color-grey-200);
}

.reorder-items-dialog .dialog__footer {
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.reorder-items-dialog .dialog__header {
	color: var(--color-grey-800);
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height-m);
}

.search-input {
	position: relative;
	height: 48px;
}

.search-input__input {
	width: 100%;
	height: 100%;
	padding: 12px 16px;
	color: var(--color-grey-500);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	border: 1px solid var(--color-grey-300);
	outline: none;
	transition: border 300ms ease-in;
}

.search-input__input:focus, .search-input__input:active{
	border-color: var(--color-grey-400);
}

.search-input__input::-webkit-input-placeholder {
	color: var(--color-grey-500);
}

.search-input__input:-ms-input-placeholder {
	color: var(--color-grey-500);
}

.search-input__input::-ms-input-placeholder {
	color: var(--color-grey-500);
}

.search-input__input::placeholder {
	color: var(--color-grey-500);
}

.search-input__search-icon {
	position: absolute;
	right: 10px;
	top: 12px;
}

.search-input__close-button {
	position: absolute;
	right: 10px;
	top: 12px;
}

.search-input__search-icon {
  color: var(--color-grey-400);
	position: absolute;
	right: 10px;
	top: calc(50% - 10px);
}


.editor-grid-title-row {
	display: -webkit-flex;
	display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 32px; 
}

.editor-grid-title-row__right {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
}
.sort-dropdown__label, .sort-dropdown .menu-dropdown__label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: var(--font-size-xs);
  line-height: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  color: var(--color-grey-700);
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.sort-dropdown__label {
  display: inline-block;
  margin-right: 5px;
}

.sort-dropdown__menu-container{
  min-width: 200px;
  top: 10px;
}

.input {
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	padding: 3px 8px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-800);
	outline: none;
	width: 100%;
	transition: border 300ms ease-in;
}

.input:focus, .input:active {
	border-color: var(--color-grey-400);
}

.input::-webkit-input-placeholder {
	color: var(--color-grey-300);
}

.input:-ms-input-placeholder {
	color: var(--color-grey-300);
}

.input::-ms-input-placeholder {
	color: var(--color-grey-300);
}

.input::placeholder {
	color: var(--color-grey-300);
}
.editor-error-label {
	color: var(--color-danger);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.8px;
}

input + .editor-error-label, .select + .editor-error-label  {
  margin-top: 5px;
}
.dropdown {
	position: relative;
	display: inline-block;
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	padding: 3px 8px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-800);
	outline: none;
	width: 100%;
	transition: border 300ms ease-in;
	background: white;
	margin-top: 8px;
}

.dropdown__label-row {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.dropdown__label {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-800);
	display: block;
	width: 100%;
	font-weight: var(--font-weight-regular);
}

.dropdown__icon {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	color: var(--color-grey-400);
	position: absolute;
	right: 5px;
}

.dropdown__options-container {
	position: absolute;
	/* top: 18px; */
	left: 0;
	z-index: 1;
	background-color: white;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	min-width: 280px;
	width: 100%;
	max-height: 600px;
	overflow-y: auto;
	overflow-x: hidden;
	opacity: 0;
	-webkit-animation: fadeIn 2000ms var(--timing-function-ease-out-expo) 1 forwards;
	        animation: fadeIn 2000ms var(--timing-function-ease-out-expo) 1 forwards;
	-webkit-transform-origin: 0% 0%;
	        transform-origin: 0% 0%;
	margin-top: 5px;
}

.dropdown__options-container--open {
	opacity: 1;
	padding: 8px 0;
}

.dropdown__option {
	min-height: 40px;
	padding: 8px 0;
	transition-property: background-color;
	transition-duration: 300ms;
}

.dropdown__option--none {
	border-bottom: 1px solid var(--color-grey-200);
}

.dropdown__option-button {
	border: none;
	outline: none;
	width: 100%;
	padding: 0 16px;
	color: var(--color-grey-500);
	cursor: pointer;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	text-align: left;
	transition-property: color;
	transition-duration: 300ms;
}

.dropdown__option:hover {
	background-color: var(--color-brand-lightest);
}

.dropdown__option:hover .dropdown__option-button {
	color: var(--color-grey-800);
}

.create-area-dialog {
	height: auto;
	width: 840px;
}

.create-area-dialog__paragraph {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
}

.create-area-dialog__name {
	font-weight: var(--font-weight-bold);
}

.create-area-dialog__input {
	height: 33px;
	padding: 4px 8px;
	font-size: 16px;
	color: var(--color-grey-800);
	width: 100%;
	margin-top: 6px;
}

.create-area-dialog__input-label {
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	letter-spacing: 0.8px;
	text-transform: uppercase;
	display: block;
}

.create-area-dialog__footer {
	padding: 14px 32px 30px 16px;
}
.create-area-dialog__footer .button--secondary {
	padding: 8px 8px;
}
.create-area-dialog__footer .button--primary {
	padding: 6px 24px;
	height: 32px;
}
.create-area-dialog__footer .create-area-dialog__cancel-button {
	margin-right: auto;
	padding: 8px 16px;
}

.areas-list {
	-webkit-flex: 1 1;
	        flex: 1 1;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	position: relative;
	width: 100%;
	margin: 0 auto;
	opacity: 0;
	-webkit-animation: fadeIn 1000ms var(--timing-function-ease-out-expo) 1 forwards;
	        animation: fadeIn 1000ms var(--timing-function-ease-out-expo) 1 forwards;
	padding: 40px;
}

.areas-list__search {
	border: 1px solid var(--color-grey-300);
	background-color: white;
	width: 100%;
	height: 48px;
	line-height: 48px;
	padding: 5px 18px;
}

.areas-list__navbar-button {
	color: var(--color-grey-400);
	font-weight: var(--font-weight-medium);
	cursor: pointer;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	text-transform: none;
	letter-spacing: 0.1px;
}

.areas-list__navbar-button:focus {
	box-shadow: unset;
}

.active .areas-list__navbar-button {
	color: white;
	font-weight: var(--font-weight-bold);
}

.areas-list__navbar-separator--last {
	margin: 3px 32px 0 16px;
}

.areas-list__navbar-separator {
	margin: 3px 15px 0;
}

.areas-list__navbar-separator--first {
	margin: 3px 15px 0 18px;
}

.product-grid-select-item {
	position: relative;
	width: 32%;
	min-height: 143px;
	margin-bottom: 32px;
	padding: 40px 32px 16px;
	border: 1px solid var(--color-grey-300);
	transition: all 0.3s ease-out;
}

.product-grid-select-item:hover {
	border-color: var(--color-grey-400);
	cursor: pointer;
}

.product-grid-select-item__contents {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	min-height: 50px;
}

.product-grid-select-item__title {
	font-size: 20px;
	font-weight: 500;
	max-height: 60px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	color: var(--color-grey-800);
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-regular);
}

.product-grid-select-item__menu {
	position: absolute;
	right: 15px;
	top: -2px;
}

.product-grid-select-item__draft-label,
.product-grid-select-item__live-label {
	margin-right: auto;
}

.product-grid-select-item__draft-label,
.product-grid-select-item__published-label,
.product-grid-select-item__live-label {
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.8px;
}

.product-grid-select-item__draft-label,
.product-grid-select-item__published-label {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	text-align: left;
	font-weight: var(--font-weight-regular);
}

.product-grid-select-item__published-label {
	text-align: right;
}

.product-grid-select-item__footer {
	border-top: 1px solid var(--color-grey-200);
	min-height: 36px;
	padding-top: 16px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
}

.create-product-dialog {
	height: auto;
	width: 840px;
}

.create-product-dialog__paragraph {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
}

.create-product-dialog__name {
	font-weight: var(--font-weight-bold);
}

.create-product-dialog__input {
	height: 33px;
	padding: 4px 8px;
	font-size: 16px;
	color: var(--color-grey-800);
	width: 100%;
	margin-top: 6px;
}

.create-product-dialog__input-label {
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	letter-spacing: 0.8px;
	text-transform: uppercase;
	display: block;
}

.create-product-dialog__footer {
	padding: 14px 32px 30px 16px;
}
.create-product-dialog__footer .button--secondary {
	padding: 8px 8px;
}
.create-product-dialog__footer .button--primary {
	padding: 6px 24px;
	height: 32px;
}
.create-product-dialog__footer .create-product-dialog__cancel-button {
	margin-right: auto;
	padding: 8px 16px;
}

.products-list {
	-webkit-flex: 1 1;
	        flex: 1 1;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	position: relative;
	width: 100%;
	margin: 0 auto;
	opacity: 0;
	-webkit-animation: fadeIn 1000ms var(--timing-function-ease-out-expo) 1 forwards;
	        animation: fadeIn 1000ms var(--timing-function-ease-out-expo) 1 forwards;
	padding: 40px;
}

.products-list__search {
	border: 1px solid var(--color-grey-300);
	background-color: white;
	width: 100%;
	height: 48px;
	line-height: 48px;
	padding: 5px 18px;
}

.products-list__navbar-button {
	color: var(--color-grey-400);
	font-weight: var(--font-weight-medium);
	cursor: pointer;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	text-transform: none;
	letter-spacing: 0.1px;
}

.products-list__navbar-button:focus {
	box-shadow: unset;
}

.active .products-list__navbar-button {
	color: white;
	font-weight: var(--font-weight-bold);
}

.products-list__navbar-separator--last {
	margin: 3px 32px 0 16px;
}

.products-list__navbar-separator {
	margin: 3px 15px 0;
}
.products-list__navbar-separator--first {
	margin: 3px 15px 0 18px;
}

.products-list__title-section {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.products-list__title-section .editor-page-order-change-label {
	margin-top: 14px;
	text-transform: uppercase;
}

.editor-header {
	height: 64px;
	width: 100%;
	padding: 0 40px;
	background-color: var(--color-grey-100);
	border-bottom: 1px solid var(--color-grey-300);
	position: fixed;
	top: 0;
	z-index: 1;
}

.editor-header__inner {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	height: 100%;
}

.editor-header__logo {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.editor-header__logo-separator {
	width: 1px;
	height: 24px;
  background-color: var(--color-grey-300);
  margin-left: 12px;
}

.line-ellipsis a {
  text-decoration: underline;
}
.area-stats-editor {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
  margin-left: 32px;
}

.area-stats-editor__stat,
.area-stats-editor__add-stat {
	opacity: 0;
	-webkit-animation: fadeIn 300ms forwards;
	        animation: fadeIn 300ms forwards;
	outline: 1px solid var(--color-grey-600);
	transition: all 300ms ease-in;
	height: 120px;
	width: 160px;
  position: relative;
  display: -webkit-flex;
  display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 8px 14px 8px;
}

.area-stats-editor__add-stat {
  -webkit-justify-content: center;
          justify-content: center;
  padding-bottom: 0;
}

.area-stats-editor__add-stat:hover {
  color: var(--color-brand);
}

.area-stats-editor__delete-stat {
  position: absolute;
  top: 4px;
  right: 4px;
}

.area-stats-editor__delete-stat:hover {
  color: var(--color-danger-dark);
}

.area-stats-editor__stat + .area-stats-editor__stat,
.area-stats-editor__stat + .area-stats-editor__add-stat {
	margin-left: 30px;
}

.area-stats-editor__stat:hover {
	border-color: var(--color-grey-400);
}

.area-stats-editor__stat.selected {
	outline: 2px solid var(--color-brand);
}


.area-stats-editor__stat-label {
	color: white;
	font-size: var(--font-size-xs);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
  text-align: center;
  max-width: calc(100% - 16px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 16px;
}

.area-stats-editor__stat-value {
	color: white;
	letter-spacing: 0.3px;
	font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  min-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
  text-align: center;
}

.area-stats-editor__no-stat {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  letter-spacing: 0.3px;
  color: var(--color-grey-500);
  padding-bottom: 24px;
}
.area-detail-section {
	position: relative;
	background-color: var(--color-grey-700);
	border: 4px solid var(--color-grey-700);
	min-height: 240px;
	padding: 48px 32px 40px 40px;
	cursor: pointer;
	transition: border-color;
	transition-duration: 300ms;
	width: calc(100% + 72px);
	margin-left: -40px;
	margin-top: -40px;
}

.area-detail-section.selected {
	border-color: var(--color-brand);
}

.area-detail-section__row {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
}

.area-detail-section__left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	width: 45%;
	max-width: 45%;
}

.area-detail-section__no-name-block,
.area-detail-section__no-intro-text-block {
	height: 80px;
	width: 316px;
	border: 1px solid var(--color-grey-600);
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-500);
}

.area-detail-section__no-name-block {
	height: 40px;
	width: 250px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	margin-bottom: 39px;
	transition-property: border;
	transition-duration: 300ms;
}

.area-detail-section__name {
	margin-bottom: 39px;
	color: white;
	font-size: var(--font-size-xxl);
	line-height: var(--line-height-xxl);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	transition-property: border;
	transition-duration: 300ms;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 320px;
	overflow: hidden;
}

.area-detail-section__intro-text {
	color: white;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	min-height: 80px;
	width: 100%;
	max-width: 100%;
	outline: 1px solid var(--color-grey-600);
	padding: 12px;
	transition-property: outline;
	transition-duration: 300ms;
}

.area-detail-section__intro-text.selected,
.area-detail-section__no-intro-text-block.selected,
.area-detail-section__no-name-block.selected {
	outline: 2px solid var(--color-brand);
}

.editor-config-options__section{
	margin-bottom: 40px;
}

.editor-config-options__list-item {
	width: 100%;
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-medium);
	line-height: var(--font-size-xs); /*line height is same as font height*/
	letter-spacing: 0.3px;
	transition-property: color;
  transition-duration: 300ms;
	cursor: pointer;
	text-transform: uppercase;
}

.editor-config-options__list-item:hover {
	color: var(--color-brand-dark);
}

.editor-config-options__list-item + .editor-config-options__list-item {
	margin-top: 27px;
}

.label-with-count{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.label-with-count__value {
  color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
  line-height: var(--line-height-xxs);
  margin-left: 8px;
}

.label-with-count + input, .label-with-count + textarea {
  margin-top: 8px;
}
.textarea {
	height: 97px;
	width: 100%;
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	padding: 5px 8px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-800);
	outline: none;
	resize: vertical;
}

.textarea::-webkit-input-placeholder {
	color: var(--color-grey-300);
}

.textarea:-ms-input-placeholder {
	color: var(--color-grey-300);
}

.textarea::-ms-input-placeholder {
	color: var(--color-grey-300);
}

.textarea::placeholder {
	color: var(--color-grey-300);
}

.video-preview .button.button--icon {
	width: 100%;
	position: absolute;
	height: 100%;
}

.video-preview .button.button--icon svg {
	margin: auto;
}

.video-preview__button-icon {
	color: rgba(0, 0, 0, 0.3);
}

.video-preview__button-icon:hover,
.video-preview__button-icon:focus {
	color: rgba(0, 0, 0, 0.5);
}

.video-preview__button-icon--disabled {
	left: 0;
}

.video-preview__button-icon--disabled:hover,
.video-preview__button-icon--disabled:focus {
	color: rgba(0, 0, 0, 0.3);
}
.edit-area-tools-section .dropdown__label-row {
	width: 100%;
}

.edit-area-tools-section .dropdown__icon {
	position: absolute;
	right: 5px;
}

.edit-area-tools-section .dropdown {
	margin-top: 5px;
	display: block;
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-800);
	padding: 0 5px;
	position: relative;
	background: white;
}

.edit-area-tools-section .dropdown__label {
	font-size: 16px;
	color: var(--color-grey-800);
	font-weight: var(--font-weight-regular);
}

.edit-area-tools-section__readonly-info {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-top: 24px;
	margin-bottom: 24px;
}

.edit-area-tools-section__product-media {
	margin-top: 5px;
	height: 100px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	color: var(--color-grey-300);
}

.edit-area-tools-section__product-media img,
.edit-area-tools-section__product-media video {
	max-height: 100px;
	max-width: 100%;
	width: auto;
	height: auto;
}

.edit-area-tools-section__product-media-upload {
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	margin-top: 26px;
	text-transform: uppercase;
}

.edit-area-tools-section__readonly-info-label {
	color: var(--color-grey-400);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-left: 12px;
}

.edit-area-tools-section__readonly-field {
	display: none;
}

.edit-area-tools-section .searchable-select__option--is-focused {
	background: var(--color-grey-100);
}

.edit-area-tools-section .searchable-select__option--is-selected {
	background: var(--color-grey-200);
}
.area-detail-edit__stat-field:first-of-type {
	width: 100%;
	margin: 0 0 8px 0;
}

.area-detail-edit__stat {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	margin-bottom: -4px;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.area-detail-edit__stat + .area-detail-edit__stat {
	border-top: 1px solid var(--color-grey-200);
	padding-top: 16px;
}

.area-detail-edit__no-stats {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	letter-spacing: 0.3px;
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
	margin-bottom: 23px;
}

.area-detail__fieldset {
	display: -webkit-flex;
	display: flex;
	padding: 0;
	outline: none;
	border: none;
	margin: 0 0 24px 0;
}

.area-detail__products-section-title {
	text-transform: uppercase;
}
.editor-page-saving__contianer {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}

.editor-page-saving__label {
	color: var(--color-grey-400);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	margin-left: 12px;
	margin-right: 51px;
}

.html-view {
  white-space: pre-wrap;
	word-wrap: break-word;
}

.html-view a {
  text-decoration: underline;
}
.latest-update__section {
	margin-top: 48px;
	padding: 32px 16px 18px 16px;
	background-color: var(--color-grey-section-background);
}

.latest-update__section-content {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.latest-update__section-details {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	margin-bottom: 24px;
}

.latest-update__section-details > * {
	-webkit-flex: 1 1 160px;
	        flex: 1 1 160px;
}

.latest-update__section-detail {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-top: 10px;
	max-width: 200px;
}
.latest-update__section-detail:first-child {
	max-width: 100%;
	padding-right: 16px;
	white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.latest-update__section-detail-label {
	margin-bottom: 8px;
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	letter-spacing: 0.8px;
	color: var(--color-grey-500);
	text-transform: uppercase;
}

.latest-update__section-detail-value {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	color: var(--color-grey-800);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.latest-update__section-detail-value::first-letter {
	text-transform: capitalize;
}

.latest-update__section-description {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	color: var(--color-grey-800);
	white-space: pre-wrap;
	word-wrap: break-word;
}

.latest-update__section-description--none {
	color: var(--color-grey-300);
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.latest-update__section {
		padding: 32px 40px 88px 40px;
		margin-top: 80px;
	}

	.latest-update__section-description {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		letter-spacing: 0.3px;
	}

	.latest-update__section-detail-value {
		font-size: var(--font-size-l);
		line-height: var(--line-height-l);
		font-weight: var(--font-weight-medium);
	}

	.latest-update__section-details {
		margin-bottom: 32px;
	}
}


.editor-add-button {
	position: relative;
	width: 100%;
	height: 48px;
	padding: 8px;
	border: 1px solid transparent;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
  background-color: var(--color-brand-light);
  color: var(--color-brand);
	cursor: pointer;
  outline: none;
  transition: all .3s ease-out;
}

.editor-add-button:hover, .editor-add-button--selected {
  border: 1px solid var(--color-brand-dark);
	color: var(--color-brand-dark);
}

.area-tools {
	padding-top: 60px;
}

.area-tools__container--dragging {
	padding-bottom: 148px;
	margin-bottom: 15px;
}

.area-tools__product-drag-handle {
	color: var(--color-grey-300);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.area-tools__product-drag-handle--disabled {
	color: var(--color-grey-200);
	pointer-events: none;
}

.area-tools__product-media {
	height: 100px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	color: var(--color-grey-300);
}

.area-tools__product-media.area-tools__product-media--default {
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.area-tools__product-media img,
.area-tools__product-media video {
	max-height: 100px;
	max-width: 100%;
	width: auto;
	height: auto;
}

.area-tools__row {
	display: -webkit-flex;
	display: flex;
	background-color: var(--color-grey-100);
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	margin-top: 16px;
	padding: 24px 40px 15px 24px;
	max-height: 148px;
	min-height: 148px;
	position: relative;
	overflow: hidden;
}

.area-tools__column {
	width: 25%;
	position: relative;
	margin: 8px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.area-tools__product-title {
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	margin-top: 7px;
}

.area-tools__product-info {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-top: auto;
}

.area-tools__product-description {
	padding: 10px 8px;
	background: white;
	border: 1px solid var(--color-grey-300);
	color: var(--color-grey-800);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-regular);
	letter-spacing: 0.8px;
	min-height: 100px;
	min-width: 200px;
	max-height: 100px;
	max-width: 200px;
}

.area-tools__new-product {
	height: 80px;
	margin-top: 24px;
	padding-top: 28px;
	border: 1px solid silver;
	color: var(--color-grey-500);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	text-align: center;
	display: none;
}

.area-tools__product-button--removed {
	overflow: hidden;
}

.area-tools__product-button--removed .area-tools__row {
	transition: all 0.2s ease-out;
	margin-bottom: -164px;
	opacity: 0;
	border: 2px solid transparent;
}

.area-tools__add-button.active button {
	border: 1px solid transparent;
	background-color: var(--color-grey-100);
	color: var(--color-grey-300);
	cursor: default;
}

.area-tools__add-button.active ~ .area-tools__new-product {
	border: 2px solid var(--color-brand);
	display: block;
}

.area-tools__product-button > div {
	border: 2px solid transparent;
}

.area-tools__product-button.active > div {
	border: 2px solid var(--color-brand);
	background-color: var(--color-grey-100);
}

.area-tools__close-button {
	position: absolute;
	top: 8px;
	right: 0;
}

.area-tools__close-button:hover {
	color: var(--color-danger);
	cursor: pointer;
}

.product-person {
	display: -webkit-inline-flex;
	display: inline-flex;
	padding: 0;
	height: 160px;
	width: 100%;
	background-color: var(--color-grey-700);
	color: white;
}

.product-person__info {
	width: 100%;
	position: relative;
	padding: 12px 16px 16px 24px;
}

.product-person__image ~ .product-person__info {
	margin-left: 0;
}

.product-person__image {
	color: var(--color-grey-400);
	height: 160px;
	width: 160px;
	min-width: 160px;
	text-align: center;
	display: inline-block;
	background: transparent;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.product-person__image img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.product-person__name {
	margin-top: 8px;
	color: white;
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.39px;
	line-height: var(--line-height-m);
	max-height: 57px;
	min-height: 57px;
}

.product-person__email,
.product-person__phone,
.product-person__title {
	color: white;
	font-size: var(--font-size-xs);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
	display: block;
}

.product-person__title {
	color: var(--color-grey-300);
}

.product-person__phone {
	margin-top: 8px;
}

.product-person__email {
	margin-top: 2px;
	transition-property: color;
	transition-duration: 300ms;
}

.product-person__email a {
	display: inline-block;
	text-decoration: underline;
	max-width: 232px;
	word-break: break-all;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.product-person__email:hover {
	color: var(--color-brand);
}

/*Light theme for content editor*/

.product-person.light {
	min-height: 140px;
  height: 140px;
	outline: 2px solid var(--color-grey-100);
	transition: all 0.3s var(--timing-function-ease-out-expo);
}

.product-person.light:hover,  .product-person.light.selected{
	outline-color: var(--color-brand);
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
}

.light .product-person__name {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	min-height: 48px;
	max-height: 48px;
	max-width: 292px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-top: 0;
}

.light .product-person__image{
	min-width: 140px;
	width: 140px;
	height: 140px;
}

.light .product-person__email, .light .product-person__phone {
	margin-top: 0;
}
.area-experts-grid {
	padding-top: 32px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	grid-auto-rows: 140px;
}

.area-experts-grid .product-person:hover {
	outline: none;
	box-shadow: none;
}
.area-team-section{
  padding-top: 60px;
}
.area-page-editor {
	min-height: 100%;
	padding-top: 64px;
}

.area-page-editor__back-button {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-align-items: center;
	        align-items: center;
}

.area-page-editor__actions {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.area-page-editor__publish-button {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xxs);
	letter-spacing: 0.3px;
}

.load-more-button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 20px 0;
}

.load-more-button__secondary-button {
  color: var(--color-brand);
}
.grid-list__items {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-column-gap: 16px;
	grid-row-gap: 16px;
}

.grid-list__item {
	border-radius: 1px;
	overflow: hidden;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	transition-property: box-shadow;
	transition-duration: 300ms;
}

.grid-list__item:hover {
	box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
	.grid-list__items {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 23px;
		grid-row-gap: 23px;
	}
}

@media (min-width: 960px) {
	.grid-list__items {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 32px;
		grid-row-gap: 32px;
	}
}

@media (min-width: 1167px) {
	.grid-list__items {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (min-width: 1360px) {
	.grid-list__items {
		grid-template-columns: repeat(4, 1fr);
	}
}

.multi-level-filter {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.multi-level-filter__radio-group {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: -4px;
}

.multi-level-filter__radio-group + .multi-level-filter__radio-group {
  margin-top: 16px;
}

.multi-level-filter__radio-option {
	position: relative;
	margin: 4px;
	padding: 4px 16px;
	color: var(--color-grey-700);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	font-weight: var(--font-weight-medium);
	border: 1px solid transparent;
	border-radius: 12px;
	transition-property: border-color, color;
	transition-duration: 300ms;
	cursor: pointer;
}

.multi-level-filter__radio-option label {
	cursor: pointer;
}

.multi-level-filter__radio-option input {
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	cursor: pointer;
	width: 100%;
	height: 100%;
}

.level1 .multi-level-filter__radio-option {
	border-color: var(--color-grey-300);
	text-transform: uppercase;
}

.level2 .multi-level-filter__radio-option {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
  border: none;
  padding: 0;
  opacity: 0;
  -webkit-animation: fadeIn 300ms forwards;
          animation: fadeIn 300ms forwards;
}

.level1 .multi-level-filter__radio-option:hover,
.level2 .multi-level-filter__radio-option:hover {
	border-color: var(--color-brand-dark);
	color: var(--color-brand-dark);
}

.level1 .multi-level-filter__radio-option.selected,
.level2 .multi-level-filter__radio-option.selected {
	border-color: var(--color-brand);
	color: var(--color-brand);
}

.product-team-section {
	margin-top: 48px;
	padding: 0 16px;
}

.product-person__email a {
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;
}

.light-theme .page-section-title {
	margin-bottom: 15px;
}

.product-team__grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	margin-top: 32px;
}

.product-team__grid .grid-list__item {
	min-height: 92px;
	max-height: 92px;
}

.product-team__load-more {
	text-align: right;
	margin-top: 10px;
	margin-bottom: 20px;
}

.product-team-explanatory {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-light);
	margin-bottom: 24px;
}

.product-person.light-theme {
	background-color: #f7f7f7;
}

.light-theme__title {
	color: var(--color-grey-500);
}

.light-theme__name,
.light-theme__phone,
.light-theme__email {
	color: var(--color-grey-700);
}

@media (max-width: 767px) {
	.product-person__image {
		height: 92px;
		min-width: 92px;
		width: 92px;
	}

	.product-person {
		height: 92px;
	}

	.product-person__info {
		padding: 8px;
		overflow: hidden;
	}

	.product-person__email,
	.product-person__phone,
	.product-person__title {
		font-size: var(--font-size-xxs);
		line-height: var(--line-height-xxs);
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.product-person__name {
		font-size: var(--font-size-xs);
		line-height: var(--line-height-xs);
		max-height: 25px;
		min-height: auto;
	}

	.grid-list__item {
		overflow: hidden;
	}
}

@media all and (min-width: 768px) {
	.product-team-section {
		margin-top: 80px;
		padding: 0 40px;
	}
	.product-team__grid {
		grid-column-gap: 23px;
		grid-row-gap: 23px;
	}

	.product-team__grid .grid-list__item {
		min-height: 160px;
		max-height: 160px;
	}
}

@media (min-width: 960px) {
	.product-team__grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 1167px) {
	.product-team__grid {
		grid-column-gap: 30px;
		grid-row-gap: 30px;
	}
}

@media (min-width: 1360px) {
	.product-team__grid {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 32px;
		grid-row-gap: 32px;
	}
}

.product {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	border: none;
	outline: none;
	border-radius: 1px;
	background-color: white;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	opacity: 0;
	/* Do not use shorthands for ipad support */
	-webkit-animation-name: fadeInUp;
	        animation-name: fadeInUp;
	-webkit-animation-timing-function: ease;
	        animation-timing-function: ease;
	-webkit-animation-delay: 1s;
	        animation-delay: 1s;
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	cursor: pointer;
}

.product:last-child {
	margin-bottom: 0;
}

.product:hover {
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.product-details {
	padding: 16px 23px 16px 17px;
}

.product-title {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-black);
	letter-spacing: 0.04px;
	color: var(--color-grey-700);
	margin-bottom: 6px;
	text-transform: uppercase;
}

.product-intro-text {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-light);
	letter-spacing: 0.015em;
	color: var(--color-grey-700);
}

.product-image {
	width: 100%;
	height: auto;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	border-top: 1px solid var(--color-grey-200);
	position: relative;
}

.product-image .video-preview__button-icon {
	margin-top: -10px;
}

.product-image img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.product-header {
	height: 50px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-items: center;
	        align-items: center;
	padding-right: 16px;
}

.product-header--none {
	background-color: var(--color-grey-600);
}

.product-header--grey {
	background-color: var(--color-grey-600);
}

.product-header--teal {
	background-color: #4d55b3;
}

.product-header--blue {
	background-color: #4db3a0;
}

.product-header--green {
	background-color: var(--color-brand);
}

.product-when-to-use-label {
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	letter-spacing: 0.8px;
	line-height: var(--line-height-s);
	text-transform: uppercase;
	margin-bottom: 8px;
	margin-top: 16px;
}

.product-details__arrow {
	position: absolute;
	display: none;
	right: 5px;
	top: 25px;
}

.product-header__icon {
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.product-masonry__item {
  display: block;
}

.product-masonry__item + .product-masonry__item{
  margin-top: 32px;
}
.product-list {
	padding: 0px 40px;
	margin-top: 64px;
}

.product-list__masonry-grid {
	display: -webkit-flex;
	display: flex;
	margin-left: -32px; /* gutter size offset */
	width: auto;
	margin-top: 56px;
}

.product-list__masonry-grid-column {
	padding-left: 32px; /* gutter size */
	background-clip: padding-box;
}

@media (max-width: 480px) {
	.product-when-to-use-label,
	.product-when-to-use-label + .product-intro-text {
		display: none;
	}

	.product-list {
		padding: 0 16px;
	}
}

.breadcrumb__list {
	padding: 16px 16px 16px 0px;
	margin-left: -3px;
	list-style: none;
}

.breadcrumb__list-item {
	display: inline;
	font-size: var(--font-size-s);
	font-weight: var(--font-weight-medium);
	color: var(--color-grey-500);
}

.breadcrumb__list-item a {
	letter-spacing: 0.3px;
	line-height: var(--line-height-s);
}

.breadcrumb__list-item + .breadcrumb__list-item:before {
	padding: 8px;
	content: '/\A0';
}

.breadcrumb__list-item:last-of-type,
.breadcrumb__list-item:last-of-type::before {
	color: white;
}

.area-detail {
	margin-top: 64px;
	padding: 0 40px 0;
	color: white;
	position: relative;
	background: black;
	height: 336px;
	background-image: url(/images/area-detail-bg.jpg);
	width: 100%;
	font-weight: var(--font-weight-regular);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.area-detail__row {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.area-detail__left {
	width: 42%;
}

.area-detail__left-cols-8 {
	width: 67%;
}

.area-detail__right {
	width: 58%;
	padding-left: 32px;
	padding-top: 35px;
}

.area-detail__right-cols-4 {
	width: 33%;
}

.area-detail__title {
	margin-top: 24px;
	font-size: var(--font-size-xxl);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	line-height: var(--line-height-xxl);
	text-transform: uppercase;
}

.area-detail__description {
	margin-top: 24px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
}

.area-detail__grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	margin-top: -25px;
}

.area-detail__stat-item {
	min-height: 180px;
	border-left: 1px solid var(--color-grey-600);
	padding-top: 31px;
}

.grid-list__item:first-child .area-detail__stat-item {
	border-left: none;
}

.grid-list__item:last-child .area-detail__stat-item {
	border-bottom: none;
}

.area-detail__term-label {
	width: 100%;
	letter-spacing: 0.8px;
	text-align: center;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-300);
}

.area-detail__term-value {
	color: var(--color-grey-100);
	line-height: var(--line-height-giant-l);
	letter-spacing: 7.13px;
	text-align: center;
	padding-top: 20px;
}

.area-detail__term-value--type-default {
	font-size: var(--font-size-giant-l);
}

.area-detail__term-value--type-5 {
	font-size: var(--font-size-giant-m);
}

.area-detail__term-value--type-6 {
	font-size: 64px;
}

.area-detail__term-value--type-7 {
	font-size: 50px;
}

.area-detail__term-value--type-8 {
	font-size: var(--font-size-giant-xs);
}

.area-detail__term-value--type-9 {
	font-size: var(--font-size-giant-xxs);
}

.area-detail__term-value--type-10 {
	font-size: 36px;
}

.area-detail__term-value--type-11 {
	font-size: 30px;
}

.area-detail__term-value--type-12 {
	font-size: var(--font-size-xl);
}

.area-detail__term-value--type-13 {
	font-size: 25px;
}

.area-detail__term-value--type-14 {
	font-size: 22px;
}

.area-detail__description.area-detail__description--mobile {
	display: none;
}

.area-detail__read-more {
	cursor: pointer;
	text-transform: uppercase;
	text-decoration: underline;
	font-weight: var(--font-weight-bold);
}

.area-button__link {
	margin-top: 8px;
	margin-left: -2px;
}
.area-button__link:hover {
	opacity: 0.8;
}

@media (min-width: 768px) and (max-width: 1440px) {
	.area-detail__term-label {
		font-size: var(--font-size-xs);
	}

	.area-detail__description {
		font-size: var(--font-size-xs);
		letter-spacing: 0.8px;
		line-height: var(--line-height-xs);
		margin-top: 30px;
	}

	.area-detail__title {
		font-size: var(--font-size-l);
	}

	/* Stats font sizes */

	.area-detail__term-value--type-default {
		font-size: 72px;
	}

	.area-detail__term-value--type-5 {
		font-size: var(--font-size-giant-xxs);
	}

	.area-detail__term-value--type-6 {
		font-size: 34px;
	}

	.area-detail__term-value--type-7 {
		font-size: var(--font-size-xl);
	}

	.area-detail__term-value--type-8 {
		font-size: var(--font-size-l);
	}

	.area-detail__term-value--type-9 {
		font-size: var(--font-size-m);
	}

	.area-detail__term-value--type-10 {
		font-size: 19px;
	}

	.area-detail__term-value--type-11 {
		font-size: 17px;
	}

	.area-detail__term-value--type-12 {
		font-size: 15px;
	}

	.area-detail__term-value--type-13 {
		font-size: 13px;
	}

	.area-detail__term-value--type-14 {
		font-size: 12px;
	}
}

@media (max-width: 1167px) {
	.area-detail {
		height: 404px;
	}

	.area-detail__row {
		-webkit-flex-direction: column;
		        flex-direction: column;
		height: 337px;
	}

	.area-detail__right {
		padding-bottom: 16px;
		width: 100%;
	}

	.area-detail__title {
		margin-top: 0;
	}

	.area-detail__description {
		margin-top: 9px;
	}

	.breadcrumb__list {
		padding-bottom: 9px;
	}

	.area-detail__left {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.area-detail__right {
		width: 100%;
		padding: 0;
	}

	.area-detail__title {
		font-size: var(--line-height-xs);
		font-weight: bold;
		line-height: var(--line-height-m);
	}

	.area-detail__description {
		display: none;
	}

	.area-detail__description.area-detail__description--mobile {
		display: block;
		margin-top: 24px;
		font-size: var(--font-size-xs);
	}

	.area-detail__grid {
		grid-template-rows: 1fr 1fr 1fr;
		grid-template-columns: 1fr !important;
	}

	.area-detail__stat-item {
		border-bottom: 1px solid var(--color-grey-600);
		border-left: 0;
	}

	.area-detail__term-value {
		padding-top: 0;
	}

	.area-detail {
		min-height: 556px;
		height: unset;
		padding: 0 16px 0;
	}

	.area-detail__term-label {
		font-size: var(--font-size-xs);
	}

	.area-detail__stat-item {
		min-height: 107px;
		padding-top: 0;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: center;
		        justify-content: center;
	}

	.area-detail__row {
		-webkit-flex-direction: column;
		        flex-direction: column;
		height: 100%;
	}

	.area-detail__right {
		padding: 32px 24px 24px;
	}

	/* Stats font sizes */

	.area-detail__term-value--type-default,
	.area-detail__term-value--type-5,
	.area-detail__term-value--type-6,
	.area-detail__term-value--type-7,
	.area-detail__term-value--type-8,
	.area-detail__term-value--type-9 {
		font-size: var(--font-size-giant-xxs);
	}

	.area-detail__term-value--type-10 {
		font-size: 38px;
	}

	.area-detail__term-value--type-11 {
		font-size: var(--font-size-xxl);
	}

	.area-detail__term-value--type-12 {
		font-size: var(--font-size-xl);
	}

	.area-detail__term-value--type-13 {
		font-size: 26px;
	}

	.area-detail__term-value--type-14 {
		font-size: var(--font-size-l);
	}
}

@media (max-width: 1167px) {
	.decrease-height {
		height: 150px;
		min-height: unset;
	}
}

.area__page {
	font-weight: var(--font-weight-bold);
	color: white;
	font-size: var(--font-size-xxl);
	line-height: var(--line-height-xxl);
	letter-spacing: 0.3px;
	padding: 20px 40px;
	background-color: #000000;
	height: 300px;
	width: 100%;
}

.area-page__footer-space {
	margin-top: 64px;
}

.expert-edit-section {
	position: relative;
}

.expert-edit-section__hint {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-flex: 1 1;
	        flex: 1 1;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
}

.expert-edit-section__link {
	display: block;
	margin-top: 16px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	transition: color 333ms var(--timing-function-ease-out-expo);
	text-decoration: underline;
}

.expert-edit-section__link:hover {
	color: var(--color-brand);
}

.expert-edit-section__file-picker-label {
	transition: color 333ms var(--timing-function-ease-out-expo);
	cursor: pointer;
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
}

.expert-edit-section__file-picker-label:hover {
	color: var(--color-brand-darkest);
}

.expert-edit-section__file-picker-input {
	display: none; /*label has htmlFor to open file window*/
}

.experts-grid {
  padding-top: 32px;
  display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-auto-rows: 140px; 
}

.experts-grid__add-button {
  width: 100%;
  min-height: 140px;
  height: 140px;
  margin: 0;
  outline: 2px solid var(--color-brand-light);
  transition: all 0.3s var(--timing-function-ease-out-expo);
}

.experts-grid__add-button:hover, .experts-grid__add-button--selected{
  outline-color: var(--color-brand);
  border: none;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
}

.experts-grid__add-button--disabled {
  background-color: var(--color-grey-100);
  color: var(--color-grey-300);
  outline: none;
  border: none;
  box-shadow: none;
  pointer-events: none;
}
.experts-section{
  /* padding: 40px; */
}
.experts-search {
	position: relative;
}

.experts-search__controls {
	position: relative;
}

.experts-search__input {
	height: 48px;
	padding: 16px;
}

.experts-search__controls-search,
.experts-search__controls-close {
	position: absolute;
	right: 14px;
	top: 12px;
}

.experts-search__results {
  background-color: white;
	width: 100%;
	padding: 0;
	margin: 0;
	position: absolute;
	max-height: 30vh;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 99999;
	margin-top: 0;
	border-radius: 0;
	transition: opacity 0.1s ease;
	opacity: 0;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.experts-search__results--open {
	opacity: 1;
}

.experts-search__results-header {
  display: -webkit-flex;
  display: flex;
  height: 32px;
  background-color: var(--color-grey-100);
  color: var(--color-grey-500);
  font-size: var(--font-size-xxs);
  line-height: var(--line-height-xxs);
  padding: 8px 16px;
}

.experts-search__results-name {
  width: 38%;
}

.experts-search__results-role {
  width: 38%;
}

.experts-search__results-location {
  width: 24%;
}

.experts-search__results-list {
	padding: 0;
	margin: 0;
}

.experts-search__results-list-item {
  display: -webkit-flex;
  display: flex;
  min-height: 40px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  letter-spacing: .3px;
  color: var(--color-grey-500);
}

.content-editor__body {
  padding-top: 64px;
}
.product-detail {
	margin-top: 64px;
	padding: 0 40px 0;
	color: white;
	position: relative;
	background: black;
	height: auto;
	background-image: url(/images/product-detail-bg.jpg);
	background-position: top;
	background-repeat: no-repeat;
}

.product-detail--with-hero-feature {
	margin-bottom: 32px;
}

.product-detail__title {
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height-m);
	letter-spacing: 0.3px;
	margin-top: 24px;
	text-transform: uppercase;
}

.product-detail__info,
.product-detail__version {
	color: var(--color-grey-300);
	font-size: var(--font-size-s);
	font-weight: 300;
	line-height: var(--line-height-s);
	letter-spacing: 0.8px;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.product-detail__info {
	white-space: unset;
}

.product-detail__version {
	margin-top: 8px;
	font-weight: var(--font-weight-bold);
}

.product-detail__info {
	color: var(--color-grey-200);
	margin: 32px 32px 0 0;
}

.product-detail__hero-image {
	height: 162x;
	width: 100%;
	text-align: center;
	position: relative;
	bottom: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
}

.product-detail__hero-image img {
	display: inline-block;
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
	-webkit-align-self: center;
	        align-self: center;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	position: absolute;
	top: 50%;
	left: 50%;
}

.product-detail__hero-image video {
	outline: none;
}

.product-detail__row {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.product-detail__left {
	width: 100%;
}

.product-detail__right {
	margin: 25px 0;
	min-width: 50%;
}

.product-detail__row-expanded {
	width: 67%;
}

.product-detail__read-more {
	cursor: pointer;
	text-transform: uppercase;
	text-decoration: underline;
	font-weight: var(--font-weight-bold);
	font-size: var(--font-size-xs);
}

.product-detail__product-button-url button {
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.37px;
	padding: 0 32px;
	height: 48px;
	margin-bottom: 32px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.area-detail__read-more {
	cursor: pointer;
	text-transform: uppercase;
	text-decoration: underline;
	font-weight: var(--font-weight-bold);
}

@media (max-width: 900px) {
	.product-detail__right {
		padding-left: 32px;
	}
}

@media (max-width: 767px) {
	.product-detail {
		padding: 0 16px 0;
	}

	.product-detail__product-button-url button {
		height: 48px;
		margin-bottom: 0;
		margin-top: 32px;
		padding: 0 16px;
		width: 100%;
	}

	.product-detail__hero-image {
		height: 190px;
		width: 100%;
	}

	.product-detail__right {
		min-width: 100%;
		padding-left: 0;
	}
}

@media (min-width: 768px) {
	.product-detail__title {
		font-size: var(--font-size-xl);
		font-weight: var(--font-weight-medium);
		line-height: var(--line-height-xl);
	}

	.product-detail__version {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		letter-spacing: 0.8px;
	}

	.product-detail {
		background-size: cover;
	}

	.product-detail__hero-image {
		height: 245px;
		width: 100%;
	}

	.product-detail__product-button-url button {
		margin-top: 32px;
	}

	.product-detail__row {
		-webkit-flex-direction: row;
		        flex-direction: row;
	}
}

@media (min-width: 960px) {
	.product-detail {
		height: auto;
		min-height: auto;
		background-position: center;
	}

	.product-detail__row {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
	}

	.product-detail__left {
		width: 42%;
	}

	.product-detail__right {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: center;
		        align-items: center;
		width: 58%;
		margin: 0;
	}

	.product-detail__hero-image {
		height: 245px;
		width: 100%;
	}

	.product-detail__description {
		min-height: 220px;
	}

	.product-detail__info {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		letter-spacing: 0.8px;
	}
}

@media (min-width: 1167px) {
	.product-detail {
		height: auto;
	}

	.product-detail__description {
		min-height: 200px;
	}

	.product-detail__hero-image {
		height: 297px;
		width: 527px;
	}

	.product-detail__info {
		font-size: var(--font-size-s);
		margin-top: 16px;
	}

	.breadcrumb__list {
		padding-bottom: 0;
	}

	.product-detail__product-button-url button {
		margin-top: 32px;
	}
}

@media (min-width: 1366px) {
	.product-detail__title {
		font-size: var(--font-size-xxl);
		font-weight: var(--font-weight-medium);
		line-height: var(--line-height-xxl);
	}

	.product-detail__info {
		font-size: var(--font-size-s);
	}

	.product-detail__hero-image {
		height: 371px;
		width: 658px;
	}

	.product-detail {
		height: auto;
	}

	.product-detail__description {
		min-height: 200px;
	}
}

@media (min-width: 1440px) {
	.product-detail__hero-image {
		height: 440px;
		width: 100%;
	}
}

.when-to-use {
	margin-top: 35px;
	padding: 0 40px;
}

.when-to-use__text {
	color: var(--color-grey-800);
	font-size: var(--font-size-s);
	font-weight: 300;
	letter-spacing: 0.3px;
	line-height: var(--line-height-s);
	max-width: 67%;
}

@media (max-width: 1100px) {
	.when-to-use__text {
		max-width: 100%;
	}
}

@media (max-width: 960px) {
	.when-to-use .page-section-title {
		margin-bottom: 16px;
	}
}

@media (max-width: 767px) {
	.when-to-use {
		margin-top: 3px; /*Feature section has 45px spacing - So section gap must be 48px*/
		padding: 0 16px;
	}

	.when-to-use .page-section-title {
		font-size: var(--font-size-m);
		font-weight: bold;
		line-height: var(--line-height-m);
	}

	.when-to-use__text {
		font-size: var(--font-size-xs);
		letter-spacing: 0.8px;
		line-height: var(--line-height-xs);
	}
}

.features-carousel {
	padding: 0 40px;
	margin-top: 32px;
	overflow-x: hidden;
}

.features-carousel-lightbox .ap-modal-content {
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;
	max-height: calc(100% - 64px);
	width: calc(100% - 64px);
}

.features-carousel .slider-list {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
}

.features-carousel__body {
	padding: 0 40px;
	position: relative;
}

.features-carousel__body .slider-control-bottomcenter {
	display: none;
}

.features-carousel__body .slider-control-centerright {
	right: -10px !important;
	top: calc(45% - 20px) !important;
}

.features-carousel__body .slider-control-centerleft {
	left: -40px !important;
	top: calc(45% - 20px) !important;
}

.features-carousel__prev-btn,
.features-carousel__next-btn {
	/* button reset*/
	cursor: pointer;
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	background: transparent;
	color: inherit;
	font: inherit;
	line-height: normal;
	outline: none;
	position: absolute;
	top: 10px;
}

.features-carousel__prev-btn svg,
.features-carousel__next-btn svg {
	color: var(--color-grey-400);
}

.features-carousel__item {
	outline: none;
	background-color: var(--color-grey-100);
	padding: 24px 24px 34px;
	margin: 0 18px 25px 18px;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	transition-property: box-shadow;
	cursor: pointer;
	transition-duration: 300ms;
	transition-timing-function: ease-in-out;
}

.features-carousel .slider-slide:hover .features-carousel__item {
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.features-carousel__item--no-action {
	pointer-events: none;
	cursor: not-allowed;
}

.features-carousel__img-wrapper {
	height: 190px;
	width: 100%;
	text-align: center;
	background: var(--color-grey-100);
	position: relative;
	outline: none;
	color: var(--color-grey-300);
}

.features-carousel__img-wrapper img {
	display: inline-block;
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
	outline: none;
}

.features-carousel__img-title {
	-webkit-line-clamp: 2;
	/*! autoprefixer: off */
	-webkit-box-orient: vertical;
	color: var(--color-grey-700);
	display: -webkit-box;
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-black);
	justify-content: center;
	letter-spacing: 0.3px;
	line-height: var(--line-height-m);
	margin-top: 32px;
	/* max-height: 62px;
	min-height: 62px; */
	overflow: hidden;
	text-transform: uppercase;
}

.features-carousel__img-text {
	-webkit-line-clamp: 3;
	/*! autoprefixer: off */
	-webkit-box-orient: vertical;
	color: var(--color-grey-700);
	display: -webkit-box;
	font-size: var(--font-size-s);
	font-weight: 300;
	height: calc(
		16px * 1.4 * 3 + 16px
	); /* $font-size*$line-height*$lines-to-show + padding-top;  */
	letter-spacing: 0.8px;
	line-height: 1.4;
	overflow: hidden;
	padding-top: 16px;
}

.features-carousel-lightbox h4 {
	color: var(--color-grey-800);
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-bold);
}

.feature-media-container .feature-media-name {
	display: none;
}

@media (max-width: 767px) {
	.features-carousel {
		padding: 0 0 0 16px;
	}

	.features-carousel__body {
		padding: 0;
	}

	.features-carousel__item {
		margin: 0 8px 25px 8px;
		padding: 16px;
	}

	.features-carousel__item.full-size {
		margin: 0 24px 25px 8px;
	}

	.features-carousel__img-title {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		margin-top: 16px;
		margin-bottom: 8px;
		min-height: 48px;
		text-transform: uppercase;
	}

	.features-carousel__img-text {
		font-size: var(--font-size-xxs);
		line-height: var(--line-height-xxs);
		padding: 0;
	}

	.features-carousel-lightbox .ap-modal-content {
		max-width: -webkit-fit-content;
		max-width: -moz-fit-content;
		max-width: fit-content;
		max-height: 100%;
		width: 100%;
	}

	.features-carousel-lightbox .ap-modal-header {
		padding: 8px;
	}

	.features-carousel-lightbox h4 {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
	}

	.features-carousel__body .slider-control-centerleft,
	.features-carousel__body .slider-control-centerright {
		bottom: 10px !important;
		height: 50px !important;
		left: calc(100% - 40px) !important;
		position: relative !important;
		top: auto !important;
		width: 50px !important;
	}

	.features-carousel__body .slider-control-centerleft {
		left: 5px !important;
		float: left;
		right: auto !important;
	}

	.features-carousel__next-btn,
	.features-carousel__prev-btn {
		-webkit-align-items: center;
		        align-items: center;
		color: var(--color-grey-700);
		display: -webkit-flex;
		display: flex;
		font-size: 13px;
		font-weight: 500;
		height: 40px;
		letter-spacing: 0.3px;
		line-height: 13px;
	}

	.features-carousel__body .slider-control-bottomcenter {
		bottom: 31px !important;
		display: block;
	}

	.features-carousel__body .slider-control-bottomcenter ul {
		white-space: nowrap;
	}

	.features-carousel .paging-item {
		max-width: 26px;
		max-height: 26px;
		width: 26px;
		height: 26px;
		overflow: hidden;
	}

	.features-carousel .paging-item button {
		height: 25px !important;
		width: 25px !important;
		max-height: 25px !important;
		max-width: 25px !important;
	}

	.features-carousel .paging-item span.paging-dot {
		box-sizing: border-box !important;
		height: 9px !important;
		width: 9px !important;
		border: 1px solid var(--color-grey-300) !important;
		background: none !important;
	}

	.features-carousel .paging-item.active span.paging-dot {
		background: var(--color-grey-300) !important;
	}
}

.features-carousel-lightbox .ap-modal-body {
	overflow: hidden;
	padding: 0;
}

.features-carousel-lightbox__navigation {
	position: absolute;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.feature-navigation {
	cursor: pointer;
}

.feature-media-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	height: 100%;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
}

.feature-media-container .features-media-carousel {
	position: relative;
	height: 100%;
}

.feature-media-container img,
.feature-media-container video {
	max-height: 100%;
	max-width: 100%;
	object-fit: contain;
	border-radius: 1px;
}

.feature-media-description {
	width: 100%;
	padding: 16px 32px;
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
	border-top: 1px solid var(--color-grey-300);
}

.features-media-carousel {
	display: -webkit-flex;
	display: flex;
}

.feature-media-container > div {
	display: -webkit-flex;
	display: flex;
}

.feature-modal-container .slider-slide {
	height: 80vh !important;
}

.feature-modal-container .ap-modal-content {
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;
	max-height: calc(100% - 64px);
	width: calc(100% - 64px);
}

.feature-modal-container .ap-modal-content {
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;
	max-height: 100%;
	width: 100%;
}

.feature-modal-container .ap-modal-body {
	overflow: hidden;
	padding: 0;
}

@media (max-width: 767px) {
	.feature-media-container {
		-webkit-justify-content: unset;
		        justify-content: unset;
		overflow-y: scroll;
	}

	.feature-media-name {
		display: -webkit-flex;
		display: flex;
		color: var(--color-grey-700);
		font-size: var(--font-size-s);
		font-weight: bold;
		letter-spacing: 0.3px;
		line-height: var(--line-height-s);
		padding: 0 16px 16px;
		-webkit-align-self: flex-start;
		        align-self: flex-start;
	}

	.feature-modal-container .ap-modal-wrapper {
		width: 100%;
		height: 100%;
		margin: 0;
		-webkit-align-items: unset;
		        align-items: unset;
	}

	.feature-modal-container .slider {
		height: 100% !important;
	}

	.feature-modal-container .ap-modal-content {
		max-width: unset;
		background-color: var(--color-grey-100);
	}

	.feature-modal-container .ap-modal-body {
		height: 100%;
	}

	.feature-modal-container .ap-modal-header {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row-reverse;
		        flex-direction: row-reverse;
		border-bottom: 0;
	}

	.feature-modal-container .ap-modal-header h4 {
		display: none;
	}

	.feature-media-description {
		border: 0;
	}

	.features-media-carousel {
		display: block;
		padding: 0 16px;
	}

	.feature-media-container > div {
		display: block;
		height: unset !important;
		padding: 0 16px 16px;
	}

	.feature-media-container .feature-media-name {
		display: block;
	}

	.feature-media-container video {
		height: unset !important;
	}

	.feature-media-description {
		padding: 8px 16px 0;
	}

	.feature-modal-container .paging-item.active span.paging-dot {
		background: var(--color-grey-300) !important;
	}

	.feature-modal-container .paging-item span.paging-dot {
		box-sizing: border-box !important;
		height: 9px !important;
		width: 9px !important;
		border: 1px solid var(--color-grey-300) !important;
		background: none !important;
	}
}

.file-item {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: stretch;
	        align-items: stretch;
	min-height: 168px;
	max-height: 168px;
	width: 100%;
	padding: 16px 24px 16px 24px;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	position: relative;
}

.file-item__save-icon svg {
	vertical-align: middle;
}

.file-item__save-icon {
	display: block;
	position: absolute;
	color: var(--color-grey-600);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.2) 0%,
		rgba(255, 255, 255, 1) 100%
	);
	width: 100%;
	right: 0;
	bottom: -120px;
	transition: bottom 300ms;
	left: 0;
	text-align: center;
	height: 120px;
	line-height: 150px;
}

@media(hover: hover){
	.file-item:hover .file-item__save-icon {
		bottom: 0;
	}
}

.file-item--disabled {
	pointer-events: none;
}

.file-item__icon {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	color: var(--color-grey-400); /* TODO: Use new color palette */
}

.file-item__icon svg {
	width: 32px;
	height: 32px;
}

.file-item__details {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-flex: 1 1;
	        flex: 1 1;
	margin-left: 16px;
	-webkit-justify-content: center;
	        justify-content: center;
	width: calc(100% - 57px);
}

.file-item__description--blank,
.file-item__description {
	padding-top: 8px;
	border-top: 1px solid var(--color-grey-200);
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	letter-spacing: 0.8px;
	margin: 12px 15px 0 0;
	min-width: 100%;
	height: 84px;
	overflow: hidden;
}

.file-item__description--blank {
	line-height: 84px;
	text-align: center;
	color: var(--color-grey-300);
	text-transform: uppercase;
}

.file-item__header {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.file-item__name {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-regular);
	-webkit-flex-basis: 100%;
	        flex-basis: 100%;
	color: var(--color-grey-800);
	letter-spacing: 0.8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.file-item__version {
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	font-weight: var(--font-weight-regular);
	letter-spacing: 0.8px;
	color: var(--color-grey-500);
	-webkit-flex-basis: 100%;
	        flex-basis: 100%;
}

.file-item__error {
	color: var(--color-danger);
}

@media (min-width: 768px) {
	.file-item__icon svg {
		width: 40px;
		height: 40px;
	}

	.file-item__name {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		letter-spacing: 0.3px;
	}

	.file-item__version {
		font-size: var(--font-size-xs);
		line-height: var(--line-height-xs);
	}

	.file-item__description--blank,
	.file-item__description {
		font-size: var(--font-size-xs);
		line-height: var(--line-height-xs);
	}
}

.attachments__section {
	opacity: 0;
  padding: 0 16px;
  margin-top: 48px;
	-webkit-animation: fadeIn 1000ms var(--timing-function-ease-out-expo) 1 forwards;
	        animation: fadeIn 1000ms var(--timing-function-ease-out-expo) 1 forwards;
}


@media (min-width: 768px) { 
  .attachments__section {
    padding: 0 40px;
    margin-top: 80px;
  }
}
.product-page__space-separator {
	margin-top: 64px;
}

.product-detail__video {
	width: inherit;
	height: inherit;
}

.product-detail__video .video-preview__default-image {
  max-width: 500px;
  box-shadow: none;
}

.product-detail__video.video-preview .button.button--icon {
	left: 0;
}
.product-detail-section {
	position: relative;
	background-color: var(--color-grey-700);
	border: 4px solid var(--color-grey-700);
	min-height: 240px;
	max-height: 240px;
	padding: 30px 32px 32px 40px;
	cursor: pointer;
	transition: border-color;
	transition-duration: 300ms;
	width: calc(100% + 72px);
	margin-left: -40px;
	margin-top: -40px;
}

.product-detail-section--selected {
	border-color: var(--color-brand);
}

.product-detail-section__row {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: flex-end;
	        align-items: flex-end;
}

.product-detail-section__left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	width: 45%;
	max-width: 45%;
}

.product-detail-section__right{
	height: 100%;
}

.product-detail-section__no-name-block,
.product-detail-section__no-description-block {
	height: 64px;
	width: 316px;
	border: 1px solid var(--color-grey-600);
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-500);
}

.product-detail-section__no-name-block {
	height: 40px;
	width: 250px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	margin-bottom: 39px;
	transition-property: border;
	transition-duration: 300ms;
}

.product-detail-section__name {
	margin-top: 18px;
	color: white;
	font-size: var(--font-size-xxl);
	line-height: var(--line-height-xxl);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	transition-property: border;
	transition-duration: 300ms;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 320px;
	overflow: hidden;
}

.product-detail-section__description {
	color: white;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	min-height: 64px;
	max-height: 64px;
	width: 100%;
	max-width: 100%;
	outline: 1px solid var(--color-grey-600);
	padding: 12px;
	transition-property: outline;
	transition-duration: 300ms;
}

.product-detail-section__description--selected,
.product-detail-section__no-name-block.selected {
	outline: 2px solid var(--color-brand);
}

.product-detail-section__version {
	margin-top: 8px;
	margin-bottom: 23px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-500);
	letter-spacing: 0.3px;
}

.product-detail-section__version-value {
	color: var(--color-grey-300);
}

.product-detail-section__hero-block svg {
	width: 40px;
}

.product-detail-section__hero-block {
	height: 170px;
	width: 316px;
	border: 1px solid var(--color-grey-600);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-500);
	letter-spacing: 0.3px;
	position: relative;
}

.product-detail-section__hero-block .video-preview {
	position: relative;
}

.product-detail-section__hero-block--no-border {
	border: 0px;
}

.product-detail-section__hero-block img,
.product-detail-section__hero-block video {
	max-height: 100%;
	max-width: 100%;
	width: auto;
	height: auto;
}

.SingleDatePicker {
	display: block;
	margin-top: 8px;
}

.SingleDatePicker .SingleDatePickerInput {
	width: 100%;
	display: -webkit-flex;
	display: flex;
}

.SingleDatePickerInput .DateInput {
	-webkit-flex-grow: 1;
	        flex-grow: 1;
}

.SingleDatePickerInput_calendarIcon {
	margin-left: 8.5px;
	margin-top: 2px;
}

.SingleDatePicker .DateInput .DateInput_input {
	padding: 4.5px 8.5px 4.5px 3px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	width: 100%;
}

.product-detail__fieldset-info {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-bottom: 19px;
}

.product-detail__fieldset {
	display: -webkit-flex;
	display: flex;
	padding: 0;
	outline: none;
	border: none;
	margin: 0 0 24px 0;
}
.product-features {
	padding-top: 60px;
}

.product-features__container--dragging {
	padding-bottom: 148px;
	margin-bottom: 15px;
}

.product-features__drag-handle {
	color: var(--color-grey-300);
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	margin-right: 15px;
}

.product-features__drag-handle--disabled {
	color: var(--color-grey-200);
	pointer-events: none;
}

.product-features__media svg {
	width: 30px;
}

.product-features__media {
	height: 100px;
	width: 200px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	color: var(--color-grey-300);
}

.product-features__media.product-features__media--default {
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.product-features__media img,
.product-features__media video {
	max-height: 100px;
	max-width: 100%;
	width: auto;
	height: auto;
}

.product-features__row {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	background-color: var(--color-grey-100);
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	margin-top: 16px;
	padding: 24px 40px 15px 24px;
	max-height: 148px;
	min-height: 148px;
	position: relative;
	overflow: hidden;
}

.product-features__media-column {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-left: auto; 
}

.product-features__title {
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	margin-top: 7px;
}

.product-features__info {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-top: auto;
}

.product-features__name {
	min-height: 24px;
	max-height: 96px;
	min-width: 9vw;
	max-width: 9vw;
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
}

.product-features__description {
	padding: 10px 8px;
	background: white;
	border: 1px solid var(--color-grey-300);
	color: var(--color-grey-800);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-regular);
	letter-spacing: 0.8px;
	min-height: 100px;
	min-width: 25vw;
	max-height: 100px;
	max-width: 25vw;
	margin: 0 23px;
}

.product-features__new-feature {
	height: 80px;
	margin-top: 24px;
	padding-top: 28px;
	border: 1px solid silver;
	color: var(--color-grey-500);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	text-align: center;
	display: none;
}

.product-features__button--removed {
	overflow: hidden;
}

.product-features__button--removed .product-features__row {
	transition: all 0.2s ease-out;
	margin-bottom: -164px;
	opacity: 0;
	border: 2px solid transparent;
}

.product-features__add-button.active button {
	border: 1px solid transparent;
	background-color: var(--color-grey-100);
	color: var(--color-grey-300);
	cursor: default;
}

.product-features__add-button.active ~ .product-features__new-feature {
	border: 2px solid var(--color-brand);
	display: block;
}

.product-features__button > div {
	border: 2px solid transparent;
}

.product-features__button.active > div {
	border: 2px solid var(--color-brand);
	background-color: var(--color-grey-100);
}

.product-features__close-button {
	position: absolute;
	top: 8px;
	right: 0;
}

.product-features__close-button:hover {
	color: var(--color-danger);
	cursor: pointer;
}

.clear-text-button {
	border: none;
	padding: 0;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xxs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	color: var(--color-danger);
	transition: all 333ms var(--timing-function-ease-out-expo);
	text-transform: uppercase;
	cursor: pointer;
}

.clear-text-button:hover {
	color: var(--color-danger-dark);
}

.file-picker {
  margin-top: 26px;
}

.file-picker__label {
  transition: color 333ms var(--timing-function-ease-out-expo);
	cursor: pointer;
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
}

.file-picker__label:hover {
	color: var(--color-brand-darkest);
}

.file-picker__input {
  display: none; /*label has htmlFor to open file window*/
}

.edit-product-features-upload {
	height: 154px;
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	background-color: var(--color-grey-100);
	padding: 32px;
	color: var(--color-grey-400);
	text-align: center;
}

.edit-product-features-upload:focus {
	outline: none;
}

.edit-product-features-upload__label {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	text-align: center;
}

.edit-product-features-upload svg {
	margin: 0 0 8px;
}

.edit-product-features-upload__file-text {
	color: var(--color-grey-700);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	text-align: center;
}

.edit-product-features-upload__upload-text {
	color: var(--color-brand);
	text-transform: uppercase;
	margin: 20px 0 30px;
	display: block;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	cursor: pointer;
}

.edit-product-features-upload__label-container {
	margin-bottom: 8px;
	display: -webkit-flex;
	display: flex;
}

.edit-product-features-upload__label-max-size {
	color: var(--color-grey-700);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	margin-left: auto;
}

.edit-product-features-upload__label-text {
	height: 13px;
	width: 151px;
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	text-transform: uppercase;
	letter-spacing: 0.8px;
}

.edit-product-features-upload__loader div {
	margin: 10px auto 8px;
}

.edit-product-features-upload__video-dialog {
	width: auto;
	height: auto;
	padding: 40px 0px 0px 0px;
	position: relative;
}

.edit-product-features-upload__video-dialog .button {
	position: absolute;
	right: 8px;
  top: 8px;
  color: var(--color-grey-500);
  background: white;
}

.edit-product-features-upload__video-dialog .button:hover {
  color: var(--color-grey-800);
}

.edit-product-features-upload__action-links {
  display: -webkit-flex;
  display: flex;
}

.edit-product-features-upload__action-links > * {
  margin: 20px auto 30px;
}

.edit-product-features-upload__action-links .button {
  color: var(--color-grey-700);
}
.edit-products-feature-section__hero-fieldset {
	display: -webkit-flex;
	display: flex;
	padding: 0;
	outline: none;
	border: none;
	margin: 0 0 24px 0;
}

.edit-products-feature-section__cover-image {
	display: -webkit-flex;
	display: flex;
}

.edit-products-feature-section__cover-image-preview img {
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.edit-products-feature-section__cover-image-label {
	margin-bottom: 8px;
	display: inline-block;
}

.edit-products-feature-section__cover-image-preview {
	height: 140px;
	width: 50%;
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	background-color: var(--color-grey-700);
	position: relative;
}

.edit-products-feature-section__cover-image-picker {
	width: 50%;
	padding: 32px 32px 32px 32px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
}

.edit-products-feature-section__cover-image-picker .file-picker {
	margin-top: 0;
}

.edit-products-feature-section__cover-image-default-text {
	width: 50%;
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	text-align: center;
	margin-top: 8px;
}

.edit-products-feature-section__button-use-default {
	color: var(--color-grey-700);
	font-size: var(--font-size-xs);
	letter-spacing: 0.3px;
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
	margin-top: 19px;
}

.product-team__section {
	padding-top: 60px;
}

.product-when-to-use-section {
  padding-top: 52px;
}

.product-when-to-use__container {
  padding: 12px 8px;
  outline: 1px solid var(--color-grey-300);
  color: var(--color-grey-800);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	transition-property: outline;
  transition-duration: 300ms;
  min-height: 80px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
}

.product-when-to-use__container.selected {
	outline: 2px solid var(--color-brand);
}

.product-when-to-use__container-no-text {
  color: var(--color-grey-500);	
  font-size: var(--font-size-s);	
  letter-spacing: 0.3px;	
  line-height: var(--line-height-s);	
  display: -webkit-flex;	
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.product-attachments__section {
	padding-top: 60px;
}

.product-attachments__grid {
	margin-top: 25px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 24px;
	grid-row-gap: 24px;
}

.product-attachments__grid-item {
	/* padding: 16px; */
	border-radius: 1px;
	background-color: white;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	border: 2px solid white;
	transition: border-color 300ms ease-in-out;
}

.product-attachments__grid-item:hover,
.product-attachments__grid-item--active {
	border-color: var(--color-brand);
}

.product-attachments__add-button.active button {
	border: 1px solid transparent;
	background-color: var(--color-grey-100);
	color: var(--color-grey-300);
	cursor: default;
}


.product-attachements__new {
  transition: all 300ms ease-in-out;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	height: 100%;
	border: 2px solid var(--color-brand);
	background-color: var(--color-grey-100);
}

.product-page-editor {
	min-height: 100%;
	padding-top: 64px;
}

.product-page-editor__back-button {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-align-items: center;
	        align-items: center;
}

.product-page-editor__actions {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.product-page-editor__publish-button {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xxs);
	letter-spacing: 0.3px;
}


.edit-products-attachment__section{
  position: relative;
}

.edit-products-attachment__file-row {
	margin-top: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.edit-products-attachment__file-link{
  display: block;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	transition: color 333ms var(--timing-function-ease-out-expo);
	text-decoration: underline;
}

.edit-products-attachment__file-link:hover {
	color: var(--color-brand);
}

.edit-products-attachment__file-picker-label {
	transition: color 333ms var(--timing-function-ease-out-expo);
	cursor: pointer;
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
}

.edit-products-attachment__file-picker-label:hover {
	color: var(--color-brand-darkest);
}

.edit-products-attachment__file-picker-input {
	display: none; /*label has htmlFor to open file window*/
}
.filter-checkbox {
	display: inline-block;
	position: relative;
	vertical-align: middle;
}
.filter-checkbox .checkbox-label,
.filter-checkbox .checkbox-title {
	display: inline-block;
	position: relative;
	vertical-align: middle;
}
.filter-checkbox .checkbox-title {
	cursor: pointer;
	padding-right: 5px;
}

.filter-checkbox .checkbox-title.disabled {
	color: var(--color-grey-300);
	cursor: not-allowed;
}

.checkbox-button {
	display: -webkit-flex;
	display: flex;
	position: relative;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	background-color: var(--color-grey-100);
	border: 2px solid var(--color-grey-300);
	cursor: pointer;
	transition: border 0.1s ease-out, background 0.1s ease-out;
}
.checkbox-button:hover {
	border-color: var(--color-brand-dark);
}
.checkbox-button:hover {
	border-color: var(--color-brand-dark);
}
.checkbox-button.disabled {
	cursor: not-allowed;
	opacity: 0.4;
}
.checkbox-icon {
	fill: none;
	stroke: white;
	opacity: 0;
}

.checkbox-native-input {
	position: absolute;
	opacity: 0;
	left: 0;
	top: 0;
}

.checkbox-native-input:checked + .checkbox-button {
	background-color: var(--color-brand);
	border-color: var(--color-brand);
}

.checkbox-native-input:checked + .checkbox-button:hover {
	border-color: var(--color-brand-dark);
	background-color: var(--color-brand-dark);
}

.checkbox-native-input:checked + .checkbox-button:hover {
	border-color: var(--color-brand-dark);
	background-color: var(--color-brand-dark);
}

.checkbox-native-input:checked + .checkbox-button .checkbox-icon {
	opacity: 1;
}

.checkbox-native-input:checked + .checkbox-button.disabled {
	background-color: var(--color-grey-300);
	border-color: var(--color-grey-300);
	cursor: not-allowed;
}

.filter-tablet {
	display: -webkit-flex;
	display: flex;
	background-color: var(--color-grey-400);
	border-radius: 16px;
	padding: 4px 12px;
	margin-right: 16px;
	margin-bottom: 8px;
}

.filter-tablet__remove-icon:hover svg {
	fill: white;
}

.filter-tablet .filter-tablet__title {
	color: white;
	font-size: var(--font-size-s);
	letter-spacing: 0.3px;
	line-height: var(--line-height-s);
}

.filter-tablet__remove-icon {
	margin-left: 8px;
	cursor: pointer;
	height: 24px;
	-webkit-align-self: center;
	        align-self: center;
}

.filter-collapsible {
	border-top: 1px solid var(--color-grey-300);
}

.filter-collapsible:last-child {
	border-bottom: 1px solid var(--color-grey-300);
}

.filter-collapsible__body {
	padding: 0 12px 32px;
}

.filter-collapsible__header {
	display: -webkit-flex;
	display: flex;
	height: 60px;
	padding: 0 12px;
	cursor: pointer;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
}

.filter-collapsible__title {
	color: var(--color-grey-600);
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-medium);
}

.filter-collapsible__icon {
	color: var(--color-grey-600);
}

.sidebar {
	background-color: white;
	bottom: 0;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
	height: 100%;
	left: 0;
	position: fixed;
	right: auto;
	top: 64px;
	width: 356px;
	z-index: 3;
}

.sidebar--closed {
	margin-right: -1000px;
	display: none;
}

.sidebar--opened {
	margin-right: 0;
	display: -webkit-flex;
	display: flex;
}

.sidebar__close-button {
	position: absolute;
	top: 16px;
	right: 20px;
}

.sidebar__close-button:hover {
	color: var(--color-danger);
	cursor: pointer;
}

.sidebar__search-container {
	overflow: hidden;
	padding-top: 66px;
	padding-left: 24px;
	width: 100%;
}

.sidebar__overlay {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: grey;
	z-index: 2;
	overflow: hidden;
	opacity: 0.5;
}

.search-page {
	background-color: var(--color-grey-100);
}

.search-container {
	display: -webkit-flex;
	display: flex;
	min-height: calc(100vh - 200px); /*64 px is the height of the nav bar*/
}

.search-container__search-block {
	width: 284px;
	position: relative;
}

.search-container__left {
	-webkit-flex: 1 0 356px;
	        flex: 1 0 356px;
	background-color: white;
	border-right: 1px solid var(--color-grey-300);
	padding: 66px 32px 40px 40px;
}

.search-container__filter-input {
	margin-bottom: 40px;
	height: 33px;
}

.search-container__filter-input input {
	padding: 4.5px 8.5px;
}

.search-container__filter-input .search-input__close-button {
	top: 5px;
}

.search-container__right {
	padding: 66px 40px 40px 30px;
	-webkit-flex-grow: 1;
	        flex-grow: 1;
	-webkit-flex-shrink: 0;
	        flex-shrink: 0;
	-webkit-flex-basis: calc(100vw - 356px);
	        flex-basis: calc(100vw - 356px);
	background-color: var(--color-grey-100);
	overflow-x: hidden;
	overflow-y: auto;
}

.search-container__title {
	font-size: var(--font-size-l);
	line-height: var(--line-height-l);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.04px;
	color: var(--color-grey-700);
	margin-bottom: 24px;
}

.search-container__masonry-grid {
	display: -webkit-flex;
	display: flex;
	margin-left: -32px; /* gutter size offset */
	width: auto;
	margin-top: 56px;
	max-width: 1244px;
}

.search-container__masonry-grid-column {
	padding-left: 32px; /* gutter size */
	background-clip: padding-box;
}

.area-filters {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.current-search {
	display: -webkit-flex;
	display: flex;
	margin-left: 24px;
}

.current-search__message {
	color: var(--color-grey-600);
	font-size: var(--font-size-s);
	letter-spacing: 0.03px;
	line-height: var(--line-height-s);
	max-width: 60vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.current-search_icon {
	-webkit-transform: translateY(-1.2px);
	        transform: translateY(-1.2px);
	margin-left: 8px;
	cursor: pointer;
}

.search-container__filters {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.search-container__filters .filter-checkbox {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: baseline;
	        align-items: baseline;
	padding: 10px 0;
}

.search-container__filters .checkbox-title {
	color: var(--color-grey-600);
	font-size: var(--font-size-s);
	letter-spacing: 0.03px;
	line-height: var(--line-height-s);
	margin-left: 9px;
	width: 230px;
}

.search-container__filter-button {
	display: none;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	max-width: 1212px;
	margin-right: 32px;
}

.search-sidebar__container {
	display: none;
}

.search-container__search-filter {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-top: 0;
}

.search-container__filter-actions {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.sidebar__search-container .search-container__search-block {
	overflow-y: scroll;
	max-height: 100%;
	padding-right: 34px;
	padding-bottom: 200px;
}

.page .homepage-footer {
	z-index: 5;
}

.sidebar__apply-button {
	display: none;
}

.product-intro-text--mobile {
	display: none;
}

.product-intro-text--desktop {
	display: block;
}

.sidebar-mobile {
	display: none;
}

@media (min-width: 481px) and (max-width: 1167px) {
	.search-container__left {
		display: none;
	}

	.search-container__search-block {
		width: 356px;
	}

	.search-sidebar__container {
		display: block;
	}

	.current-search__message {
		max-width: 60vw;
	}
}

@media (min-width: 480px) and (max-width: 1167px) {
	.search-container__filter-button {
		display: -webkit-flex;
		display: flex;
		margin-left: auto;
	}

	.search-container__search-filter {
		margin-bottom: 12px;
	}

	.sidebar-mobile {
		display: none;
	}
}

@media (max-width: 480px) {
	.search-container__search-block {
		width: 100%;
		position: static;
	}

	/* Hide close buton for mobile */
	.search-container__search-block .search-input {
		display: none;
	}

	.search-container__sections-wrapper {
		-webkit-flex: 1 1;
		        flex: 1 1;
		overflow-y: scroll;
	}

	.search-container__search-block {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		height: 100%;
		min-height: 100%;
		padding: 16px;
	}

	.product-intro-text {
		width: calc(100vw - 130px);
	}

	.search-container__right {
		padding: 0;
	}

	.search-container__title {
		color: var(--color-grey-700);
		font-size: var(--font-size-s);
		font-weight: bold;
		letter-spacing: 0.3px;
		line-height: var(--line-height-s);
	}

	.filter-actions .search-container__title {
		border-bottom: 1px solid var(--color-grey-200);
	}

	.search-container__filter-button {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		margin-right: auto;
	}

	.sidebar {
		display: none;
	}

	.sidebar-mobile {
		position: fixed;
		z-index: 1000;
		background-color: white;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		display: block;
	}

	.hide-for-mobile {
		display: none;
	}

	.area-filters {
		-webkit-flex-wrap: nowrap;
		        flex-wrap: nowrap;
		-ms-overflow-style: none;
		scrollbar-width: none;
		overflow-x: scroll;
		overflow-y: hidden;
		width: 90vw;
		padding-top: 8px;
	}

	.area-filters::-webkit-scrollbar {
		display: none;
	}

	.filter-tablet {
		margin-bottom: 0;
		max-height: 32px;
		margin-right: 8px;
		white-space: nowrap;
	}

	.filter-actions {
		position: -webkit-sticky;
		position: sticky;
		top: 9px;
		z-index: 1;
		background-color: var(--color-grey-100);
		padding: 16px 16px;
	}

	.search-container__right {
		overflow-x: unset;
		overflow-y: unset;
	}

	.search-container__masonry-grid {
		margin-top: 26px;
	}

	.search {
		padding: 0 16px;
	}

	.search-container__search-filter {
		-webkit-flex-direction: column-reverse;
		        flex-direction: column-reverse;
		-webkit-justify-content: flex-start;
		        justify-content: flex-start;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		margin-top: 0;
	}

	.search-container__filter-actions {
		width: 100%;
		-webkit-flex-direction: row;
		        flex-direction: row;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		-webkit-align-items: center;
		        align-items: center;
	}

	.search-sidebar__container {
		display: -webkit-flex;
		display: flex;
	}

	/* Mobile sidebar */

	.search-container__search-block .search-container__title {
		color: var(--color-grey-700);
		font-size: var(--font-size-l);
		font-weight: var(--font-weight-medium);
		letter-spacing: 0.04px;
		line-height: var(--line-height-l);
	}

	.search-container__sections-wrapper::-webkit-scrollbar {
		display: none;
	}

	.filter-actions .search-container__title {
		padding-bottom: 16px;
		margin-bottom: 16px;
	}

	.sidebar__apply-button {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		        justify-content: center;
		width: 100%;
		padding: 12px 0;
	}

	.sidebar__apply-button button {
		width: 100%;
	}

	.sidebar__overlay {
		display: none;
	}

	.product-intro-text--mobile {
		display: block;
	}

	.product-intro-text--desktop {
		display: none;
	}

	/* Product item from masonry */

	.product {
		-webkit-flex-direction: row;
		        flex-direction: row;
		height: 88px;
		margin-bottom: 16px;
	}

	.product .product-image {
		display: none;
	}

	.product .product-header {
		height: 88px;
		min-width: 48px;
		padding-right: 0;
		-webkit-justify-content: center;
		        justify-content: center;
		-webkit-align-items: flex-start;
		        align-items: flex-start;
	}

	.product .product-header__icon {
		padding-top: 6px;
	}

	.product-intro-text {
		font-size: var(--font-size-xxs);
	}

	.product .product-title {
		font-size: var(--font-size-s);
		font-weight: bold;
		letter-spacing: 0.3px;
		margin-bottom: 0;
		line-height: var(--line-height-s);
	}

	.product-details__arrow {
		display: block;
	}

	.product-details {
		padding-top: 12px;
	}

	.product svg {
		width: 32px;
		height: 25px;
	}

	.sidebar__close-button-container {
		display: none;
	}

	body {
		overflow: unset !important;
	}
}

@media (max-width: 1167px) {
	.search-container__left {
		display: none;
	}
}

@media all and (min-width: 1600px) {
	.search-container__right {
		-webkit-flex-grow: 1;
		        flex-grow: 1;
		-webkit-flex-shrink: 0;
		        flex-shrink: 0;
		-webkit-flex-basis: calc(1600px - 356px);
		        flex-basis: calc(1600px - 356px);
	}
	.current-search__message {
		max-width: 49vw;
	}
}

.my-engagements-background {
	display: block;
	background-color: var(--color-grey-100);
	min-height: calc(100vh - 185px);
	overflow: hidden;
	padding-bottom: 100px;
}
.my-engagements-container {
	color: var(--color-grey-700);
	margin: 0 32px 32px 40px;
	padding-top: 100px;
	max-width: 1600px;
	overflow: hidden;
}

.my-engagements-container .page-content-wrapper {
	overflow-x: scroll;
}

.my-engagements-container .page-title {
	font-size: var(--font-size-xl);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	line-height: var(--line-height-xl);
	margin-bottom: 32px;
}

.my-engagements-container .hidden {
	display: none;
}

.my-engagements-container .my-engagements-header {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	margin-bottom: 16px;
}

.my-engagements-container .my-engagements-header .product-title {
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height-m);
}

.my-engagements-container .my-engagements-header .product-url {
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
	margin-left: 16px;
	text-transform: uppercase;
}

.my-engagements-container .my-engagements-item-columns {
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	margin-bottom: 8px;
	margin-left: 32px;
	text-transform: uppercase;
}

.my-engagements-container .engagement-block {
	background-color: white;
	box-shadow: 0 3px 10px 0 rgba(0,0,0,0.1);
	height: 64px;
	margin-bottom: 16px;
	overflow: hidden;
	border: 1px solid var(--color-grey-100);
	cursor: pointer;
}

.my-engagements-container .page-content {
	min-width: 950px;
}

.my-engagements-container .engagement-block .engagement-block-content {
	padding: 20px 32px;
}

.my-engagements-container .engagement-block:hover {
	border: 1px solid #5CB247;
}

.my-engagements-container .engagement-block.expanded .engagement-block-content > div:first-child {
	border-bottom: 1px solid var(--color-grey-300);
}

.my-engagements-container .engagement-block.expanded {
	height: auto;
	overflow: auto;
}

.my-engagements-container .product-name {
	font-family: Roboto;
	font-size: var(--font-size-s);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	margin-bottom: 16px;
}

.my-engagements-container .code-name {
	font-family: Roboto;
	font-size: var(--font-size-s);
	letter-spacing: 0.3px;
}

.my-engagements-container .engagement-column {
	padding: 0 !important;
}

.my-engagements-container .code-block {
	height: 24px;
	font-family: Roboto;
	font-size: var(--font-size-s);
	letter-spacing: 0.8px;
	line-height: var(--line-height-s);
	width: 118px;
}

.my-engagements-container .engagement-url,
.my-engagements-container .engagement-email {
	color: var(--color-brand);
	cursor: pointer;
	display: -webkit-flex;
	display: flex;
	font-size: var(--font-size-s);
	font-family: Roboto;
	letter-spacing: 0.3px;
	line-height: var(--line-height-s);
	text-decoration: underline;
}

.my-engagements-container .engagement-email {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
}

.my-engagements-container .engagement-email:after {
	width: 24px;
	height: 24px;
	content: url(/images/Copy.svg);
	visibility: hidden;
	margin-left: 8px;
}

.my-engagements-container .engagement-email:hover:after {
	visibility: visible;
}

.my-engagements-container .users-info-headers {
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	letter-spacing: 0.3px;
	line-height: var(--line-height-s);
	margin: 30px 0 4px 0;
	white-space: nowrap;
}

.my-engagements-container .user-role-value {
	height: 19px;
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
	margin-bottom: 4px;
	white-space: nowrap;
}

.my-engagements-container .dates-field {
	height: 19px;
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
	margin-top: 8px;
}

.my-engagements-container .bottom-row {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
	background-color: var(--color-grey-100);
	height: 48px;
}

.my-engagements-container .bottom-row > div {
	margin-right: 32px;
}

.my-engagements-container .cost-to-date {
	margin-left: 40px;
}

.my-engagements-container .copyTextInput {
	position: absolute;
	left: -300px;
}

.my-engagements-container .request-decommission-block {
	-webkit-align-items: center;
	        align-items: center;
	display: -webkit-flex;
	display: flex;
	color: var(--color-grey-700);
	cursor: pointer;
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	line-height: var(--line-height-xxs);
	text-transform: uppercase;
}

.my-engagements-container .request-decommission-text {
	color: var(--color-grey-700);
	margin-left: 4px;
}

.my-engagements-container .info-section {
	color: var(--color-grey-700);
	font-family: Roboto;
	font-size: var(--font-size-xs);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
	margin-top: 16px;
}

.my-engagements-container .info-section > div {
	margin-bottom: 8px;
}

.my-engagements-container .info-section .title {
	color: var(--color-grey-500);
}

.my-engagements-container .project_url {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.my-engagements-container .engagement-block.expanded .project_url {
	white-space: normal;
}

.my-engagements-container .no-data-container {
	text-align: center;
	font-size: var(--font-size-m);
	color: var(--color-grey-700);
}

.my-engagements-container .no-email {
	color: var(--color-grey-500);
	font-size: 14px;
}

.my-engagements-container .forecat-title {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

.my-engagements-container .forecast-value {
	margin-right: 5px;
}

.my-engagements-container .forecast-icon {
	height: 20px;
}

.forecast-tooltip {
	height: 60px;
	text-align: center;
}

.expanded-body, .bottom-row {
	cursor: default;
}

.my-engagements-container .mobile-title {
	display: none;
}

.engagement-tippy-text {
	color: var(--color-grey-500);
	font-family: Roboto;
	font-size: var(--font-size-xs);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
}

@media (max-width: 768px) {
	.my-engagements-container .engagement-block {
		height: 250px;
	}

	.my-engagements-container .mobile-title {
		display: block;
		color: var(--color-grey-500);
		font-family: Roboto;
		font-size: 11px;
		letter-spacing: 0;
		line-height: 13px;
		text-transform: uppercase;
		font-weight: 300;
		margin: 16px 0 7px 0;
	}

	.my-engagements-container .my-engagements-item-columns {
		display: none;
	}

	.my-engagements-container .engagement-block .engagement-block-content {
		margin-top: 0;
		padding-top: 0;
	}

	.my-engagements-container .engagement-url,
	.my-engagements-container .engagement-email {
		-webkit-flex-direction: column;
		        flex-direction: column;
		text-decoration: none;
	}

	.my-engagements-container .engagement-url a {
		text-decoration: underline;
	}

	.my-engagements-container .product-name {
		margin-bottom: 0;
	}

	.my-engagements-container .page-content {
		min-width: 100%;
	}

	.my-engagements-container .bottom-row {
		-webkit-align-items: flex-start;
		        align-items: flex-start;
		-webkit-flex-direction: column;
		        flex-direction: column;
		height: 125px;
	}

	.my-engagements-container .page-title {
		margin-bottom: 24px;
	}

	.my-engagements-container .engagement-url {
		margin-bottom: 16px;
	}

	.request-decommission {
		margin-top: 25px;
		margin-bottom: 34px;
	}

	.my-engagements-container .request-decommission-block {
		margin-left: 17px;
	}

	.add-user {
		margin-bottom: 25px;
	}

	.info-section-first-column {
		display: none;
	}

	.my-engagements-container {
		margin-left: 16px;
		margin-right: 16px;
	}

	.my-engagements-container .engagement-block .engagement-block-content {
		padding: 0 16px;
	}

	.my-engagements-container .engagement-block .engagement-block-content > div:first-child {
		-webkit-flex-direction: column;
		        flex-direction: column;
	}
}

:root {
	--color-brand: #5cb335;
	--color-brand-dark: #488929;
	--color-brand-darkest: #468b36;
	--color-brand-light: #eff8ed;
	--color-brand-lightest: #f6fbf5;
	--color-danger: #d9625e;
	--color-danger-dark: #b54a46;
	--color-grey-100: #f7f7f7;
	--color-grey-150: #f2f2f2;
	--color-grey-200: #e6e6e6;
	--color-grey-300: #cccccc;
	--color-grey-400: #999999;
	--color-grey-500: #737373;
	--color-grey-600: #4d4d4d;
	--color-grey-700: #333333;
	--color-grey-800: #1a1a1a;

	--color-black: #000000;
	--color-white: #ffffff;

	--color-grey-section-background: #f0f0f0;
}

:root {
	--font-size-giant-l: 98px;
	--font-size-giant-m: 80px;
	--font-size-giant-s: 60px;
	--font-size-giant-xs: 45px;
	--font-size-giant-xxs: 40px;

	--font-size-xxl: 33px;
	--font-size-xl: 28px;
	--font-size-l: 24px;
	--font-size-m: 18px;
	--font-size-s: 16px;
	--font-size-xs: 13px;
	--font-size-xxs: 11px;
	--font-size-xxxs: 9px;

	--line-height-giant: 98px;
	--line-height-xxl: 41px;
	--line-height-xl: 35px;
	--line-height-l: 31px;
	--line-height-m: 27px;
	--line-height-s: 22px;
	--line-height-xs: 19px;
	--line-height-xxs: 13px;
	--line-height-xxxs: 11px;

	--font-weight-light: 300;
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-bold: 700;
	--font-weight-black: 900;
}

:root {
	--timing-function-ease-out-expo: cubic-bezier(0.190,  1.000, 0.220, 1.000);
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-webkit-keyframes modalScaleIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	50% {
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes modalScaleIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	50% {
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translate(0px, 0px);
		        transform: translate(0px, 0px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate(0px, -25px);
		        transform: translate(0px, -25px);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translate(0px, 0px);
		        transform: translate(0px, 0px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate(0px, -25px);
		        transform: translate(0px, -25px);
	}
}

@-webkit-keyframes scaleInRotateRight {
	from {
		-webkit-transform: scale(0.8) rotate(-120deg);
		        transform: scale(0.8) rotate(-120deg);
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes scaleInRotateRight {
	from {
		-webkit-transform: scale(0.8) rotate(-120deg);
		        transform: scale(0.8) rotate(-120deg);
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes scaleIn {
	from {
		-webkit-transform: scale(0.8);
		        transform: scale(0.8);
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes scaleIn {
	from {
		-webkit-transform: scale(0.8);
		        transform: scale(0.8);
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes spinner {
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes spinner {
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@-webkit-keyframes show {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes show {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}


*,
*::before,
*::after {
	box-sizing: border-box;
}

:root {
	--ap-content-max-width: 1600px;
}

html {
	font-family: 'Roboto', sans-serif;
	font-size: var(--font-size-s);
	line-height: 1.3;
	font-weight: var(--font-weight-regular);
	color: var(--color-grey-800);
	background-color: white;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
	overflow-y: overlay;
}

#root {
	position: relative;
	z-index: 1;
}

#modal-root {
	position: relative;
	z-index: 2;
}

.no-scroll {
	overflow: hidden;
}

svg:not(:root) {
	overflow: visible;
}

a {
	color: inherit;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: inherit;
}

p {
	margin: 0;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

table {
	border-collapse: collapse;
}

button {
	text-transform: inherit;
	font: inherit;
	color: inherit;
	border-radius: 0;
	background-color: transparent;
}

input,
textarea {
	font: inherit;
}

input[type='number'] {
	-webkit-appearance: textfield;
	   -moz-appearance: textfield;
	        appearance: textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	        appearance: none;
	margin: 0;
}

#adalIdTokenFrame {
	top: 0;
}

/* width */
::-webkit-scrollbar {
	width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
	background-color: #f7f7f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
	width: 10px;
	border-radius: 6px;
	background-color: #d8d8d8;
	border: 3px solid transparent;
	background-clip: content-box;
}

