.grid-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 33px;
}
 
.grid-select:hover .grid-select-item.transition-enter-done {
  transform: none;
}

.grid-select:after {
  content: ' ';
  width: 32%;
}
