.editor-error-label {
	color: var(--color-danger);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.8px;
}

input + .editor-error-label, .select + .editor-error-label  {
  margin-top: 5px;
}