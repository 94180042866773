.tippy-popper .settings-tooltip-theme {
	min-width: 180px;
}

.settings-dropdown.light-nav.active {
	color: var(--color-brand-dark);
}

.settings-dropdown__list {
	padding: 8px 0;
}

.settings-dropdown__list-item {
	background: white;
	animation: background-color 300ms ease-in;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-height: 32px;
	min-width: 180px;
}

.settings-dropdown__list-item:hover {
	background-color: var(--color-brand-lightest);
}

.settings-dropdown__list-item:hover .settings-dropdown__list-button {
	color: var(--color-grey-800);
}

.settings-dropdown__list-button {
	color: var(--color-grey-500);
	appearance: none;
	outline: 0;
	border: 0;
	padding: 0;
	transition-property: color;
	transition-duration: 333ms;
	transition-timing-function: var(--timing-function-ease-out-expo);
	cursor: pointer;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	text-align: start;
	width: 100%;
	padding: 8px 16px;
}
