.grid-list__items {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-column-gap: 16px;
	grid-row-gap: 16px;
}

.grid-list__item {
	border-radius: 1px;
	overflow: hidden;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	transition-property: box-shadow;
	transition-duration: 300ms;
}

.grid-list__item:hover {
	box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
	.grid-list__items {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 23px;
		grid-row-gap: 23px;
	}
}

@media (min-width: 960px) {
	.grid-list__items {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 32px;
		grid-row-gap: 32px;
	}
}

@media (min-width: 1167px) {
	.grid-list__items {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (min-width: 1360px) {
	.grid-list__items {
		grid-template-columns: repeat(4, 1fr);
	}
}
