.my-engagements-background {
	display: block;
	background-color: var(--color-grey-100);
	min-height: calc(100vh - 185px);
	overflow: hidden;
	padding-bottom: 100px;
}
.my-engagements-container {
	color: var(--color-grey-700);
	margin: 0 32px 32px 40px;
	padding-top: 100px;
	max-width: 1600px;
	overflow: hidden;
}

.my-engagements-container .page-content-wrapper {
	overflow-x: scroll;
}

.my-engagements-container .page-title {
	font-size: var(--font-size-xl);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	line-height: var(--line-height-xl);
	margin-bottom: 32px;
}

.my-engagements-container .hidden {
	display: none;
}

.my-engagements-container .my-engagements-header {
	align-items: center;
	display: flex;
	margin-bottom: 16px;
}

.my-engagements-container .my-engagements-header .product-title {
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height-m);
}

.my-engagements-container .my-engagements-header .product-url {
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
	margin-left: 16px;
	text-transform: uppercase;
}

.my-engagements-container .my-engagements-item-columns {
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	margin-bottom: 8px;
	margin-left: 32px;
	text-transform: uppercase;
}

.my-engagements-container .engagement-block {
	background-color: white;
	box-shadow: 0 3px 10px 0 rgba(0,0,0,0.1);
	height: 64px;
	margin-bottom: 16px;
	overflow: hidden;
	border: 1px solid var(--color-grey-100);
	cursor: pointer;
}

.my-engagements-container .page-content {
	min-width: 950px;
}

.my-engagements-container .engagement-block .engagement-block-content {
	padding: 20px 32px;
}

.my-engagements-container .engagement-block:hover {
	border: 1px solid #5CB247;
}

.my-engagements-container .engagement-block.expanded .engagement-block-content > div:first-child {
	border-bottom: 1px solid var(--color-grey-300);
}

.my-engagements-container .engagement-block.expanded {
	height: auto;
	overflow: auto;
}

.my-engagements-container .product-name {
	font-family: Roboto;
	font-size: var(--font-size-s);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	margin-bottom: 16px;
}

.my-engagements-container .code-name {
	font-family: Roboto;
	font-size: var(--font-size-s);
	letter-spacing: 0.3px;
}

.my-engagements-container .engagement-column {
	padding: 0 !important;
}

.my-engagements-container .code-block {
	height: 24px;
	font-family: Roboto;
	font-size: var(--font-size-s);
	letter-spacing: 0.8px;
	line-height: var(--line-height-s);
	width: 118px;
}

.my-engagements-container .engagement-url,
.my-engagements-container .engagement-email {
	color: var(--color-brand);
	cursor: pointer;
	display: flex;
	font-size: var(--font-size-s);
	font-family: Roboto;
	letter-spacing: 0.3px;
	line-height: var(--line-height-s);
	text-decoration: underline;
}

.my-engagements-container .engagement-email {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
}

.my-engagements-container .engagement-email:after {
	width: 24px;
	height: 24px;
	content: url(/images/Copy.svg);
	visibility: hidden;
	margin-left: 8px;
}

.my-engagements-container .engagement-email:hover:after {
	visibility: visible;
}

.my-engagements-container .users-info-headers {
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	letter-spacing: 0.3px;
	line-height: var(--line-height-s);
	margin: 30px 0 4px 0;
	white-space: nowrap;
}

.my-engagements-container .user-role-value {
	height: 19px;
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
	margin-bottom: 4px;
	white-space: nowrap;
}

.my-engagements-container .dates-field {
	height: 19px;
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
	margin-top: 8px;
}

.my-engagements-container .bottom-row {
	align-items: center;
	display: flex;
	flex-direction: row-reverse;
	background-color: var(--color-grey-100);
	height: 48px;
}

.my-engagements-container .bottom-row > div {
	margin-right: 32px;
}

.my-engagements-container .cost-to-date {
	margin-left: 40px;
}

.my-engagements-container .copyTextInput {
	position: absolute;
	left: -300px;
}

.my-engagements-container .request-decommission-block {
	align-items: center;
	display: flex;
	color: var(--color-grey-700);
	cursor: pointer;
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	line-height: var(--line-height-xxs);
	text-transform: uppercase;
}

.my-engagements-container .request-decommission-text {
	color: var(--color-grey-700);
	margin-left: 4px;
}

.my-engagements-container .info-section {
	color: var(--color-grey-700);
	font-family: Roboto;
	font-size: var(--font-size-xs);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
	margin-top: 16px;
}

.my-engagements-container .info-section > div {
	margin-bottom: 8px;
}

.my-engagements-container .info-section .title {
	color: var(--color-grey-500);
}

.my-engagements-container .project_url {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.my-engagements-container .engagement-block.expanded .project_url {
	white-space: normal;
}

.my-engagements-container .no-data-container {
	text-align: center;
	font-size: var(--font-size-m);
	color: var(--color-grey-700);
}

.my-engagements-container .no-email {
	color: var(--color-grey-500);
	font-size: 14px;
}

.my-engagements-container .forecat-title {
	display: flex;
	align-items: center;
}

.my-engagements-container .forecast-value {
	margin-right: 5px;
}

.my-engagements-container .forecast-icon {
	height: 20px;
}

.forecast-tooltip {
	height: 60px;
	text-align: center;
}

.expanded-body, .bottom-row {
	cursor: default;
}

.my-engagements-container .mobile-title {
	display: none;
}

.engagement-tippy-text {
	color: var(--color-grey-500);
	font-family: Roboto;
	font-size: var(--font-size-xs);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
}

@media (max-width: 768px) {
	.my-engagements-container .engagement-block {
		height: 250px;
	}

	.my-engagements-container .mobile-title {
		display: block;
		color: var(--color-grey-500);
		font-family: Roboto;
		font-size: 11px;
		letter-spacing: 0;
		line-height: 13px;
		text-transform: uppercase;
		font-weight: 300;
		margin: 16px 0 7px 0;
	}

	.my-engagements-container .my-engagements-item-columns {
		display: none;
	}

	.my-engagements-container .engagement-block .engagement-block-content {
		margin-top: 0;
		padding-top: 0;
	}

	.my-engagements-container .engagement-url,
	.my-engagements-container .engagement-email {
		flex-direction: column;
		text-decoration: none;
	}

	.my-engagements-container .engagement-url a {
		text-decoration: underline;
	}

	.my-engagements-container .product-name {
		margin-bottom: 0;
	}

	.my-engagements-container .page-content {
		min-width: 100%;
	}

	.my-engagements-container .bottom-row {
		align-items: flex-start;
		flex-direction: column;
		height: 125px;
	}

	.my-engagements-container .page-title {
		margin-bottom: 24px;
	}

	.my-engagements-container .engagement-url {
		margin-bottom: 16px;
	}

	.request-decommission {
		margin-top: 25px;
		margin-bottom: 34px;
	}

	.my-engagements-container .request-decommission-block {
		margin-left: 17px;
	}

	.add-user {
		margin-bottom: 25px;
	}

	.info-section-first-column {
		display: none;
	}

	.my-engagements-container {
		margin-left: 16px;
		margin-right: 16px;
	}

	.my-engagements-container .engagement-block .engagement-block-content {
		padding: 0 16px;
	}

	.my-engagements-container .engagement-block .engagement-block-content > div:first-child {
		flex-direction: column;
	}
}
