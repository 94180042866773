.file-item {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	min-height: 168px;
	max-height: 168px;
	width: 100%;
	padding: 16px 24px 16px 24px;
	flex-wrap: wrap;
	position: relative;
}

.file-item__save-icon svg {
	vertical-align: middle;
}

.file-item__save-icon {
	display: block;
	position: absolute;
	color: var(--color-grey-600);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.2) 0%,
		rgba(255, 255, 255, 1) 100%
	);
	width: 100%;
	right: 0;
	bottom: -120px;
	transition: bottom 300ms;
	left: 0;
	text-align: center;
	height: 120px;
	line-height: 150px;
}

@media(hover: hover){
	.file-item:hover .file-item__save-icon {
		bottom: 0;
	}
}

.file-item--disabled {
	pointer-events: none;
}

.file-item__icon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: var(--color-grey-400); /* TODO: Use new color palette */
}

.file-item__icon svg {
	width: 32px;
	height: 32px;
}

.file-item__details {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: 16px;
	justify-content: center;
	width: calc(100% - 57px);
}

.file-item__description--blank,
.file-item__description {
	padding-top: 8px;
	border-top: 1px solid var(--color-grey-200);
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	letter-spacing: 0.8px;
	margin: 12px 15px 0 0;
	min-width: 100%;
	height: 84px;
	overflow: hidden;
}

.file-item__description--blank {
	line-height: 84px;
	text-align: center;
	color: var(--color-grey-300);
	text-transform: uppercase;
}

.file-item__header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}

.file-item__name {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-regular);
	flex-basis: 100%;
	color: var(--color-grey-800);
	letter-spacing: 0.8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.file-item__version {
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	font-weight: var(--font-weight-regular);
	letter-spacing: 0.8px;
	color: var(--color-grey-500);
	flex-basis: 100%;
}

.file-item__error {
	color: var(--color-danger);
}

@media (min-width: 768px) {
	.file-item__icon svg {
		width: 40px;
		height: 40px;
	}

	.file-item__name {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		letter-spacing: 0.3px;
	}

	.file-item__version {
		font-size: var(--font-size-xs);
		line-height: var(--line-height-xs);
	}

	.file-item__description--blank,
	.file-item__description {
		font-size: var(--font-size-xs);
		line-height: var(--line-height-xs);
	}
}
