.ap-modal-content {
	max-width: 611px;
	width: 611px;
}

.new-engagement {
	display: flex;
	justify-content: center;
	padding: 16px 0;
}

.new-engagement__actions {
	display: none;
	justify-content: center;
}

.new-engagement__actions-info {
	display: flex;
	color: var(--color-grey-800);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
}

.new-engagement-block {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.new-engagement-block__image {
	height: 221px;
	width: 230px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.new-engagement-block__image img {
	max-width: 161px;
	max-height: 161px;
	width: auto;
	height: auto;
}

.new-engagement-block__title {
	color: var(--color-grey-700);
	font-size: var(--font-size-m);
	line-height: 31px;
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.25px;
	text-align: center;
	max-width: 182px;
}

.new-engagement-block__separator {
	width: 1px;
	background-color: var(--color-grey-300);
	min-height: 388px;
	margin: 0 30px;
}

.new-engagement-block__email-ets-image {
	height: 160px;
	width: 160px;
	border: 1px solid var(--color-grey-300);
	background-color: var(--color-grey-100);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.new-engagement-block__email-ets-image img {
	transform: translateX(4px);
}

@media (min-width: 768px) {
	.new-engagement__actions {
		display: flex;
	}
	.new-engagement__actions-info {
		display: none;
	}
}
