.expert-edit-section {
	position: relative;
}

.expert-edit-section__hint {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
}

.expert-edit-section__link {
	display: block;
	margin-top: 16px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	transition: color 333ms var(--timing-function-ease-out-expo);
	text-decoration: underline;
}

.expert-edit-section__link:hover {
	color: var(--color-brand);
}

.expert-edit-section__file-picker-label {
	transition: color 333ms var(--timing-function-ease-out-expo);
	cursor: pointer;
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
}

.expert-edit-section__file-picker-label:hover {
	color: var(--color-brand-darkest);
}

.expert-edit-section__file-picker-input {
	display: none; /*label has htmlFor to open file window*/
}
