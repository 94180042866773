.product-when-to-use-section {
  padding-top: 52px;
}

.product-when-to-use__container {
  padding: 12px 8px;
  outline: 1px solid var(--color-grey-300);
  color: var(--color-grey-800);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	transition-property: outline;
  transition-duration: 300ms;
  min-height: 80px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
}

.product-when-to-use__container.selected {
	outline: 2px solid var(--color-brand);
}

.product-when-to-use__container-no-text {
  color: var(--color-grey-500);	
  font-size: var(--font-size-s);	
  letter-spacing: 0.3px;	
  line-height: var(--line-height-s);	
  display: flex;
  justify-content: center;
  align-items: center;
}
