.overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	animation: show 666ms 250ms var(--timing-function-ease-out-expo) both;
}

.overlay--no-data {
	background-color: transparent;
	animation: none;
}

.overlay--no-data svg {
	color: var(--color-grey-500);
}

.overlay__message {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	text-align: center;
	letter-spacing: 0.8px;
	display: block;
	padding-left: 5px;
	color: var(--color-grey-500);
}
