.sort-dropdown__label, .sort-dropdown .menu-dropdown__label {
  display: flex;
  align-items: center;
  font-size: var(--font-size-xs);
  line-height: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  color: var(--color-grey-700);
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.sort-dropdown__label {
  display: inline-block;
  margin-right: 5px;
}

.sort-dropdown__menu-container{
  min-width: 200px;
  top: 10px;
}
