:root {
	--font-size-giant-l: 98px;
	--font-size-giant-m: 80px;
	--font-size-giant-s: 60px;
	--font-size-giant-xs: 45px;
	--font-size-giant-xxs: 40px;

	--font-size-xxl: 33px;
	--font-size-xl: 28px;
	--font-size-l: 24px;
	--font-size-m: 18px;
	--font-size-s: 16px;
	--font-size-xs: 13px;
	--font-size-xxs: 11px;
	--font-size-xxxs: 9px;

	--line-height-giant: 98px;
	--line-height-xxl: 41px;
	--line-height-xl: 35px;
	--line-height-l: 31px;
	--line-height-m: 27px;
	--line-height-s: 22px;
	--line-height-xs: 19px;
	--line-height-xxs: 13px;
	--line-height-xxxs: 11px;

	--font-weight-light: 300;
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-bold: 700;
	--font-weight-black: 900;
}
