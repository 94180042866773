
.editor-add-button {
	position: relative;
	width: 100%;
	height: 48px;
	padding: 8px;
	border: 1px solid transparent;
	display: flex;
	align-items: center;
	justify-content: center;
  background-color: var(--color-brand-light);
  color: var(--color-brand);
	cursor: pointer;
  outline: none;
  transition: all .3s ease-out;
}

.editor-add-button:hover, .editor-add-button--selected {
  border: 1px solid var(--color-brand-dark);
	color: var(--color-brand-dark);
}
