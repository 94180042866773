
.editor-grid-title-row {
	display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px; 
}

.editor-grid-title-row__right {
	align-items: center;
	display: flex;
}