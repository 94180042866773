.create-product-dialog {
	height: auto;
	width: 840px;
}

.create-product-dialog__paragraph {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
}

.create-product-dialog__name {
	font-weight: var(--font-weight-bold);
}

.create-product-dialog__input {
	height: 33px;
	padding: 4px 8px;
	font-size: 16px;
	color: var(--color-grey-800);
	width: 100%;
	margin-top: 6px;
}

.create-product-dialog__input-label {
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	letter-spacing: 0.8px;
	text-transform: uppercase;
	display: block;
}

.create-product-dialog__footer {
	padding: 14px 32px 30px 16px;
}
.create-product-dialog__footer .button--secondary {
	padding: 8px 8px;
}
.create-product-dialog__footer .button--primary {
	padding: 6px 24px;
	height: 32px;
}
.create-product-dialog__footer .create-product-dialog__cancel-button {
	margin-right: auto;
	padding: 8px 16px;
}
