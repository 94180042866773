*,
*::before,
*::after {
	box-sizing: border-box;
}

:root {
	--ap-content-max-width: 1600px;
}

html {
	font-family: 'Roboto', sans-serif;
	font-size: var(--font-size-s);
	line-height: 1.3;
	font-weight: var(--font-weight-regular);
	color: var(--color-grey-800);
	background-color: white;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
	overflow-y: overlay;
}

#root {
	position: relative;
	z-index: 1;
}

#modal-root {
	position: relative;
	z-index: 2;
}

.no-scroll {
	overflow: hidden;
}

svg:not(:root) {
	overflow: visible;
}

a {
	color: inherit;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: inherit;
}

p {
	margin: 0;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

table {
	border-collapse: collapse;
}

button {
	text-transform: inherit;
	font: inherit;
	color: inherit;
	border-radius: 0;
	background-color: transparent;
}

input,
textarea {
	font: inherit;
}

input[type='number'] {
	appearance: textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	appearance: none;
	margin: 0;
}

#adalIdTokenFrame {
	top: 0;
}

/* width */
::-webkit-scrollbar {
	width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
	background-color: #f7f7f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
	width: 10px;
	border-radius: 6px;
	background-color: #d8d8d8;
	border: 3px solid transparent;
	background-clip: content-box;
}
