.area-detail {
	margin-top: 64px;
	padding: 0 40px 0;
	color: white;
	position: relative;
	background: black;
	height: 336px;
	background-image: url(/images/area-detail-bg.jpg);
	width: 100%;
	font-weight: var(--font-weight-regular);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.area-detail__row {
	display: flex;
	justify-content: space-between;
}

.area-detail__left {
	width: 42%;
}

.area-detail__left-cols-8 {
	width: 67%;
}

.area-detail__right {
	width: 58%;
	padding-left: 32px;
	padding-top: 35px;
}

.area-detail__right-cols-4 {
	width: 33%;
}

.area-detail__title {
	margin-top: 24px;
	font-size: var(--font-size-xxl);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	line-height: var(--line-height-xxl);
	text-transform: uppercase;
}

.area-detail__description {
	margin-top: 24px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
}

.area-detail__grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	margin-top: -25px;
}

.area-detail__stat-item {
	min-height: 180px;
	border-left: 1px solid var(--color-grey-600);
	padding-top: 31px;
}

.grid-list__item:first-child .area-detail__stat-item {
	border-left: none;
}

.grid-list__item:last-child .area-detail__stat-item {
	border-bottom: none;
}

.area-detail__term-label {
	width: 100%;
	letter-spacing: 0.8px;
	text-align: center;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-300);
}

.area-detail__term-value {
	color: var(--color-grey-100);
	line-height: var(--line-height-giant-l);
	letter-spacing: 7.13px;
	text-align: center;
	padding-top: 20px;
}

.area-detail__term-value--type-default {
	font-size: var(--font-size-giant-l);
}

.area-detail__term-value--type-5 {
	font-size: var(--font-size-giant-m);
}

.area-detail__term-value--type-6 {
	font-size: 64px;
}

.area-detail__term-value--type-7 {
	font-size: 50px;
}

.area-detail__term-value--type-8 {
	font-size: var(--font-size-giant-xs);
}

.area-detail__term-value--type-9 {
	font-size: var(--font-size-giant-xxs);
}

.area-detail__term-value--type-10 {
	font-size: 36px;
}

.area-detail__term-value--type-11 {
	font-size: 30px;
}

.area-detail__term-value--type-12 {
	font-size: var(--font-size-xl);
}

.area-detail__term-value--type-13 {
	font-size: 25px;
}

.area-detail__term-value--type-14 {
	font-size: 22px;
}

.area-detail__description.area-detail__description--mobile {
	display: none;
}

.area-detail__read-more {
	cursor: pointer;
	text-transform: uppercase;
	text-decoration: underline;
	font-weight: var(--font-weight-bold);
}

.area-button__link {
	margin-top: 8px;
	margin-left: -2px;
}
.area-button__link:hover {
	opacity: 0.8;
}

@media (min-width: 768px) and (max-width: 1440px) {
	.area-detail__term-label {
		font-size: var(--font-size-xs);
	}

	.area-detail__description {
		font-size: var(--font-size-xs);
		letter-spacing: 0.8px;
		line-height: var(--line-height-xs);
		margin-top: 30px;
	}

	.area-detail__title {
		font-size: var(--font-size-l);
	}

	/* Stats font sizes */

	.area-detail__term-value--type-default {
		font-size: 72px;
	}

	.area-detail__term-value--type-5 {
		font-size: var(--font-size-giant-xxs);
	}

	.area-detail__term-value--type-6 {
		font-size: 34px;
	}

	.area-detail__term-value--type-7 {
		font-size: var(--font-size-xl);
	}

	.area-detail__term-value--type-8 {
		font-size: var(--font-size-l);
	}

	.area-detail__term-value--type-9 {
		font-size: var(--font-size-m);
	}

	.area-detail__term-value--type-10 {
		font-size: 19px;
	}

	.area-detail__term-value--type-11 {
		font-size: 17px;
	}

	.area-detail__term-value--type-12 {
		font-size: 15px;
	}

	.area-detail__term-value--type-13 {
		font-size: 13px;
	}

	.area-detail__term-value--type-14 {
		font-size: 12px;
	}
}

@media (max-width: 1167px) {
	.area-detail {
		height: 404px;
	}

	.area-detail__row {
		flex-direction: column;
		height: 337px;
	}

	.area-detail__right {
		padding-bottom: 16px;
		width: 100%;
	}

	.area-detail__title {
		margin-top: 0;
	}

	.area-detail__description {
		margin-top: 9px;
	}

	.breadcrumb__list {
		padding-bottom: 9px;
	}

	.area-detail__left {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.area-detail__right {
		width: 100%;
		padding: 0;
	}

	.area-detail__title {
		font-size: var(--line-height-xs);
		font-weight: bold;
		line-height: var(--line-height-m);
	}

	.area-detail__description {
		display: none;
	}

	.area-detail__description.area-detail__description--mobile {
		display: block;
		margin-top: 24px;
		font-size: var(--font-size-xs);
	}

	.area-detail__grid {
		grid-template-rows: 1fr 1fr 1fr;
		grid-template-columns: 1fr !important;
	}

	.area-detail__stat-item {
		border-bottom: 1px solid var(--color-grey-600);
		border-left: 0;
	}

	.area-detail__term-value {
		padding-top: 0;
	}

	.area-detail {
		min-height: 556px;
		height: unset;
		padding: 0 16px 0;
	}

	.area-detail__term-label {
		font-size: var(--font-size-xs);
	}

	.area-detail__stat-item {
		min-height: 107px;
		padding-top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.area-detail__row {
		flex-direction: column;
		height: 100%;
	}

	.area-detail__right {
		padding: 32px 24px 24px;
	}

	/* Stats font sizes */

	.area-detail__term-value--type-default,
	.area-detail__term-value--type-5,
	.area-detail__term-value--type-6,
	.area-detail__term-value--type-7,
	.area-detail__term-value--type-8,
	.area-detail__term-value--type-9 {
		font-size: var(--font-size-giant-xxs);
	}

	.area-detail__term-value--type-10 {
		font-size: 38px;
	}

	.area-detail__term-value--type-11 {
		font-size: var(--font-size-xxl);
	}

	.area-detail__term-value--type-12 {
		font-size: var(--font-size-xl);
	}

	.area-detail__term-value--type-13 {
		font-size: 26px;
	}

	.area-detail__term-value--type-14 {
		font-size: var(--font-size-l);
	}
}

@media (max-width: 1167px) {
	.decrease-height {
		height: 150px;
		min-height: unset;
	}
}
