.experts-reorder-list {
	margin-bottom: 24px;
}

.expert-list__item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	user-select: none;
}

.expert-list__name {
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
	overflow: hidden;
	outline: 0;
	border: 0;
	text-align: left;
	border-radius: 0;
	flex: 1;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	padding: 10px 0;
	color: var(--color-grey-700);
	transition: all 333ms var(--timing-function-ease-out-expo);
	letter-spacing: 0.3px;
}

.expert-list__name.inActive {
	pointer-events: none;
	color: var(--color-grey-500);
}

.expert-list__footer {
	display: flex;
	padding-top: 16px;
}

.expert-list__item-drag-handle {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--color-grey-400);
	margin-right: 24px;
}

.expert-list__item-drag-handle:hover {
	color: var(--color-grey-700);
}

.expert-list__item-delete:hover {
	color: var(--color-danger);
}

.expert-list--dragging {
	margin-bottom: 68px; /*one item height - This should not disturb bottom section moving as item is displaced*/
}