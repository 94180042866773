.clear-text-button {
	border: none;
	padding: 0;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xxs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	color: var(--color-danger);
	transition: all 333ms var(--timing-function-ease-out-expo);
	text-transform: uppercase;
	cursor: pointer;
}

.clear-text-button:hover {
	color: var(--color-danger-dark);
}
