.toggle {
	position: relative;
	display: inline-flex;
}

.toggle__checkbox {
	opacity: 0;
	width: 0;
	height: 0;
}

.toggle__slider {
	position: absolute;
	cursor: pointer;
	width: 32px;
	height: 18px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	outline: none;
	background-color: white;
	border: 2px solid var(--color-grey-300);
	border-radius: 15px;
	transition: all 333ms var(--timing-function-ease-out-expo);
}

.toggle__label {
	color: var(--color-grey-800);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-left: 42px;
}

.toggle__checkbox:focus + .toggle__slider::after,
.toggle__checkbox:active + .toggle__slider::after {
	display: block;
}

.toggle__slider::after {
	content: '';
	display: none;
	position: absolute;
	top: -5px;
	left: -5px;
	border-radius: 15px;
	height: 24px;
	width: 38px;
	border: 2px solid #ccc;
}

.toggle__slider::before {
	content: '';
	position: absolute;
	height: 10px;
	width: 10px;
	left: 2px;
	bottom: 2px;
	background-color: var(--color-grey-300);
	border-radius: 50%;
	transition: all 333ms var(--timing-function-ease-out-expo);
}

.toggle__checkbox:checked + .toggle__slider {
	border-color: var(--color-brand);
}

.toggle__checkbox:checked + .toggle__slider::before {
	transform: translateX(14px);
	background-color: var(--color-brand);
}

.toggle__checkbox:not([disabled]) + .toggle__slider:hover::before {
	background-color: var(--color-brand-dark);
}

.toggle__checkbox:disabled + .toggle__slider {
	border-color: var(--color-grey-200);
}

.toggle__checkbox:disabled + .toggle__slider::before {
	background-color: var(--color-grey-300);
}
