.homepage-navbar {
	width: 100vw !important;
}

.homepage-navbar__list {
	display: none;
	position: relative;
}

.homepage-navbar__list .menu-dropdown__options-container {
	top: 64px;
}

.homepage-navbar__item.active .menu-dropdown__label {
	color: #ffffff;
}

.homepage-navbar__list .menu-dropdown__label:hover {
	color: var(--color-brand);
	cursor: pointer;
}

.homepage-navbar__list .menu-dropdown__label:hover + .menu-dropdown__icon svg {
	color: var(--color-brand);
}

.homepage-navbar__list .menu-dropdown__icon svg:hover {
	fill: var(--color-brand);
}

.homepage-navbar__item {
	display: inline-flex;
	align-items: center;
	color: var(--color-grey-400);
	user-select: none;
	margin-right: 24px;
}

.homepage-navbar__item:first-child {
	padding-right: 8px;
}

.homepage-navbar__item .menu-dropdown__label,
.homepage-navbar__link {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
	outline: 0;
	height: 64px;
	display: inline-flex;
	align-items: center;
	transition: color 333ms var(--timing-function-ease-out-expo);
	color: inherit;
}

.homepage-navbar__link:hover {
	color: var(--color-brand);
}

.homepage-navbar__link:focus,
.homepage-navbar__link.active {
	color: white;
}

.homepage-navbar__link + .homepage-navbar__link {
	margin-left: 16px;
	margin-right: 24px;
}

/* Make left part narrow */
.homepage-navbar > div > div:first-child {
	max-width: 150px;
}

.homepage-navbar__nav-link {
	display: flex;
	align-self: stretch;
	align-items: center;
}

.homepage-navbar__item.my-engagements,
.homepage-navbar__item.companies {
	cursor: pointer;
	color: var(--color-grey-400);
	font-size: var(--font-size-s);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height-s);
	margin-right: 32px;
}

.homepage-navbar__item.my-engagements.active {
	color: white;
}

.homepage-navbar__item.my-engagements:hover,
.homepage-navbar__item.companies:hover {
	color: var(--color-brand);
}

.homepage-navbar__item.my-engagements.active:hover {
	color: white;
}


@media (min-width: 960px) {
	.homepage-navbar__list {
		display: inline-flex;
	}
}
