.experts-search {
	position: relative;
}

.experts-search__controls {
	position: relative;
}

.experts-search__input {
	height: 48px;
	padding: 16px;
}

.experts-search__controls-search,
.experts-search__controls-close {
	position: absolute;
	right: 14px;
	top: 12px;
}

.experts-search__results {
  background-color: white;
	width: 100%;
	padding: 0;
	margin: 0;
	position: absolute;
	max-height: 30vh;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 99999;
	margin-top: 0;
	border-radius: 0;
	transition: opacity 0.1s ease;
	opacity: 0;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.experts-search__results--open {
	opacity: 1;
}

.experts-search__results-header {
  display: flex;
  height: 32px;
  background-color: var(--color-grey-100);
  color: var(--color-grey-500);
  font-size: var(--font-size-xxs);
  line-height: var(--line-height-xxs);
  padding: 8px 16px;
}

.experts-search__results-name {
  width: 38%;
}

.experts-search__results-role {
  width: 38%;
}

.experts-search__results-location {
  width: 24%;
}

.experts-search__results-list {
	padding: 0;
	margin: 0;
}

.experts-search__results-list-item {
  display: flex;
  min-height: 40px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  letter-spacing: .3px;
  color: var(--color-grey-500);
}
