.home-page-container {
	color: var(--color-grey-800);
	font-size: var(--font-size-m);
	letter-spacing: 0.03px;
	width: 100vw;
}

.home-page-container section {
	margin-left: auto;
	margin-right: auto;
	max-width: 1600px;
	overflow: hidden;
}

/* Top block styles */
.home-page-container__top-block-container {
	background: #ebeff2;
	height: 570px;
}
.home-page-container__top-block {
	display: flex;
	height: 100%;
	padding: 20px 0;
	width: 100vw;
}
.home-page-container__top-block-info,
.home-page-container__top-block-logo-container {
	align-items: center;
	display: flex;
	flex: 1 1 50%;
}

.home-page-container__top-block-logo-container {
	justify-content: flex-end;
}

.home-page-container__top-block-logo {
	background: url('/images/AlixIP-Home-Banner.png') no-repeat;
	background-size: contain;
	height: 458px;
	width: 720px;
}

.home-page-container__top-block-info {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 10px;
}

.home-page-container__top-block-text {
	letter-spacing: 0.02px;
	line-height: 35px;
	font-size: 27px;
	height: 105px;
	max-width: 548px;
	padding-right: 50px;
}

.home-page-container__top-block-title {
	display: flex;
	align-items: center;
	height: 110px;
	width: 304px;
}

.home-page-container__top-block-title svg {
	height: 59px;
	width: 192px;
}

/* Services block styles */

.home-page-container__services-block {
	display: flex;
	justify-content: center;
	overflow: visible;
	position: relative;
	width: 100%;
	z-index: 5;
}

.home-page-container__services-block-button .button--primary {
	height: 32px;
	padding-top: 7px;
	width: 204px;
}

.home-page-container__services-block-left {
	display: flex;
	flex-direction: row-reverse;
	flex: 1 0 41.6%;
	margin-top: 50px;
}

.home-page-container__services-block-left-content {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 85px 0 0 30px;
	position: relative;
	max-width: 432px;
}

.home-page-container__services-block-text {
	height: 92px;
	line-height: 27px;
	text-align: right;
	max-width: 432px;
}
.home-page-container__services-block-title {
	font-size: var(--font-size-giant-xxs);
	font-weight: bold;
	height: 147px;
	letter-spacing: 0.02px;
	line-height: 49px;
	text-align: right;
	max-width: 432px;
}

.home-page-container__services-block-right {
	padding-left: 32px;
	align-items: center;
	display: flex;
	flex: 1 0 58.4%;
	justify-content: flex-end;
	height: auto;
	margin-top: 50px;
	min-height: 450px;
}

.home-page-container__services-block-right-content {
	position: relative;
	height: auto;
	margin-bottom: 110px;
	width: 100%;
}

.home-page-container_honeycomb-background {
	position: absolute;
	z-index: 1;
	align-items: center;
	background: url('/images/AlixIP-Home-Pattern.svg') no-repeat left top;
	background: linear-gradient(270deg, rgba(255, 255, 255, 0) 30%, #ffffff 100%),
		url('/images/AlixIP-Home-Pattern.svg') no-repeat center right;
	background-size: contain;
	display: flex;
	background-position: center right;
	margin-left: 0;
	margin-right: 0;
	background-size: contain;
	width: 70%;
	height: 100%;
	margin-top: -3%;
}

.home-page-container__services-list-container {
	position: relative;
	top: calc(50% - 225px);
	width: 57.1%;
	z-index: 2;
}

/* Knowledge base section styles */
.home-page-container__knowledge-base-block {
	display: flex;
	justify-content: flex-start;
	margin-top: 40px;
	position: relative;
	width: 100%;
	z-index: 3;
}

.home-page-container__knowledge-base-background-container {
	background-color: var(--color-grey-100);
	height: 437px;
	position: relative;
	width: calc(85.7% + 16px);
}

.home-page-container__knowledge-base-tiles-container {
	display: flex;
	justify-content: flex-end;
	position: absolute;
	right: 0;
	width: calc(85.7% + 32px);
}

.home-page-container__info-block-title {
	font-size: var(--font-size-giant-xxs);
	font-weight: bold;
	letter-spacing: 0.02px;
	line-height: 49px;
	max-width: 431px;
}

.home-page-container__info-block-text {
	line-height: 27px;
	max-width: 432px;
}

.home-page-container__knowledge-base-right .home-page-container__info-block-text,
.home-page-container__knowledge-base-right .home-page-container__info-block-title {
	padding-right: 20px;
}

.home-page-container__knowledge-base-tile {
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	height: 328px;
	margin-left: 32px;
	width: calc(32.33% - 32px);
}

.home-page-container__knowledge-base-tile-bottom {
	background-color: #ffffff;
	height: 50%;
	padding: 20px 15px;
	position: relative;
}

.home-page-container__knowledge-base-tile-benchmarks,
.home-page-container__knowledge-base-tile-commodities,
.home-page-container__knowledge-base-tile-registers {
	align-items: center;
	display: flex;
	height: 50%;
}

.home-page-container__knowledge-base-tile:hover {
	box-shadow: 0 6px 20px 3px rgba(0, 0, 0, 0.4);
}

.home-page-container__knowledge-base-tile-benchmarks {
	background-color: #4d55b3;
}

.home-page-container__knowledge-base-tile-commodities {
	background-color: var(--color-brand);
}

.home-page-container__knowledge-base-tile-registers {
	background-color: #4db3a0;
}

.home-page-container__knowledge-base-left {
	display: flex;
	align-items: center;
	flex: 1 0 calc(58.4%);
}

.home-page-container__knowledge-base-right {
	display: flex;
	flex-direction: column;
	flex: 1 1 41.6%;
	justify-content: flex-start;
	margin-left: 34px;
	margin-top: 80px;
}

.home-page-container__knowledge-tile-header {
	color: var(--color-grey-700);
	font-size: var(--font-size-l);
	font-weight: 800;
	line-height: 27px;
	margin-bottom: 10px;
}

.home-page-container__knowledge-tile-text {
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	font-weight: 400;
	letter-spacing: 0.04px;
	line-height: var(--line-height-s);
}

.home-page-container__knowledge-tile-arrow,
.home-page-container__involved-tile-arrow {
	bottom: 15px;
	right: 15px;
	position: absolute;
}

.home-page-container__arrow {
	background: url('/images/green-arrow.svg') no-repeat;
	cursor: pointer;
	height: 12px;
	width: 14px;
}

.home-page-container__benchmark-logo {
	background: url('/images/benchmark_80px.svg') no-repeat;
	height: 73.3px;
	width: 60px;
}

.home-page-container__commodities-logo {
	background: url('/images/commodities_80px.svg') no-repeat;
	height: 80px;
	width: 80px;
}

.home-page-container__registers-logo {
	background: url('/images/registers_80px.svg') no-repeat;
	height: 75px;
	width: 75px;
}

.home-page-container__benchmark-logo,
.home-page-container__commodities-logo,
.home-page-container__registers-logo {
	background-size: contain;
	margin: 0 auto;
}

/* Build tool styles */
.home-page-container__build-tool-block {
	display: flex;
	margin-top: 70px;
	justify-content: center;
	width: 100%;
}

.home-page-container__build-tool-right-content {
	align-items: center;
	display: flex;
	position: relative;
	height: 510px;
	width: 100%;
}

.home-page-container__build-tool-left,
.home-page-container__build-tool-right {
	align-items: center;
	display: flex;
	flex-direction: row-reverse;
	flex: 1 0 41.6%;
}

.home-page-container__build-tool-right {
	flex: 1 0 58.4%;
	padding-left: 32px;
	position: relative;
}

.home-page-container__build-tool-left-content {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: -10px;
	position: relative;
	text-align: right;
}

.home-page-container__info-block-button .button--primary {
	height: 32px;
	margin-top: 10px;
	padding-top: 7px;
	width: 111px;
}

.home-page-container__build-tool-background-block {
	align-items: center;
	background-color: #f7f7f7;
	display: flex;
	height: 458px;
	position: absolute;
	padding-top: 56.25%;
	right: 0;
	width: 71.4%;
}

.home-page-container__built-tool-container {
	align-items: center;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	display: flex;
	justify-content: center;
	height: 337px;
	padding-left: 30px;
	position: relative;
	top: auto;
	width: calc(85.7% - 32px);
}

.home-page-container__build-tool-schema-block {
	background: url('/images/AlixIP-Home-BuildYourOwn-Process.svg') no-repeat;
	background-size: contain;
	padding-top: 40%;
	width: 100%;
}

/* Involved info block styles */

.home-page-container__involved-block {
	margin-top: 50px;
	width: 100%;
}

.home-page-container__involved-title {
	font-size: var(--font-size-giant-xxs);
	font-weight: bold;
	letter-spacing: 0.02px;
	line-height: 49px;
	text-align: center;
}

.home-page-container__involved-text {
	line-height: 27px;
	margin: 0 auto;
	text-align: center;
	max-width: 664px;
}

.home-page-container__involved-actions-container {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: 40px auto 100px;
	width: 75%;
}

.home-page-container__involved-tile {
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	margin: 0;
	width: calc(33.3% - 16px);
	height: 288px;
}

.home-page-container__involved-tile:nth-child(2) {
	margin: 0 32px;
}

.home-page-container__tell-us-image {
	background: url('/images/bottom-feedback.jpg') no-repeat;
}

.home-page-container__cdo-image {
	background: url('/images/bottom-CDO.jpg') no-repeat;
}

.home-page-container__vote-image {
	background: url('/images/bottom-Idea.jpg') no-repeat;
	background-position-y: -20px;
}

.home-page-container__cdo-image,
.home-page-container__vote-image,
.home-page-container__tell-us-image {
	align-items: center;
	background-size: cover;
	display: flex;
	justify-content: center;
	height: 100%;
	position: absolute;
	width: 100%;
	z-index: 1;
}

.home-page-container__involved-tile--top {
	align-items: center;
	display: flex;
	justify-content: center;
	height: 61%;
	overflow: hidden;
	position: relative;
}

.home-page-container__involved-tile:hover {
	box-shadow: 0 6px 20px 3px rgba(0, 0, 0, 0.4);
}

.home-page-container__involved-tile--bottom {
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 39%;
	padding: 25px 15px 15px;
}

.home-page-container_involved-tile-title {
	color: var(--color-grey-700);
	font-size: var(--font-size-l);
	font-weight: 500;
	letter-spacing: 0.04px;
	line-height: 31px;
}

.home-page-container__cdo-logo {
	background: url('/images/CDO_80px.svg') no-repeat;
	height: 60px;
	width: 60px;
	z-index: 2;
}

.home-page-container__feedback-logo {
	background: url('/images/feedback_80px.svg') no-repeat;
	height: 66.67px;
	width: 66.67px;
	z-index: 2;
}

.home-page-container__idea-logo {
	background: url('/images/idea_80px.svg') no-repeat;
	height: 66.67px;
	width: 60px;
	z-index: 2;
}

.home-page-container__codelibrary-icon {
	background: url('/images/code-icon.svg') no-repeat;
	height: 66.67px;
	transform: scale(1.3);
	width: 60px;
	z-index: 2;
}

.home-page-container__green-text {
	color: var(--color-brand);
}

.home-page-container__codelibrary-logo {
	background: url('/images/python.jpeg') no-repeat;
	height: 100%;
	width: 100%;
	background-size: cover;
	z-index: 1;
	position: absolute;
	filter: brightness(0.5);
}

/* 1440 -> 1599 */
@media (max-width: 1599px) {
	.home-page-container_honeycomb-background {
		background: url('/images/AlixIP-Home-Pattern.svg') no-repeat left top;
		background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
			url('/images/AlixIP-Home-Pattern.svg') no-repeat center right;
		display: flex;
	}

	.home-page-container__top-block-container {
		height: 520px;
	}

	.home-page-container__built-tool-container {
		height: 337px;
	}

	.home-page-container__build-tool-left-content {
		margin-top: -10px;
	}

	.home-page-container__services-list-container {
		width: 55.3%;
	}

	.home-page-container__involved-tile {
		height: 266px;
		width: calc(33.3% - 44px);
	}

	.home-page-container__vote-image {
		background-position-y: 0;
	}

	.home-page-container__services-block-right {
		min-height: 490px;
	}
}

@media (max-width: 1440px) {
	.home-page-container__top-block-logo {
		max-width: 100%;
		max-height: 100%;
		height: 100%;
		margin-top: 3%;
		width: 100%;
	}

	.home-page-container__services-block-right {
		min-height: 505px;
	}
}

/* 1167 - 1365 */
@media (max-width: 1365px) {
	.home-page-container__services-block-right {
		min-height: 520px;
	}

	.home-page-container__services-block-right-content {
		display: flex;
		justify-content: flex-start;
	}

	.home-page-container__knowledge-base-tiles-container {
		width: calc(85.7% + 48px);
		justify-content: flex-start;
	}

	.home-page-container__knowledge-base-tile {
		width: calc(33.3% - 24px);
		margin-left: 0;
	}

	.home-page-container__knowledge-base-tile:nth-child(2) {
		margin: 0 32px;
	}

	.home-page-container__involved-actions-container {
		width: 92%;
	}

	.home-page-container__top-block-logo {
		height: 0;
		padding-bottom: 75%;
		width: 100%;
	}
}

@media (max-width: 1166px) {
	.home-page-container__knowledge-base-block {
		flex-direction: column-reverse;
		margin-top: 10px;
	}

	.home-page-container__knowledge-base-right {
		align-items: center;
		margin-top: 60px;
		margin-left: 0;
		text-align: center;
	}

	.home-page-container__info-block-title {
		font-size: var(--font-size-giant-xxs);
		line-height: 49px;
		height: auto;
		margin-bottom: 12px;
		max-width: 583px;
		width: 100%;
	}

	.home-page-container__info-block-title .home-page-container__green-text {
		display: inline;
		margin-left: 10px;
	}

	.home-page-container__knowledge-base-left {
		align-items: start;
		flex-direction: column;
		flex: 1 1 100%;
		justify-content: center;
	}

	.home-page-container__knowledge-base-background-container {
		height: 232px;
		margin-top: 18%;
		width: 100%;
	}

	.home-page-container__knowledge-base-tiles-container {
		width: calc(79% - 64px);
		justify-content: center;
		left: 32px;
		right: auto;
		margin-left: calc(50% - (79% - 32px) / 2);
	}

	.home-page-container__knowledge-base-tile {
		height: 317px;
		margin: 0;
	}

	.home-page-container__knowledge-base-tile:nth-child(2) {
		margin: 0 32px;
	}

	.home-page-container__build-tool-block {
		flex-direction: column;
	}

	.home-page-container__build-tool-left {
		flex-direction: column;
		flex: 1 1 100%;
		text-align: center;
	}

	.home-page-container__build-tool-left-content {
		align-items: center;
		flex-direction: column;
		margin-top: 20px;
		max-width: 100%;
		width: 100%;
	}

	.home-page-container__info-block-title {
		font-size: var(--font-size-giant-xxs);
		line-height: 49px;
		height: auto;
		margin-bottom: 12px;
		max-width: 583px;
		text-align: center;
	}

	.home-page-container__info-block-text {
		font-size: var(--font-size-m);
		line-height: var(--line-height-m);
		height: auto;
		max-width: 583px;
		text-align: center;
		width: 100%;
	}

	.home-page-container__build-tool-right {
		padding-left: 0;
	}

	.home-page-container__build-tool-right-content {
		height: 445px;
		margin-top: 0;
	}

	.home-page-container__build-tool-background-block {
		width: 100%;
	}

	.home-page-container__built-tool-container {
		margin: 0 auto;
		width: calc(68.3% - 64px);
	}

	.home-page-container__build-tool-background-block {
		height: 232px;
		padding-top: 0;
		width: 100%;
		margin-top: 8%;
	}

	.home-page-container__services-block-right {
		height: auto;
		min-height: 450px;
	}

	.home-page-container__built-tool-container {
		height: 285px;
	}

	.home-page-container__involved-block {
		align-items: center;
		display: flex;
		flex-direction: column;
	}

	.home-page-container__involved-title {
		max-width: 588px;
		text-align: center;
	}

	.home-page-container__involved-text {
		max-width: 582px;
		font-size: var(--font-size-m);
		line-height: var(--line-height-m);
		margin-top: 15px;
	}

	.home-page-container__involved-actions-container {
		width: calc(100% - 64px);
	}

	.home-page-container__involved-tile {
		margin: 0;
		width: calc(33.3% - 16px);
		height: 286px;
	}

	.home-page-container__involved-tile:nth-child(2) {
		margin: 0 32px;
	}

	.home-page-container__cdo-image,
	.home-page-container__vote-image,
	.home-page-container__tell-us-image {
		background-size: cover;
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: 1;
	}

	.home-page-container__involved-tile--top {
		height: 60%;
	}

	.home-page-container__involved-tile--bottom {
		height: 115px;
	}

	.home-page-container__services-block-left {
		flex: 1 1 calc(50% - 32px);
	}

	.home-page-container__services-block-right {
		flex: 1 1 calc(50% + 32px);
	}

	.home-page-container__services-block-left-content {
		margin-top: 40px;
	}

	.home-page-container__services-list-container {
		top: 0;
		width: calc(83.3% - 48px);
	}
}

@media (max-width: 959px) {
	.home-page-container__top-block-container {
		height: auto;
	}

	.home-page-container__top-block {
		flex-direction: column-reverse;
	}

	.home-page-container__top-block-info {
		align-items: center;
		margin-top: 0;
	}

	.home-page-container__top-block-title {
		margin-top: 65px;
		width: 188px;
	}

	.home-page-container__top-block-text {
		font-size: 27px;
		padding-right: 0;
		width: 548px;
	}

	.home-page-container__top-block-logo {
		height: 488px;
		margin-right: 0;
		width: 100%;
	}

	.home-page-container__services-block {
		flex-direction: column;
	}

	.home-page-container__services-block-left {
		flex-direction: column;
		flex: 1 1 100%;
	}

	.home-page-container__services-block-left-content {
		align-items: center;
		max-width: 100%;
		flex-direction: column;
		margin-left: 0;
		width: 100%;
	}

	.home-page-container__services-block-title {
		font-size: var(--font-size-giant-xxs);
		line-height: 49px;
		height: auto;
		margin-bottom: 12px;
		max-width: 583px;
		text-align: center;
	}

	.home-page-container__services-block-text {
		font-size: var(--font-size-m);
		line-height: var(--line-height-m);
		height: auto;
		margin-bottom: 22px;
		max-width: 583px;
		text-align: center;
	}

	.home-page-container__services-list-container {
		margin-top: 3%;
		max-width: 500px;
		width: 70%;
	}

	.home-page-container__services-block-right {
		margin-top: 30px;
		min-height: 460px;
		padding-left: 0;
	}

	.home-page-container__services-block-right-content {
		margin-top: 0;
		width: calc(91.6% - 32px);
	}

	.home-page-container__knowledge-base-tiles-container {
		width: calc(100% - 64px);
		justify-content: center;
		left: 32px;
		margin-left: 0;
		right: auto;
	}

	.home-page-container__built-tool-container {
		margin: 0 auto;
		width: calc(83.3% - 48px);
	}

	.home-page-container__knowledge-base-background-container {
		margin-top: 22%;
	}

	.home-page-container__involved-tile:nth-child(2) {
		margin: 0 24px;
	}

	.home-page-container__build-tool-right-content {
		height: 400px;
	}

	.home-page-container__top-block-logo {
		margin-top: 0;
		padding-bottom: 68px;
	}

	.home-page-container__involved-title {
		max-width: 582px;
	}

	.home-page-container__vote-image {
		background-position: -50px;
	}
}

@media (max-width: 800px) {
	.home-page-container__cdo-image {
		background-position: -29px;
	}

	.home-page-container__vote-image {
		background-position: -70px;
	}

	.home-page-container__tell-us-image {
		background-position: -29px;
	}
}

@media (max-width: 767px) {
	.home-page-container__top-block-text {
		font-size: var(--font-size-m);
		font-weight: 500;
		line-height: var(--line-height-m);
		max-width: 90%;
		text-align: center;
	}

	.home-page-container__top-block-title {
		height: auto;
		margin: 56px 0 40px 0;
		width: auto;
	}

	.home-page-container__top-block-logo-container {
		justify-content: center;
	}

	.home-page-container__top-block-logo {
		height: 0;
		min-width: 410px;
		padding-top: 60%;
	}

	.home-page-container__services-block-left {
		margin-top: 62px;
	}

	.home-page-container__services-block-left-content {
		margin: 0;
	}

	.home-page-container__services-block-right {
		justify-content: center;
		margin: 0;
		min-height: auto;
		padding-left: 0;
	}

	.home-page-container__services-block-right-content {
		height: auto;
		justify-content: center;
		margin-bottom: 56px;
		width: 90%;
	}

	.home-page-container_honeycomb-background {
		display: none;
	}

	.home-page-container__services-list-container {
		margin-top: 24px;
		max-width: 550px;
		position: relative;
		width: 100%;
	}

	.home-page-container__knowledge-base-block {
		margin-top: 0;
	}

	.home-page-container__knowledge-base-right {
		justify-content: center;
		margin: 0;
		padding: 0;
	}

	.home-page-container__knowledge-base-right .home-page-container__info-block-text,
	.home-page-container__knowledge-base-right .home-page-container__info-block-title {
		padding-right: 0;
	}

	.home-page-container__involved-title,
	.home-page-container__info-block-title,
	.home-page-container__services-block-title,
	.home-page-container__info-block-title {
		font-size: 23px;
		font-weight: 500;
		line-height: 31px;
		letter-spacing: 0.3px;
		text-align: center;
		margin-bottom: 16px;
		max-width: 90%;
	}

	.home-page-container__involved-text,
	.home-page-container__services-block-text,
	.home-page-container__info-block-text {
		font-size: var(--font-size-xs);
		letter-spacing: 0.8px;
		line-height: var(--line-height-xs);
		margin: 0 0 32px 0;
		max-width: 90%;
		text-align: center;
	}

	.home-page-container__info-block-title .home-page-container__green-text {
		display: block;
		margin-bottom: 16px;
		margin-left: 0;
	}

	.home-page-container__info-block-text {
		margin-bottom: 40px;
	}

	.home-page-container__info-block-title {
		margin-bottom: 0;
	}

	.home-page-container__build-tool-left-content .home-page-container__info-block-text {
		margin: 16px 0 32px 0;
	}

	.home-page-container__info-block-button .button--primary {
		height: 29px;
		margin-top: 0;
		margin-bottom: 32px;
		padding-top: 5px;
		width: 120px;
	}

	.home-page-container__build-tool-right {
		flex: 1 1 100%;
		margin-bottom: 24px;
	}

	.home-page-container__build-tool-right-content {
		align-items: flex-start;
		height: auto;
	}

	.home-page-container__build-tool-background-block {
		align-self: flex-end;
		height: 0;
		margin: 24px 0 0 0;
		padding-top: 38%;
	}

	.home-page-container__built-tool-container {
		height: 0;
		max-width: 550px;
		padding-top: 45%;
		position: relative;
		width: 90%;
	}

	.home-page-container__build-tool-schema-block {
		position: absolute;
		height: 90%;
		left: 5%;
		padding: 0;
		top: 10%;
		width: 90%;
	}

	.home-page-container__involved-block {
		margin-top: 56px;
	}

	.home-page-container__knowledge-base-tiles-container {
		align-items: center;
		align-self: center;
		flex-direction: column;
		left: 0;
		max-width: 550px;
		position: relative;
		width: 90%;
	}

	.home-page-container__knowledge-base-tile {
		display: flex;
		flex-direction: row;
		height: 88px;
		width: 100%;
	}

	.home-page-container__knowledge-base-tile-benchmarks,
	.home-page-container__knowledge-base-tile-commodities,
	.home-page-container__knowledge-base-tile-registers {
		align-items: center;
		display: flex;
		height: 100%;
		width: 16.66%;
	}

	.home-page-container__knowledge-base-tile-bottom {
		height: 100%;
		padding: 16px 24px;
		width: 83.34%;
	}

	.home-page-container__knowledge-base-tile:nth-child(2) {
		margin: 16px 0;
	}

	.home-page-container__knowledge-base-background-container {
		position: absolute;
		height: 280px;
		margin-top: 24px;
		padding-top: 0;
		width: 100%;
	}

	.home-page-container__benchmark-logo {
		height: 22px;
		width: 18px;
	}

	.home-page-container__commodities-logo {
		height: 14px;
		width: 20px;
	}

	.home-page-container__registers-logo {
		height: 18px;
		width: 18px;
	}

	.home-page-container__knowledge-base-left {
		justify-content: flex-end;
		margin-bottom: 24px;
	}

	.home-page-container__knowledge-tile-header {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		margin-bottom: 4px;
	}

	.home-page-container__knowledge-tile-text {
		font-size: var(--font-size-xxs);
		line-height: var(--line-height-xxs);
	}

	.home-page-container__knowledge-tile-arrow,
	.home-page-container__involved-tile-arrow {
		bottom: 10px;
		right: 12px;
	}

	.home-page-container__involved-actions-container {
		flex-direction: column;
		margin: 0 auto 90px;
		max-width: 550px;
		width: 90%;
	}

	.home-page-container__involved-tile {
		display: flex;
		flex-direction: row;
		height: 72px;
		width: 100%;
	}

	.home-page-container_involved-tile-title {
		font-size: var(--font-size-s);
		font-weight: bold;
		line-height: var(--line-height-s);
		max-width: 85%;
	}

	.home-page-container__involved-tile--bottom {
		height: 100%;
		padding: 16px 16px;
		width: calc(100% - 77px);
	}

	.home-page-container__involved-tile--top {
		height: 100%;
		width: 77px;
	}

	.home-page-container__involved-tile:nth-child(2) {
		margin: 16px 0;
	}

	.home-page-container__involved-tile:first-child
		.home-page-container_involved-tile-title {
		max-width: 70%;
	}

	.home-page-container__cdo-logo {
		background-size: cover;
		height: 18px;
		width: 18px;
	}

	.home-page-container__feedback-logo {
		background-size: cover;
		height: 20px;
		width: 20px;
	}

	.home-page-container__idea-logo {
		background-size: cover;
		height: 20px;
		width: 18px;
	}

	.home-page-container__codelibrary-icon {
		background-size: cover;
		height: 20px;
		width: 18px;
		transform: scale(1.3);
	}

	.home-page-container__vote-image,
	.home-page-container__tell-us-image,
	.home-page-container__cdo-image {
		background-position: -42px;
		width: 107px;
	}

	.home-page-container__tell-us-image {
		background-position: -13px;
	}

	.home-page-container__vote-image {
		background-position: -29px;
	}

	.home-page-container__build-tool-block {
		margin-top: 56px;
	}
}

@media (max-width: 750px) {
	.home-page-container__top-block-title svg {
		height: 40px;
		width: 126.67px;
	}
}
