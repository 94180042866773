.search-page {
	background-color: var(--color-grey-100);
}

.search-container {
	display: flex;
	min-height: calc(100vh - 200px); /*64 px is the height of the nav bar*/
}

.search-container__search-block {
	width: 284px;
	position: relative;
}

.search-container__left {
	flex: 1 0 356px;
	background-color: white;
	border-right: 1px solid var(--color-grey-300);
	padding: 66px 32px 40px 40px;
}

.search-container__filter-input {
	margin-bottom: 40px;
	height: 33px;
}

.search-container__filter-input input {
	padding: 4.5px 8.5px;
}

.search-container__filter-input .search-input__close-button {
	top: 5px;
}

.search-container__right {
	padding: 66px 40px 40px 30px;
	flex: 1 0 calc(100vw - 356px);
	background-color: var(--color-grey-100);
	overflow-x: hidden;
	overflow-y: auto;
}

.search-container__title {
	font-size: var(--font-size-l);
	line-height: var(--line-height-l);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.04px;
	color: var(--color-grey-700);
	margin-bottom: 24px;
}

.search-container__masonry-grid {
	display: flex;
	margin-left: -32px; /* gutter size offset */
	width: auto;
	margin-top: 56px;
	max-width: 1244px;
}

.search-container__masonry-grid-column {
	padding-left: 32px; /* gutter size */
	background-clip: padding-box;
}

.area-filters {
	display: flex;
	flex-wrap: wrap;
}

.current-search {
	display: flex;
	margin-left: 24px;
}

.current-search__message {
	color: var(--color-grey-600);
	font-size: var(--font-size-s);
	letter-spacing: 0.03px;
	line-height: var(--line-height-s);
	max-width: 60vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.current-search_icon {
	transform: translateY(-1.2px);
	margin-left: 8px;
	cursor: pointer;
}

.search-container__filters {
	display: flex;
	flex-direction: column;
}

.search-container__filters .filter-checkbox {
	display: flex;
	align-items: baseline;
	padding: 10px 0;
}

.search-container__filters .checkbox-title {
	color: var(--color-grey-600);
	font-size: var(--font-size-s);
	letter-spacing: 0.03px;
	line-height: var(--line-height-s);
	margin-left: 9px;
	width: 230px;
}

.search-container__filter-button {
	display: none;
	justify-content: flex-start;
	max-width: 1212px;
	margin-right: 32px;
}

.search-sidebar__container {
	display: none;
}

.search-container__search-filter {
	display: flex;
	align-items: center;
	margin-top: 0;
}

.search-container__filter-actions {
	display: flex;
	align-items: center;
}

.sidebar__search-container .search-container__search-block {
	overflow-y: scroll;
	max-height: 100%;
	padding-right: 34px;
	padding-bottom: 200px;
}

.page .homepage-footer {
	z-index: 5;
}

.sidebar__apply-button {
	display: none;
}

.product-intro-text--mobile {
	display: none;
}

.product-intro-text--desktop {
	display: block;
}

.sidebar-mobile {
	display: none;
}

@media (min-width: 481px) and (max-width: 1167px) {
	.search-container__left {
		display: none;
	}

	.search-container__search-block {
		width: 356px;
	}

	.search-sidebar__container {
		display: block;
	}

	.current-search__message {
		max-width: 60vw;
	}
}

@media (min-width: 480px) and (max-width: 1167px) {
	.search-container__filter-button {
		display: flex;
		margin-left: auto;
	}

	.search-container__search-filter {
		margin-bottom: 12px;
	}

	.sidebar-mobile {
		display: none;
	}
}

@media (max-width: 480px) {
	.search-container__search-block {
		width: 100%;
		position: static;
	}

	/* Hide close buton for mobile */
	.search-container__search-block .search-input {
		display: none;
	}

	.search-container__sections-wrapper {
		flex: 1;
		overflow-y: scroll;
	}

	.search-container__search-block {
		display: flex;
		flex-direction: column;
		height: 100%;
		min-height: 100%;
		padding: 16px;
	}

	.product-intro-text {
		width: calc(100vw - 130px);
	}

	.search-container__right {
		padding: 0;
	}

	.search-container__title {
		color: var(--color-grey-700);
		font-size: var(--font-size-s);
		font-weight: bold;
		letter-spacing: 0.3px;
		line-height: var(--line-height-s);
	}

	.filter-actions .search-container__title {
		border-bottom: 1px solid var(--color-grey-200);
	}

	.search-container__filter-button {
		display: flex;
		justify-content: flex-start;
		margin-right: auto;
	}

	.sidebar {
		display: none;
	}

	.sidebar-mobile {
		position: fixed;
		z-index: 1000;
		background-color: white;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		display: block;
	}

	.hide-for-mobile {
		display: none;
	}

	.area-filters {
		flex-wrap: nowrap;
		-ms-overflow-style: none;
		scrollbar-width: none;
		overflow-x: scroll;
		overflow-y: hidden;
		width: 90vw;
		padding-top: 8px;
	}

	.area-filters::-webkit-scrollbar {
		display: none;
	}

	.filter-tablet {
		margin-bottom: 0;
		max-height: 32px;
		margin-right: 8px;
		white-space: nowrap;
	}

	.filter-actions {
		position: sticky;
		top: 9px;
		z-index: 1;
		background-color: var(--color-grey-100);
		padding: 16px 16px;
	}

	.search-container__right {
		overflow-x: unset;
		overflow-y: unset;
	}

	.search-container__masonry-grid {
		margin-top: 26px;
	}

	.search {
		padding: 0 16px;
	}

	.search-container__search-filter {
		flex-direction: column-reverse;
		justify-content: flex-start;
		align-items: flex-start;
		margin-top: 0;
	}

	.search-container__filter-actions {
		width: 100%;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.search-sidebar__container {
		display: flex;
	}

	/* Mobile sidebar */

	.search-container__search-block .search-container__title {
		color: var(--color-grey-700);
		font-size: var(--font-size-l);
		font-weight: var(--font-weight-medium);
		letter-spacing: 0.04px;
		line-height: var(--line-height-l);
	}

	.search-container__sections-wrapper::-webkit-scrollbar {
		display: none;
	}

	.filter-actions .search-container__title {
		padding-bottom: 16px;
		margin-bottom: 16px;
	}

	.sidebar__apply-button {
		display: flex;
		justify-content: center;
		width: 100%;
		padding: 12px 0;
	}

	.sidebar__apply-button button {
		width: 100%;
	}

	.sidebar__overlay {
		display: none;
	}

	.product-intro-text--mobile {
		display: block;
	}

	.product-intro-text--desktop {
		display: none;
	}

	/* Product item from masonry */

	.product {
		flex-direction: row;
		height: 88px;
		margin-bottom: 16px;
	}

	.product .product-image {
		display: none;
	}

	.product .product-header {
		height: 88px;
		min-width: 48px;
		padding-right: 0;
		justify-content: center;
		align-items: flex-start;
	}

	.product .product-header__icon {
		padding-top: 6px;
	}

	.product-intro-text {
		font-size: var(--font-size-xxs);
	}

	.product .product-title {
		font-size: var(--font-size-s);
		font-weight: bold;
		letter-spacing: 0.3px;
		margin-bottom: 0;
		line-height: var(--line-height-s);
	}

	.product-details__arrow {
		display: block;
	}

	.product-details {
		padding-top: 12px;
	}

	.product svg {
		width: 32px;
		height: 25px;
	}

	.sidebar__close-button-container {
		display: none;
	}

	body {
		overflow: unset !important;
	}
}

@media (max-width: 1167px) {
	.search-container__left {
		display: none;
	}
}

@media all and (min-width: 1600px) {
	.search-container__right {
		flex: 1 0 calc(1600px - 356px);
	}
	.current-search__message {
		max-width: 49vw;
	}
}
