.grid-select-add-button {
	position: relative;
	width: 32%;
	min-height: 144px;
	margin-bottom: 32px;
	padding: 24px 32px;
	border: 1px solid transparent;
	display: flex;
	align-items: center;
	justify-content: center;
  background-color: var(--color-brand-light);
  color: var(--color-brand);
	cursor: pointer;
  outline: none;
  transition: all .3s ease-out;
}

.grid-select-add-button:hover {
  border: 1px solid var(--color-brand-dark);
	color: var(--color-brand-dark);
}
