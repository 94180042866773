.sidebar {
	background-color: white;
	bottom: 0;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
	height: 100%;
	left: 0;
	position: fixed;
	right: auto;
	top: 64px;
	width: 356px;
	z-index: 3;
}

.sidebar--closed {
	margin-right: -1000px;
	display: none;
}

.sidebar--opened {
	margin-right: 0;
	display: flex;
}

.sidebar__close-button {
	position: absolute;
	top: 16px;
	right: 20px;
}

.sidebar__close-button:hover {
	color: var(--color-danger);
	cursor: pointer;
}

.sidebar__search-container {
	overflow: hidden;
	padding-top: 66px;
	padding-left: 24px;
	width: 100%;
}

.sidebar__overlay {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: grey;
	z-index: 2;
	overflow: hidden;
	opacity: 0.5;
}
