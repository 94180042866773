.latest-update__section {
	margin-top: 48px;
	padding: 32px 16px 18px 16px;
	background-color: var(--color-grey-section-background);
}

.latest-update__section-content {
	display: flex;
	flex-direction: column;
}

.latest-update__section-details {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 24px;
}

.latest-update__section-details > * {
	flex: 1 1 160px;
}

.latest-update__section-detail {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	max-width: 200px;
}
.latest-update__section-detail:first-child {
	max-width: 100%;
	padding-right: 16px;
	white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.latest-update__section-detail-label {
	margin-bottom: 8px;
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	letter-spacing: 0.8px;
	color: var(--color-grey-500);
	text-transform: uppercase;
}

.latest-update__section-detail-value {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	color: var(--color-grey-800);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.latest-update__section-detail-value::first-letter {
	text-transform: capitalize;
}

.latest-update__section-description {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	color: var(--color-grey-800);
	white-space: pre-wrap;
	word-wrap: break-word;
}

.latest-update__section-description--none {
	color: var(--color-grey-300);
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.latest-update__section {
		padding: 32px 40px 88px 40px;
		margin-top: 80px;
	}

	.latest-update__section-description {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		letter-spacing: 0.3px;
	}

	.latest-update__section-detail-value {
		font-size: var(--font-size-l);
		line-height: var(--line-height-l);
		font-weight: var(--font-weight-medium);
	}

	.latest-update__section-details {
		margin-bottom: 32px;
	}
}
