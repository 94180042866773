.admin {
	display: flex;
	flex-direction: column;
}

.admin__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 64px;
	padding: 0 40px;
	background-color: var(--color-grey-700);
}

.admin__logo-link {
	display: flex;
	align-self: stretch;
	align-items: center;
}

.admin .admin__navbar {
	display: inline-flex;
	position: relative;
}

.admin__navbar-item {
	display: inline-flex;
	align-items: center;
	color: var(--color-grey-400);
}

.admin__navbar-item + .admin__navbar-item {
	margin-left: 26px;
}

.admin__navbar-link {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
	outline: 0;
	height: 64px;
	display: inline-flex;
	align-items: center;
	padding: 0 10px;
	transition-property: color;
	transition-duration: 333ms;
	transition-timing-function: var(--timing-function-ease-out-expo);
}

.admin__navbar-link:focus,
.admin__navbar-link:hover {
	color: white;
}

.admin__navbar-link.active {
	color: white;
}

.admin__navbar-link + .admin__navbar-link {
	margin-left: 16px;
	margin-right: 24px;
}

.admin__icon-button {
	appearance: none;
	outline: 0;
	border: 0;
	padding: 0;
	transition-property: color;
	transition-duration: 333ms;
	transition-timing-function: var(--timing-function-ease-out-expo);
}

.admin__icon-button:focus,
.admin__icon-button:hover {
	color: white;
}

.admin__icon-button + .admin__icon-button {
	padding-left: 18px;
}

.admin__icon-button svg {
	display: flex;
}

.admin__main {
	flex: 1;
	display: flex;
	flex-direction: row;
	background-color: var(--color-grey-100);
	position: relative;
}

.admin__subnav-panel {
	width: 25vw;
	background: white;
	box-shadow: 7px 0px 15px 0px rgba(0, 0, 0, 0.05);
	z-index: 2;
}

.admin__tab-panel {
	width: 75vw;
	position: relative;
	overflow-x: hidden;
}

.admin__subnav {
	position: sticky;
	top: 0;
	padding-top: 40px;
}

.admin__subnav-link {
	display: flex;
	align-items: center;
	padding: 12px 16px 12px 28px;
	border-left: 4px solid transparent;
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	user-select: none;
	outline: none;
	transition-property: border-left-color;
	transition-duration: 333ms;
	transition-timing-function: var(--timing-function-ease-out-expo);
}

.admin__subnav-link--sub::before {
	content: '';
	width: 20px;
	height: 1px;
	margin-right: 16px;
	background-color: var(--color-grey-300);
	transition-property: background-color;
	transition-duration: 333ms;
	transition-timing-function: var(--timing-function-ease-out-expo);
}

.admin__subnav-link.active {
	border-left-color: var(--color-brand);
	background-color: var(--color-brand-lightest);
}

.admin__subnav-link--sub.active::before {
	background-color: var(--color-brand);
}

.admin__subnav-link:hover,
.admin__subnav-link:focus {
	background-color: var(--color-brand-lightest);
}
