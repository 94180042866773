.modal {
	position: relative;
	z-index: 0;
}

.modal__overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
	animation: fadeIn 666ms var(--timing-function-ease-out-expo);
}

.modal__wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	animation: modalScaleIn 666ms var(--timing-function-ease-out-expo);
}

.modal__wrapper > * {
	pointer-events: auto;
}
