.product-grid-select-item {
	position: relative;
	width: 32%;
	min-height: 143px;
	margin-bottom: 32px;
	padding: 40px 32px 16px;
	border: 1px solid var(--color-grey-300);
	transition: all 0.3s ease-out;
}

.product-grid-select-item:hover {
	border-color: var(--color-grey-400);
	cursor: pointer;
}

.product-grid-select-item__contents {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 50px;
}

.product-grid-select-item__title {
	font-size: 20px;
	font-weight: 500;
	max-height: 60px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	color: var(--color-grey-800);
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-regular);
}

.product-grid-select-item__menu {
	position: absolute;
	right: 15px;
	top: -2px;
}

.product-grid-select-item__draft-label,
.product-grid-select-item__live-label {
	margin-right: auto;
}

.product-grid-select-item__draft-label,
.product-grid-select-item__published-label,
.product-grid-select-item__live-label {
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.8px;
}

.product-grid-select-item__draft-label,
.product-grid-select-item__published-label {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	text-align: left;
	font-weight: var(--font-weight-regular);
}

.product-grid-select-item__published-label {
	text-align: right;
}

.product-grid-select-item__footer {
	border-top: 1px solid var(--color-grey-200);
	min-height: 36px;
	padding-top: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
}
