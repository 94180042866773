.area-stats-editor {
	display: flex;
	justify-content: center;
	align-items: center;
  margin-left: 32px;
}

.area-stats-editor__stat,
.area-stats-editor__add-stat {
	opacity: 0;
	animation: fadeIn 300ms forwards;
	outline: 1px solid var(--color-grey-600);
	transition: all 300ms ease-in;
	height: 120px;
	width: 160px;
  position: relative;
  display: flex;
	flex-direction: column;
	justify-content: flex-end;
  align-items: center;
  padding: 0 8px 14px 8px;
}

.area-stats-editor__add-stat {
  justify-content: center;
  padding-bottom: 0;
}

.area-stats-editor__add-stat:hover {
  color: var(--color-brand);
}

.area-stats-editor__delete-stat {
  position: absolute;
  top: 4px;
  right: 4px;
}

.area-stats-editor__delete-stat:hover {
  color: var(--color-danger-dark);
}

.area-stats-editor__stat + .area-stats-editor__stat,
.area-stats-editor__stat + .area-stats-editor__add-stat {
	margin-left: 30px;
}

.area-stats-editor__stat:hover {
	border-color: var(--color-grey-400);
}

.area-stats-editor__stat.selected {
	outline: 2px solid var(--color-brand);
}


.area-stats-editor__stat-label {
	color: white;
	font-size: var(--font-size-xs);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
  text-align: center;
  max-width: calc(100% - 16px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 16px;
}

.area-stats-editor__stat-value {
	color: white;
	letter-spacing: 0.3px;
	font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  min-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
  text-align: center;
}

.area-stats-editor__no-stat {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  letter-spacing: 0.3px;
  color: var(--color-grey-500);
  padding-bottom: 24px;
}