.editor-config-options__section{
	margin-bottom: 40px;
}

.editor-config-options__list-item {
	width: 100%;
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-medium);
	line-height: var(--font-size-xs); /*line height is same as font height*/
	letter-spacing: 0.3px;
	transition-property: color;
  transition-duration: 300ms;
	cursor: pointer;
	text-transform: uppercase;
}

.editor-config-options__list-item:hover {
	color: var(--color-brand-dark);
}

.editor-config-options__list-item + .editor-config-options__list-item {
	margin-top: 27px;
}
