.when-to-use {
	margin-top: 35px;
	padding: 0 40px;
}

.when-to-use__text {
	color: var(--color-grey-800);
	font-size: var(--font-size-s);
	font-weight: 300;
	letter-spacing: 0.3px;
	line-height: var(--line-height-s);
	max-width: 67%;
}

@media (max-width: 1100px) {
	.when-to-use__text {
		max-width: 100%;
	}
}

@media (max-width: 960px) {
	.when-to-use .page-section-title {
		margin-bottom: 16px;
	}
}

@media (max-width: 767px) {
	.when-to-use {
		margin-top: 3px; /*Feature section has 45px spacing - So section gap must be 48px*/
		padding: 0 16px;
	}

	.when-to-use .page-section-title {
		font-size: var(--font-size-m);
		font-weight: bold;
		line-height: var(--line-height-m);
	}

	.when-to-use__text {
		font-size: var(--font-size-xs);
		letter-spacing: 0.8px;
		line-height: var(--line-height-xs);
	}
}
