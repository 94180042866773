.areas-list {
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	margin: 0 auto;
	opacity: 0;
	animation: fadeIn 1000ms var(--timing-function-ease-out-expo) 1 forwards;
	padding: 40px;
}

.areas-list__search {
	border: 1px solid var(--color-grey-300);
	background-color: white;
	width: 100%;
	height: 48px;
	line-height: 48px;
	padding: 5px 18px;
}

.areas-list__navbar-button {
	color: var(--color-grey-400);
	font-weight: var(--font-weight-medium);
	cursor: pointer;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	text-transform: none;
	letter-spacing: 0.1px;
}

.areas-list__navbar-button:focus {
	box-shadow: unset;
}

.active .areas-list__navbar-button {
	color: white;
	font-weight: var(--font-weight-bold);
}

.areas-list__navbar-separator--last {
	margin: 3px 32px 0 16px;
}

.areas-list__navbar-separator {
	margin: 3px 15px 0;
}

.areas-list__navbar-separator--first {
	margin: 3px 15px 0 18px;
}
