.breadcrumb__list {
	padding: 16px 16px 16px 0px;
	margin-left: -3px;
	list-style: none;
}

.breadcrumb__list-item {
	display: inline;
	font-size: var(--font-size-s);
	font-weight: var(--font-weight-medium);
	color: var(--color-grey-500);
}

.breadcrumb__list-item a {
	letter-spacing: 0.3px;
	line-height: var(--line-height-s);
}

.breadcrumb__list-item + .breadcrumb__list-item:before {
	padding: 8px;
	content: '/\00a0';
}

.breadcrumb__list-item:last-of-type,
.breadcrumb__list-item:last-of-type::before {
	color: white;
}
