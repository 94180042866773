.select {
	position: relative;
}

.select__hidden-select {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	opacity: 0;
	z-index: 1;
	border: 0;
	outline: 0;
}

.select__label {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 6px 11px;
	border-radius: 1px;
	transition: all 333ms var(--timing-function-ease-out-expo);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-regular);
	color: var(--color-grey-800);
	border: 1px solid var(--color-grey-300);
	background-color: white; 
}

.select--theme-admin .select__label {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-regular);
	color: var(--color-grey-700);
	border: 1px solid var(--color-grey-300);
	background-color: white;
}

.select__label::after {
	content: '';
	margin-left: 5px;
	display: inline-block;
	transition: all 333ms var(--timing-function-ease-out-expo);
	color: var(--color-grey-300);
	/* The code below is generated from polished triangle mixin */
	width: 0;
	height: 0;
	border-style: solid;
	border-top-color: currentColor;
	border-right-color: transparent;
	border-bottom-color: transparent;
	border-left-color: transparent;
	border-width: 5px 5px 0 5px;
}

.select--theme-admin .select__label::after {
	border-width: 6px 6px 0 6px;
}

.select__hidden-select:hover + .select__label,
.select__hidden-select:focus + .select__label {
	border-color: var(--color-grey-400);
}

.select__hidden-select:hover + .select__label::after,
.select__hidden-select:focus + .select__label::after {
	color: var(--color-grey-400);
}
