.product {
	display: flex;
	flex-direction: column;
	border: none;
	outline: none;
	border-radius: 1px;
	background-color: white;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	opacity: 0;
	/* Do not use shorthands for ipad support */
	animation-name: fadeInUp;
	animation-timing-function: ease;
	animation-delay: 1s;
	transform-origin: 50% 50%;
	animation-fill-mode: forwards;
	cursor: pointer;
}

.product:last-child {
	margin-bottom: 0;
}

.product:hover {
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.product-details {
	padding: 16px 23px 16px 17px;
}

.product-title {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-black);
	letter-spacing: 0.04px;
	color: var(--color-grey-700);
	margin-bottom: 6px;
	text-transform: uppercase;
}

.product-intro-text {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-light);
	letter-spacing: 0.015em;
	color: var(--color-grey-700);
}

.product-image {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid var(--color-grey-200);
	position: relative;
}

.product-image .video-preview__button-icon {
	margin-top: -10px;
}

.product-image img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.product-header {
	height: 50px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 16px;
}

.product-header--none {
	background-color: var(--color-grey-600);
}

.product-header--grey {
	background-color: var(--color-grey-600);
}

.product-header--teal {
	background-color: #4d55b3;
}

.product-header--blue {
	background-color: #4db3a0;
}

.product-header--green {
	background-color: var(--color-brand);
}

.product-when-to-use-label {
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	letter-spacing: 0.8px;
	line-height: var(--line-height-s);
	text-transform: uppercase;
	margin-bottom: 8px;
	margin-top: 16px;
}

.product-details__arrow {
	position: absolute;
	display: none;
	right: 5px;
	top: 25px;
}

.product-header__icon {
	height: 100%;
	display: flex;
	align-items: center;
}
