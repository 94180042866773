.edit-products-feature-section__hero-fieldset {
	display: flex;
	padding: 0;
	outline: none;
	border: none;
	margin: 0 0 24px 0;
}

.edit-products-feature-section__cover-image {
	display: flex;
}

.edit-products-feature-section__cover-image-preview img {
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.edit-products-feature-section__cover-image-label {
	margin-bottom: 8px;
	display: inline-block;
}

.edit-products-feature-section__cover-image-preview {
	height: 140px;
	width: 50%;
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	background-color: var(--color-grey-700);
	position: relative;
}

.edit-products-feature-section__cover-image-picker {
	width: 50%;
	padding: 32px 32px 32px 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.edit-products-feature-section__cover-image-picker .file-picker {
	margin-top: 0;
}

.edit-products-feature-section__cover-image-default-text {
	width: 50%;
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	text-align: center;
	margin-top: 8px;
}

.edit-products-feature-section__button-use-default {
	color: var(--color-grey-700);
	font-size: var(--font-size-xs);
	letter-spacing: 0.3px;
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
	margin-top: 19px;
}
