.searchable-select {
	background-color: white;
}

.searchable-select__wrapper {
	position: relative;
	margin-top: 16px;
}

.searchable-select__placeholder {
	position: absolute;
	top: 4.5px;
	left: 8.5px;
	margin: 0;
	color: var(--color-grey-800);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	z-index: 0;
}

.searchable-select .searchable-select__menu {
	z-index: 99999;
	margin-top: 0;
	border-radius: 0;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.searchable-select .searchable-select__group-heading {
	padding: 10px 16px;
	background-color: var(--color-brand-lightest);
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	margin-bottom: 8px;
	text-transform: capitalize;
}
.searchable-select .searchable-select__option {
	padding: 8px 16px;
	color: var(--color-grey-500);
	font-size: var(--font-size-s);
	letter-spacing: 0.3px;
	line-height: var(--line-height-s);
}

.searchable-select .searchable-select__menu-list {
	padding-top: 0;
}

.searchable-select .searchable-select__group {
	padding-top: 0;
}

.searchable-select .searchable-select__multi-value {
	display: none;
}

.searchable-select .searchable-select__control {
	height: 32px;
	min-height: 32px;
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	transition-property: border-color;
	transition-duration: 333ms;
	background-color: transparent;
	z-index: 1;
}
.searchable-select .searchable-select__control:hover,
.searchable-select .searchable-select__control--is-focused {
	border-color: var(--color-grey-400);
	box-shadow: none;
}

.searchable-select__value-container {
	padding: 1.5px 8px;
}

.searchable-select__indicator-separator {
	display: none;
}

.searchable-select .searchable-select__dropdown-indicator {
	padding: 0 8px;
	color: var(--color-grey-400);
}

.searchable-select__dropdown-indicator .arrow-icon {
	visibility: visible;
	opacity: 1;
	position: absolute;
	top: 3.5px;
	right: 4.5px;
	transition: visibility opacity 666ms var(--timing-function-ease-out-expo);
}

.searchable-select__dropdown-indicator .search-icon {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	top: 7.5px;
	right: 4.5px;
	transition: visibility opacity 666ms var(--timing-function-ease-out-expo);
}

.searchable-select__control--menu-is-open
	.searchable-select__dropdown-indicator
	.arrow-icon {
	visibility: hidden;
	opacity: 0;
}

.searchable-select__control--menu-is-open .search-icon {
	visibility: visible;
	opacity: 1;
}
