.product-person {
	display: inline-flex;
	padding: 0;
	height: 160px;
	width: 100%;
	background-color: var(--color-grey-700);
	color: white;
}

.product-person__info {
	width: 100%;
	position: relative;
	padding: 12px 16px 16px 24px;
}

.product-person__image ~ .product-person__info {
	margin-left: 0;
}

.product-person__image {
	color: var(--color-grey-400);
	height: 160px;
	width: 160px;
	min-width: 160px;
	text-align: center;
	display: inline-block;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
}

.product-person__image img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.product-person__name {
	margin-top: 8px;
	color: white;
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.39px;
	line-height: var(--line-height-m);
	max-height: 57px;
	min-height: 57px;
}

.product-person__email,
.product-person__phone,
.product-person__title {
	color: white;
	font-size: var(--font-size-xs);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
	display: block;
}

.product-person__title {
	color: var(--color-grey-300);
}

.product-person__phone {
	margin-top: 8px;
}

.product-person__email {
	margin-top: 2px;
	transition-property: color;
	transition-duration: 300ms;
}

.product-person__email a {
	display: inline-block;
	text-decoration: underline;
	max-width: 232px;
	word-break: break-all;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.product-person__email:hover {
	color: var(--color-brand);
}

/*Light theme for content editor*/

.product-person.light {
	min-height: 140px;
  height: 140px;
	outline: 2px solid var(--color-grey-100);
	transition: all 0.3s var(--timing-function-ease-out-expo);
}

.product-person.light:hover,  .product-person.light.selected{
	outline-color: var(--color-brand);
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
}

.light .product-person__name {
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	min-height: 48px;
	max-height: 48px;
	max-width: 292px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 0;
}

.light .product-person__image{
	min-width: 140px;
	width: 140px;
	height: 140px;
}

.light .product-person__email, .light .product-person__phone {
	margin-top: 0;
}