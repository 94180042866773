.product-page-editor {
	min-height: 100%;
	padding-top: 64px;
}

.product-page-editor__back-button {
	display: inline-flex;
	align-items: center;
}

.product-page-editor__actions {
	display: flex;
	align-items: center;
}

.product-page-editor__publish-button {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xxs);
	letter-spacing: 0.3px;
}
