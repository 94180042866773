.area-detail-section {
	position: relative;
	background-color: var(--color-grey-700);
	border: 4px solid var(--color-grey-700);
	min-height: 240px;
	padding: 48px 32px 40px 40px;
	cursor: pointer;
	transition: border-color;
	transition-duration: 300ms;
	width: calc(100% + 72px);
	margin-left: -40px;
	margin-top: -40px;
}

.area-detail-section.selected {
	border-color: var(--color-brand);
}

.area-detail-section__row {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.area-detail-section__left {
	display: flex;
	flex-direction: column;
	width: 45%;
	max-width: 45%;
}

.area-detail-section__no-name-block,
.area-detail-section__no-intro-text-block {
	height: 80px;
	width: 316px;
	border: 1px solid var(--color-grey-600);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-500);
}

.area-detail-section__no-name-block {
	height: 40px;
	width: 250px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	margin-bottom: 39px;
	transition-property: border;
	transition-duration: 300ms;
}

.area-detail-section__name {
	margin-bottom: 39px;
	color: white;
	font-size: var(--font-size-xxl);
	line-height: var(--line-height-xxl);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	transition-property: border;
	transition-duration: 300ms;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 320px;
	overflow: hidden;
}

.area-detail-section__intro-text {
	color: white;
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	min-height: 80px;
	width: 100%;
	max-width: 100%;
	outline: 1px solid var(--color-grey-600);
	padding: 12px;
	transition-property: outline;
	transition-duration: 300ms;
}

.area-detail-section__intro-text.selected,
.area-detail-section__no-intro-text-block.selected,
.area-detail-section__no-name-block.selected {
	outline: 2px solid var(--color-brand);
}
