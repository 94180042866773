.experts-grid {
  padding-top: 32px;
  display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-auto-rows: 140px; 
}

.experts-grid__add-button {
  width: 100%;
  min-height: 140px;
  height: 140px;
  margin: 0;
  outline: 2px solid var(--color-brand-light);
  transition: all 0.3s var(--timing-function-ease-out-expo);
}

.experts-grid__add-button:hover, .experts-grid__add-button--selected{
  outline-color: var(--color-brand);
  border: none;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
}

.experts-grid__add-button--disabled {
  background-color: var(--color-grey-100);
  color: var(--color-grey-300);
  outline: none;
  border: none;
  box-shadow: none;
  pointer-events: none;
}