.editor-header {
	height: 64px;
	width: 100%;
	padding: 0 40px;
	background-color: var(--color-grey-100);
	border-bottom: 1px solid var(--color-grey-300);
	position: fixed;
	top: 0;
	z-index: 1;
}

.editor-header__inner {
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
}

.editor-header__logo {
	display: flex;
	align-items: center;
}

.editor-header__logo-separator {
	width: 1px;
	height: 24px;
  background-color: var(--color-grey-300);
  margin-left: 12px;
}
