.label-with-count{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-with-count__value {
  color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
  line-height: var(--line-height-xxs);
  margin-left: 8px;
}

.label-with-count + input, .label-with-count + textarea {
  margin-top: 8px;
}