.tab__header {
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--color-grey-200);
	padding-bottom: 40px;
	padding: 24px 40px;
}

.tab__title {
	font-size: var(--font-size-xl);
	line-height: var(--line-height-xl);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
}

.tab__content {
	padding: 20px 40px;
	min-height: 250px;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: calc(100vh - 150px);
}

.tab__section + .tab__section {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid var(--color-grey-200);
}

.tab__section-title {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-medium);
	padding-bottom: 10px;
	color: var(--color-grey-400);
}

.tab__fieldset-row {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 32px;
	grid-row-gap: 32px;
}

.tab__fieldset-row .tab__fieldset + .tab__fieldset {
	margin-top: 0;	
}

.tab__fieldset {
	border: 0;
	padding: 0;
	margin: 0;
}

.tab__fieldset + .tab__fieldset {
	margin-top: 24px;
}

.tab__fieldset-title {
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-medium);
	color: var(--color-grey-800);
	display: block;
	margin-bottom: 10px;
}

.tab__input {
	outline: none;
	width: 100%;
	padding: 6px 11px;
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-regular);
	color: var(--color-grey-700);
	border: 1px solid var(--color-grey-300);
}

.tab__input:hover,
.tab__input:focus {
	border-color: var(--color-grey-400);
}

.tab__textarea {
	padding: 16px;
	border: 1px solid var(--color-grey-300);
	border-radius: 2px;
	outline: 0;
	background-color: white;
	min-width: 100%;
	width: 100%;
	min-height: 180px;
}

.tab__validation-error {
	display: block;
	padding: 10px 0 0;
	color: var(--color-danger);
}

.tab__actions {
	display: inline-flex;
	align-items: center;
	margin-left: auto;
}

.tab__actions button + button {
	margin-left: 16px;
}
