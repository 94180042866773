.grid-select-menu__button {
	display: block;
	width: 100%;
	padding: 14px;
	margin: 0;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	text-align: left;
	font-size: 14px;
	font-size: var(--font-size-sm);
}

.grid-select-menu__more-icon {
	padding: 10px 20px 0 0;
	height: 40px;
}