.legacy-overlay {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: white;
	z-index: 10000;
	overflow: hidden;
}

.legacy-overlay + .page,
.legacy-overlay + .home {
	display: none;
}

.legacy-overlay__logo {
	color: black;
	padding-right: 5px;
}

.legacy-overlay__copy {
	color: var(--color-grey-500);
	font-size: 19px;
	letter-spacing: 0.36px;
	line-height: 24px;
}

.legacy-overlay__copy--highlighted {
	color: var(--color-grey-700);
	font-weight: var(--font-weight-medium);
}

.legacy-overlay__header {
	display: flex;
	align-items: center;
	padding: 56px 0 73px 0;
}

.legacy-overlay__image {
	background-image: url(/images/legacy-browser-overlay.jpg);
	width: 627px;
	height: 271px;
	background-repeat: no-repeat;
	background-size: contain;
}

.legacy-overlay__copy-url {
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	line-height: 19px;
	padding-top: 35px;
	text-decoration: underline;
	display: block;
}
