.admin-person-tab .editor-page-fieldset label {
	text-transform: none;
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-medium);
	color: var(--color-grey-800);
}

.admin-person-tab .products-select-list,
.admin-person-tab .areas-select-list {
  margin-top: 24px;
}
