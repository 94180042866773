:root {
	--color-brand: #5cb335;
	--color-brand-dark: #488929;
	--color-brand-darkest: #468b36;
	--color-brand-light: #eff8ed;
	--color-brand-lightest: #f6fbf5;
	--color-danger: #d9625e;
	--color-danger-dark: #b54a46;
	--color-grey-100: #f7f7f7;
	--color-grey-150: #f2f2f2;
	--color-grey-200: #e6e6e6;
	--color-grey-300: #cccccc;
	--color-grey-400: #999999;
	--color-grey-500: #737373;
	--color-grey-600: #4d4d4d;
	--color-grey-700: #333333;
	--color-grey-800: #1a1a1a;

	--color-black: #000000;
	--color-white: #ffffff;

	--color-grey-section-background: #f0f0f0;
}
