.product-detail {
	margin-top: 64px;
	padding: 0 40px 0;
	color: white;
	position: relative;
	background: black;
	height: auto;
	background-image: url(/images/product-detail-bg.jpg);
	background-position: top;
	background-repeat: no-repeat;
}

.product-detail--with-hero-feature {
	margin-bottom: 32px;
}

.product-detail__title {
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height-m);
	letter-spacing: 0.3px;
	margin-top: 24px;
	text-transform: uppercase;
}

.product-detail__info,
.product-detail__version {
	color: var(--color-grey-300);
	font-size: var(--font-size-s);
	font-weight: 300;
	line-height: var(--line-height-s);
	letter-spacing: 0.8px;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.product-detail__info {
	white-space: unset;
}

.product-detail__version {
	margin-top: 8px;
	font-weight: var(--font-weight-bold);
}

.product-detail__info {
	color: var(--color-grey-200);
	margin: 32px 32px 0 0;
}

.product-detail__hero-image {
	height: 162x;
	width: 100%;
	text-align: center;
	position: relative;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.product-detail__hero-image img {
	display: inline-block;
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
	align-self: center;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	transform: translate(-50%, -50%);
	position: absolute;
	top: 50%;
	left: 50%;
}

.product-detail__hero-image video {
	outline: none;
}

.product-detail__row {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.product-detail__left {
	width: 100%;
}

.product-detail__right {
	margin: 25px 0;
	min-width: 50%;
}

.product-detail__row-expanded {
	width: 67%;
}

.product-detail__read-more {
	cursor: pointer;
	text-transform: uppercase;
	text-decoration: underline;
	font-weight: var(--font-weight-bold);
	font-size: var(--font-size-xs);
}

.product-detail__product-button-url button {
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.37px;
	padding: 0 32px;
	height: 48px;
	margin-bottom: 32px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.area-detail__read-more {
	cursor: pointer;
	text-transform: uppercase;
	text-decoration: underline;
	font-weight: var(--font-weight-bold);
}

@media (max-width: 900px) {
	.product-detail__right {
		padding-left: 32px;
	}
}

@media (max-width: 767px) {
	.product-detail {
		padding: 0 16px 0;
	}

	.product-detail__product-button-url button {
		height: 48px;
		margin-bottom: 0;
		margin-top: 32px;
		padding: 0 16px;
		width: 100%;
	}

	.product-detail__hero-image {
		height: 190px;
		width: 100%;
	}

	.product-detail__right {
		min-width: 100%;
		padding-left: 0;
	}
}

@media (min-width: 768px) {
	.product-detail__title {
		font-size: var(--font-size-xl);
		font-weight: var(--font-weight-medium);
		line-height: var(--line-height-xl);
	}

	.product-detail__version {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		letter-spacing: 0.8px;
	}

	.product-detail {
		background-size: cover;
	}

	.product-detail__hero-image {
		height: 245px;
		width: 100%;
	}

	.product-detail__product-button-url button {
		margin-top: 32px;
	}

	.product-detail__row {
		flex-direction: row;
	}
}

@media (min-width: 960px) {
	.product-detail {
		height: auto;
		min-height: auto;
		background-position: center;
	}

	.product-detail__row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.product-detail__left {
		width: 42%;
	}

	.product-detail__right {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 58%;
		margin: 0;
	}

	.product-detail__hero-image {
		height: 245px;
		width: 100%;
	}

	.product-detail__description {
		min-height: 220px;
	}

	.product-detail__info {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		letter-spacing: 0.8px;
	}
}

@media (min-width: 1167px) {
	.product-detail {
		height: auto;
	}

	.product-detail__description {
		min-height: 200px;
	}

	.product-detail__hero-image {
		height: 297px;
		width: 527px;
	}

	.product-detail__info {
		font-size: var(--font-size-s);
		margin-top: 16px;
	}

	.breadcrumb__list {
		padding-bottom: 0;
	}

	.product-detail__product-button-url button {
		margin-top: 32px;
	}
}

@media (min-width: 1366px) {
	.product-detail__title {
		font-size: var(--font-size-xxl);
		font-weight: var(--font-weight-medium);
		line-height: var(--line-height-xxl);
	}

	.product-detail__info {
		font-size: var(--font-size-s);
	}

	.product-detail__hero-image {
		height: 371px;
		width: 658px;
	}

	.product-detail {
		height: auto;
	}

	.product-detail__description {
		min-height: 200px;
	}
}

@media (min-width: 1440px) {
	.product-detail__hero-image {
		height: 440px;
		width: 100%;
	}
}
