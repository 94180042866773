.features-carousel {
	padding: 0 40px;
	margin-top: 32px;
	overflow-x: hidden;
}

.features-carousel-lightbox .ap-modal-content {
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;
	max-height: calc(100% - 64px);
	width: calc(100% - 64px);
}

.features-carousel .slider-list {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
}

.features-carousel__body {
	padding: 0 40px;
	position: relative;
}

.features-carousel__body .slider-control-bottomcenter {
	display: none;
}

.features-carousel__body .slider-control-centerright {
	right: -10px !important;
	top: calc(45% - 20px) !important;
}

.features-carousel__body .slider-control-centerleft {
	left: -40px !important;
	top: calc(45% - 20px) !important;
}

.features-carousel__prev-btn,
.features-carousel__next-btn {
	/* button reset*/
	cursor: pointer;
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	background: transparent;
	color: inherit;
	font: inherit;
	line-height: normal;
	outline: none;
	position: absolute;
	top: 10px;
}

.features-carousel__prev-btn svg,
.features-carousel__next-btn svg {
	color: var(--color-grey-400);
}

.features-carousel__item {
	outline: none;
	background-color: var(--color-grey-100);
	padding: 24px 24px 34px;
	margin: 0 18px 25px 18px;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	transition-property: box-shadow;
	cursor: pointer;
	transition-duration: 300ms;
	transition-timing-function: ease-in-out;
}

.features-carousel .slider-slide:hover .features-carousel__item {
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.features-carousel__item--no-action {
	pointer-events: none;
	cursor: not-allowed;
}

.features-carousel__img-wrapper {
	height: 190px;
	width: 100%;
	text-align: center;
	background: var(--color-grey-100);
	position: relative;
	outline: none;
	color: var(--color-grey-300);
}

.features-carousel__img-wrapper img {
	display: inline-block;
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
	outline: none;
}

.features-carousel__img-title {
	-webkit-line-clamp: 2;
	/*! autoprefixer: off */
	-webkit-box-orient: vertical;
	color: var(--color-grey-700);
	display: -webkit-box;
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-black);
	justify-content: center;
	letter-spacing: 0.3px;
	line-height: var(--line-height-m);
	margin-top: 32px;
	/* max-height: 62px;
	min-height: 62px; */
	overflow: hidden;
	text-transform: uppercase;
}

.features-carousel__img-text {
	-webkit-line-clamp: 3;
	/*! autoprefixer: off */
	-webkit-box-orient: vertical;
	color: var(--color-grey-700);
	display: -webkit-box;
	font-size: var(--font-size-s);
	font-weight: 300;
	height: calc(
		16px * 1.4 * 3 + 16px
	); /* $font-size*$line-height*$lines-to-show + padding-top;  */
	letter-spacing: 0.8px;
	line-height: 1.4;
	overflow: hidden;
	padding-top: 16px;
}

.features-carousel-lightbox h4 {
	color: var(--color-grey-800);
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-bold);
}

.feature-media-container .feature-media-name {
	display: none;
}

@media (max-width: 767px) {
	.features-carousel {
		padding: 0 0 0 16px;
	}

	.features-carousel__body {
		padding: 0;
	}

	.features-carousel__item {
		margin: 0 8px 25px 8px;
		padding: 16px;
	}

	.features-carousel__item.full-size {
		margin: 0 24px 25px 8px;
	}

	.features-carousel__img-title {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
		margin-top: 16px;
		margin-bottom: 8px;
		min-height: 48px;
		text-transform: uppercase;
	}

	.features-carousel__img-text {
		font-size: var(--font-size-xxs);
		line-height: var(--line-height-xxs);
		padding: 0;
	}

	.features-carousel-lightbox .ap-modal-content {
		max-width: -webkit-fit-content;
		max-width: -moz-fit-content;
		max-width: fit-content;
		max-height: 100%;
		width: 100%;
	}

	.features-carousel-lightbox .ap-modal-header {
		padding: 8px;
	}

	.features-carousel-lightbox h4 {
		font-size: var(--font-size-s);
		line-height: var(--line-height-s);
	}

	.features-carousel__body .slider-control-centerleft,
	.features-carousel__body .slider-control-centerright {
		bottom: 10px !important;
		height: 50px !important;
		left: calc(100% - 40px) !important;
		position: relative !important;
		top: auto !important;
		width: 50px !important;
	}

	.features-carousel__body .slider-control-centerleft {
		left: 5px !important;
		float: left;
		right: auto !important;
	}

	.features-carousel__next-btn,
	.features-carousel__prev-btn {
		-webkit-align-items: center;
		        align-items: center;
		color: var(--color-grey-700);
		display: -webkit-flex;
		display: flex;
		font-size: 13px;
		font-weight: 500;
		height: 40px;
		letter-spacing: 0.3px;
		line-height: 13px;
	}

	.features-carousel__body .slider-control-bottomcenter {
		bottom: 31px !important;
		display: block;
	}

	.features-carousel__body .slider-control-bottomcenter ul {
		white-space: nowrap;
	}

	.features-carousel .paging-item {
		max-width: 26px;
		max-height: 26px;
		width: 26px;
		height: 26px;
		overflow: hidden;
	}

	.features-carousel .paging-item button {
		height: 25px !important;
		width: 25px !important;
		max-height: 25px !important;
		max-width: 25px !important;
	}

	.features-carousel .paging-item span.paging-dot {
		box-sizing: border-box !important;
		height: 9px !important;
		width: 9px !important;
		border: 1px solid var(--color-grey-300) !important;
		background: none !important;
	}

	.features-carousel .paging-item.active span.paging-dot {
		background: var(--color-grey-300) !important;
	}
}

.features-carousel-lightbox .ap-modal-body {
	overflow: hidden;
	padding: 0;
}

.features-carousel-lightbox__navigation {
	position: absolute;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.feature-navigation {
	cursor: pointer;
}

.feature-media-container {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	height: 100%;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
}

.feature-media-container .features-media-carousel {
	position: relative;
	height: 100%;
}

.feature-media-container img,
.feature-media-container video {
	max-height: 100%;
	max-width: 100%;
	object-fit: contain;
	border-radius: 1px;
}

.feature-media-description {
	width: 100%;
	padding: 16px 32px;
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	letter-spacing: 0.8px;
	line-height: var(--line-height-xs);
	border-top: 1px solid var(--color-grey-300);
}

.features-media-carousel {
	display: -webkit-flex;
	display: flex;
}

.feature-media-container > div {
	display: -webkit-flex;
	display: flex;
}

.feature-modal-container .slider-slide {
	height: 80vh !important;
}

.feature-modal-container .ap-modal-content {
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;
	max-height: calc(100% - 64px);
	width: calc(100% - 64px);
}

.feature-modal-container .ap-modal-content {
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;
	max-height: 100%;
	width: 100%;
}

.feature-modal-container .ap-modal-body {
	overflow: hidden;
	padding: 0;
}

@media (max-width: 767px) {
	.feature-media-container {
		-webkit-justify-content: unset;
		        justify-content: unset;
		overflow-y: scroll;
	}

	.feature-media-name {
		display: -webkit-flex;
		display: flex;
		color: var(--color-grey-700);
		font-size: var(--font-size-s);
		font-weight: bold;
		letter-spacing: 0.3px;
		line-height: var(--line-height-s);
		padding: 0 16px 16px;
		-webkit-align-self: flex-start;
		        align-self: flex-start;
	}

	.feature-modal-container .ap-modal-wrapper {
		width: 100%;
		height: 100%;
		margin: 0;
		-webkit-align-items: unset;
		        align-items: unset;
	}

	.feature-modal-container .slider {
		height: 100% !important;
	}

	.feature-modal-container .ap-modal-content {
		max-width: unset;
		background-color: var(--color-grey-100);
	}

	.feature-modal-container .ap-modal-body {
		height: 100%;
	}

	.feature-modal-container .ap-modal-header {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row-reverse;
		        flex-direction: row-reverse;
		border-bottom: 0;
	}

	.feature-modal-container .ap-modal-header h4 {
		display: none;
	}

	.feature-media-description {
		border: 0;
	}

	.features-media-carousel {
		display: block;
		padding: 0 16px;
	}

	.feature-media-container > div {
		display: block;
		height: unset !important;
		padding: 0 16px 16px;
	}

	.feature-media-container .feature-media-name {
		display: block;
	}

	.feature-media-container video {
		height: unset !important;
	}

	.feature-media-description {
		padding: 8px 16px 0;
	}

	.feature-modal-container .paging-item.active span.paging-dot {
		background: var(--color-grey-300) !important;
	}

	.feature-modal-container .paging-item span.paging-dot {
		box-sizing: border-box !important;
		height: 9px !important;
		width: 9px !important;
		border: 1px solid var(--color-grey-300) !important;
		background: none !important;
	}
}
