.textarea {
	height: 97px;
	width: 100%;
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	padding: 5px 8px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-800);
	outline: none;
	resize: vertical;
}

.textarea::placeholder {
	color: var(--color-grey-300);
}
