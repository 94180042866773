.editor-page-saving__contianer {
	display: flex;
	justify-content: center;
}

.editor-page-saving__label {
	color: var(--color-grey-400);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	margin-left: 12px;
	margin-right: 51px;
}
