.search-input {
	position: relative;
	height: 48px;
}

.search-input__input {
	width: 100%;
	height: 100%;
	padding: 12px 16px;
	color: var(--color-grey-500);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	border: 1px solid var(--color-grey-300);
	outline: none;
	transition: border 300ms ease-in;
}

.search-input__input:focus, .search-input__input:active{
	border-color: var(--color-grey-400);
}

.search-input__input::placeholder {
	color: var(--color-grey-500);
}

.search-input__search-icon {
	position: absolute;
	right: 10px;
	top: 12px;
}

.search-input__close-button {
	position: absolute;
	right: 10px;
	top: 12px;
}

.search-input__search-icon {
  color: var(--color-grey-400);
	position: absolute;
	right: 10px;
	top: calc(50% - 10px);
}
