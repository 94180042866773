.page-section-title {
  margin-bottom: 24px;
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	color: var(--color-grey-800);
	text-transform: uppercase;
	transition: all 300ms ease-in;
}

.page-section-title--no-margin {
	margin-bottom: 0 !important;
}

@media (min-width: 768px) {
	.page-section-title {
		margin-bottom: 32px;
		font-size: var(--font-size-xl);
		line-height: var(--line-height-xl);
		font-weight: var(--font-weight-medium);
	}
}