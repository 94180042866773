.product-list {
	padding: 0px 40px;
	margin-top: 64px;
}

.product-list__masonry-grid {
	display: flex;
	margin-left: -32px; /* gutter size offset */
	width: auto;
	margin-top: 56px;
}

.product-list__masonry-grid-column {
	padding-left: 32px; /* gutter size */
	background-clip: padding-box;
}

@media (max-width: 480px) {
	.product-when-to-use-label,
	.product-when-to-use-label + .product-intro-text {
		display: none;
	}

	.product-list {
		padding: 0 16px;
	}
}
