.label {
	color: var(--color-grey-500);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	letter-spacing: 0.8px;
	text-transform: uppercase;
}

.label + input,
.label + textarea,
.label + select,
.label + .select {
	margin-top: 8px;
}
