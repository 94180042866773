.reorder-items-dialog {
	height: auto;
	max-height: 75%;
	width: 50%;
}

.reorder-items-dialog .dialog__content {
	overflow-y: auto;
}

.reorder-items-dialog .dialog__content li {
	align-items: center;
	display: flex;
}

.reorder-items-dialog__item-drag-handle {
	margin-right: 20px;
	color: var(--color-grey-200);
}

.reorder-items-dialog .dialog__footer {
	justify-content: space-between;
}

.reorder-items-dialog .dialog__header {
	color: var(--color-grey-800);
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height-m);
}
