.editable-list__item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	user-select: none;
}

.editable-list__item--divider {
	border-bottom: 1px solid var(--color-grey-300);
}

.editable-list__status {
	margin-right: 14px;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	background-color: currentColor;
}

.editable-list__status--active {
	color: #9fda93;
}

.editable-list__status--inactive {
	color: var(--color-grey-300);
}

.editable-list__status--orphan {
	color: var(--color-danger);
}

.editable-list__button {
	outline: 0;
	border: 0;
	text-align: left;
	border-radius: 0;
	flex: 1;
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	padding: 10px 0;
	color: var(--color-grey-700);
	transition: all 333ms var(--timing-function-ease-out-expo);
}

.editable-list__button:disabled {
	pointer-events: none;
}

.editable-list__button:focus,
.editable-list__button:hover {
	color: var(--color-brand);
}

.editable-list__button:active {
	color: var(--color-brand-darkest);
}

.editable-list__footer {
	display: flex;
	padding-top: 16px;
}

.editable-list--dragging {
	margin-bottom: 48px; /*one item height - This needs to be added not to displace the bottom button*/
}

.editable-list__item-drag-handle {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--color-grey-400);
	margin-right: 5px;
}

.editable-list__item-drag-handle:hover {
	color: var(--color-grey-700);
}
