.filter-collapsible {
	border-top: 1px solid var(--color-grey-300);
}

.filter-collapsible:last-child {
	border-bottom: 1px solid var(--color-grey-300);
}

.filter-collapsible__body {
	padding: 0 12px 32px;
}

.filter-collapsible__header {
	display: flex;
	height: 60px;
	padding: 0 12px;
	cursor: pointer;
	justify-content: space-between;
	align-items: center;
}

.filter-collapsible__title {
	color: var(--color-grey-600);
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-medium);
}

.filter-collapsible__icon {
	color: var(--color-grey-600);
}
