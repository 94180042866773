.tippy-popper .tippy-tooltip.light-theme.app-tooltip-theme {
	box-shadow: 0 6px 20px 0 rgba(0,0,0,0.2);
	color: var(--color-grey-700);
}

.tippy-popper .app-tooltip-theme {
	padding: 0;
	border-radius: 1px;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	min-width: 320px;
}

.app-tooltip-theme .tippy-tooltip-content {
	padding: 0 0;
}

.app-dropdown {
	color: var(--color-grey-400);
	animation: color 300ms ease-in;
	cursor: pointer;
}

.app-dropdown.dark-nav:hover,
.app-dropdown.dark-nav.active {
	color: white;
}