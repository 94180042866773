.attachments__section {
	opacity: 0;
  padding: 0 16px;
  margin-top: 48px;
	animation: fadeIn 1000ms var(--timing-function-ease-out-expo) 1 forwards;
}


@media (min-width: 768px) { 
  .attachments__section {
    padding: 0 40px;
    margin-top: 80px;
  }
}