
.edit-products-attachment__section{
  position: relative;
}

.edit-products-attachment__file-row {
	margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-products-attachment__file-link{
  display: block;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	transition: color 333ms var(--timing-function-ease-out-expo);
	text-decoration: underline;
}

.edit-products-attachment__file-link:hover {
	color: var(--color-brand);
}

.edit-products-attachment__file-picker-label {
	transition: color 333ms var(--timing-function-ease-out-expo);
	cursor: pointer;
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
}

.edit-products-attachment__file-picker-label:hover {
	color: var(--color-brand-darkest);
}

.edit-products-attachment__file-picker-input {
	display: none; /*label has htmlFor to open file window*/
}