.area-tools {
	padding-top: 60px;
}

.area-tools__container--dragging {
	padding-bottom: 148px;
	margin-bottom: 15px;
}

.area-tools__product-drag-handle {
	color: var(--color-grey-300);
	display: flex;
	justify-content: center;
	align-items: center;
}

.area-tools__product-drag-handle--disabled {
	color: var(--color-grey-200);
	pointer-events: none;
}

.area-tools__product-media {
	height: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--color-grey-300);
}

.area-tools__product-media.area-tools__product-media--default {
	align-items: center;
	justify-content: center;
}

.area-tools__product-media img,
.area-tools__product-media video {
	max-height: 100px;
	max-width: 100%;
	width: auto;
	height: auto;
}

.area-tools__row {
	display: flex;
	background-color: var(--color-grey-100);
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	margin-top: 16px;
	padding: 24px 40px 15px 24px;
	max-height: 148px;
	min-height: 148px;
	position: relative;
	overflow: hidden;
}

.area-tools__column {
	width: 25%;
	position: relative;
	margin: 8px;
	display: flex;
	flex-direction: column;
}

.area-tools__product-title {
	color: var(--color-grey-700);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	margin-top: 7px;
}

.area-tools__product-info {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-top: auto;
}

.area-tools__product-description {
	padding: 10px 8px;
	background: white;
	border: 1px solid var(--color-grey-300);
	color: var(--color-grey-800);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-regular);
	letter-spacing: 0.8px;
	min-height: 100px;
	min-width: 200px;
	max-height: 100px;
	max-width: 200px;
}

.area-tools__new-product {
	height: 80px;
	margin-top: 24px;
	padding-top: 28px;
	border: 1px solid silver;
	color: var(--color-grey-500);
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	letter-spacing: 0.3px;
	text-align: center;
	display: none;
}

.area-tools__product-button--removed {
	overflow: hidden;
}

.area-tools__product-button--removed .area-tools__row {
	transition: all 0.2s ease-out;
	margin-bottom: -164px;
	opacity: 0;
	border: 2px solid transparent;
}

.area-tools__add-button.active button {
	border: 1px solid transparent;
	background-color: var(--color-grey-100);
	color: var(--color-grey-300);
	cursor: default;
}

.area-tools__add-button.active ~ .area-tools__new-product {
	border: 2px solid var(--color-brand);
	display: block;
}

.area-tools__product-button > div {
	border: 2px solid transparent;
}

.area-tools__product-button.active > div {
	border: 2px solid var(--color-brand);
	background-color: var(--color-grey-100);
}

.area-tools__close-button {
	position: absolute;
	top: 8px;
	right: 0;
}

.area-tools__close-button:hover {
	color: var(--color-danger);
	cursor: pointer;
}
