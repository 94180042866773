.filter-tablet {
	display: flex;
	background-color: var(--color-grey-400);
	border-radius: 16px;
	padding: 4px 12px;
	margin-right: 16px;
	margin-bottom: 8px;
}

.filter-tablet__remove-icon:hover svg {
	fill: white;
}

.filter-tablet .filter-tablet__title {
	color: white;
	font-size: var(--font-size-s);
	letter-spacing: 0.3px;
	line-height: var(--line-height-s);
}

.filter-tablet__remove-icon {
	margin-left: 8px;
	cursor: pointer;
	height: 24px;
	align-self: center;
}
