.title-input {
	padding: 0;
	border: 0;
	border-bottom: 1px solid var(--color-grey-300);
	max-width: 500px;
	width: 100%;
	background-color: transparent;
	transition: border-color 100ms ease-out;
}

.title-input--theme-admin {
	font-size: var(--font-size-xl);
	line-height: var(--line-height-xl);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	margin-bottom: -1px;
	margin-right: 8px;
}

.title-input--theme-dialog {
	font-size: var(--font-size-xl);
	line-height: var(--line-height-xl);
	font-weight: var(--font-weight-bold);
	letter-spacing: 0.3px;
	margin-right: 24px;
}

.title-input:focus {
	border-color: var(--color-grey-700);
	outline: none;
}
