.multi-level-filter {
	display: flex;
	flex-direction: column;
}

.multi-level-filter__radio-group {
	display: flex;
	flex-direction: row;
  flex-wrap: wrap;
  margin: -4px;
}

.multi-level-filter__radio-group + .multi-level-filter__radio-group {
  margin-top: 16px;
}

.multi-level-filter__radio-option {
	position: relative;
	margin: 4px;
	padding: 4px 16px;
	color: var(--color-grey-700);
	font-size: var(--font-size-xxs);
	line-height: var(--line-height-xxs);
	font-weight: var(--font-weight-medium);
	border: 1px solid transparent;
	border-radius: 12px;
	transition-property: border-color, color;
	transition-duration: 300ms;
	cursor: pointer;
}

.multi-level-filter__radio-option label {
	cursor: pointer;
}

.multi-level-filter__radio-option input {
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	cursor: pointer;
	width: 100%;
	height: 100%;
}

.level1 .multi-level-filter__radio-option {
	border-color: var(--color-grey-300);
	text-transform: uppercase;
}

.level2 .multi-level-filter__radio-option {
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
  border: none;
  padding: 0;
  opacity: 0;
  animation: fadeIn 300ms forwards;
}

.level1 .multi-level-filter__radio-option:hover,
.level2 .multi-level-filter__radio-option:hover {
	border-color: var(--color-brand-dark);
	color: var(--color-brand-dark);
}

.level1 .multi-level-filter__radio-option.selected,
.level2 .multi-level-filter__radio-option.selected {
	border-color: var(--color-brand);
	color: var(--color-brand);
}
