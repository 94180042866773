.product-page__space-separator {
	margin-top: 64px;
}

.product-detail__video {
	width: inherit;
	height: inherit;
}

.product-detail__video .video-preview__default-image {
  max-width: 500px;
  box-shadow: none;
}

.product-detail__video.video-preview .button.button--icon {
	left: 0;
}