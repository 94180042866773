.file-picker {
  margin-top: 26px;
}

.file-picker__label {
  transition: color 333ms var(--timing-function-ease-out-expo);
	cursor: pointer;
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
}

.file-picker__label:hover {
	color: var(--color-brand-darkest);
}

.file-picker__input {
  display: none; /*label has htmlFor to open file window*/
}
