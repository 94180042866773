.SingleDatePicker {
	display: block;
	margin-top: 8px;
}

.SingleDatePicker .SingleDatePickerInput {
	width: 100%;
	display: flex;
}

.SingleDatePickerInput .DateInput {
	flex-grow: 1;
}

.SingleDatePickerInput_calendarIcon {
	margin-left: 8.5px;
	margin-top: 2px;
}

.SingleDatePicker .DateInput .DateInput_input {
	padding: 4.5px 8.5px 4.5px 3px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	width: 100%;
}

.product-detail__fieldset-info {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-bottom: 19px;
}

.product-detail__fieldset {
	display: flex;
	padding: 0;
	outline: none;
	border: none;
	margin: 0 0 24px 0;
}