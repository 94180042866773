.filter-checkbox {
	display: inline-block;
	position: relative;
	vertical-align: middle;
}
.filter-checkbox .checkbox-label,
.filter-checkbox .checkbox-title {
	display: inline-block;
	position: relative;
	vertical-align: middle;
}
.filter-checkbox .checkbox-title {
	cursor: pointer;
	padding-right: 5px;
}

.filter-checkbox .checkbox-title.disabled {
	color: var(--color-grey-300);
	cursor: not-allowed;
}

.checkbox-button {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	background-color: var(--color-grey-100);
	border: 2px solid var(--color-grey-300);
	cursor: pointer;
	transition: border 0.1s ease-out, background 0.1s ease-out;
}
.checkbox-button:hover {
	border-color: var(--color-brand-dark);
}
.checkbox-button:hover {
	border-color: var(--color-brand-dark);
}
.checkbox-button.disabled {
	cursor: not-allowed;
	opacity: 0.4;
}
.checkbox-icon {
	fill: none;
	stroke: white;
	opacity: 0;
}

.checkbox-native-input {
	position: absolute;
	opacity: 0;
	left: 0;
	top: 0;
}

.checkbox-native-input:checked + .checkbox-button {
	background-color: var(--color-brand);
	border-color: var(--color-brand);
}

.checkbox-native-input:checked + .checkbox-button:hover {
	border-color: var(--color-brand-dark);
	background-color: var(--color-brand-dark);
}

.checkbox-native-input:checked + .checkbox-button:hover {
	border-color: var(--color-brand-dark);
	background-color: var(--color-brand-dark);
}

.checkbox-native-input:checked + .checkbox-button .checkbox-icon {
	opacity: 1;
}

.checkbox-native-input:checked + .checkbox-button.disabled {
	background-color: var(--color-grey-300);
	border-color: var(--color-grey-300);
	cursor: not-allowed;
}
