.edit-area-tools-section .dropdown__label-row {
	width: 100%;
}

.edit-area-tools-section .dropdown__icon {
	position: absolute;
	right: 5px;
}

.edit-area-tools-section .dropdown {
	margin-top: 5px;
	display: block;
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-800);
	padding: 0 5px;
	position: relative;
	background: white;
}

.edit-area-tools-section .dropdown__label {
	font-size: 16px;
	color: var(--color-grey-800);
	font-weight: var(--font-weight-regular);
}

.edit-area-tools-section__readonly-info {
	color: var(--color-grey-500);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-top: 24px;
	margin-bottom: 24px;
}

.edit-area-tools-section__product-media {
	margin-top: 5px;
	height: 100px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: var(--color-grey-300);
}

.edit-area-tools-section__product-media img,
.edit-area-tools-section__product-media video {
	max-height: 100px;
	max-width: 100%;
	width: auto;
	height: auto;
}

.edit-area-tools-section__product-media-upload {
	color: var(--color-brand);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	font-weight: var(--font-weight-medium);
	letter-spacing: 0.3px;
	margin-top: 26px;
	text-transform: uppercase;
}

.edit-area-tools-section__readonly-info-label {
	color: var(--color-grey-400);
	font-size: var(--font-size-xs);
	line-height: var(--line-height-xs);
	letter-spacing: 0.8px;
	margin-left: 12px;
}

.edit-area-tools-section__readonly-field {
	display: none;
}

.edit-area-tools-section .searchable-select__option--is-focused {
	background: var(--color-grey-100);
}

.edit-area-tools-section .searchable-select__option--is-selected {
	background: var(--color-grey-200);
}