.dialog {
	display: flex;
	flex-direction: column;
	background: white;
	width: 500px;
	height: 340px;
	margin: 10px;
	border-radius: 2px;
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.dialog__header {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	height: 63px;
	padding: 18px 16px 18px 32px;
	border-bottom: 1px solid var(--color-grey-300);
	font-size: var(--font-size-m);
	line-height: var(--line-height-m);
	font-weight: var(--font-weight-bold);
}

.dialog__title {
	font-size: var(--font-size-m);
	font-weight: var(--font-weight-bold);
	line-height: var(--line-height-m);
	color: var(--color-grey-800);
	letter-spacing: 0.3px;
}

.dialog__close-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	width: 24px;
	height: 24px;
	color: var(--color-grey-400);
	appearance: none;
	border: 0;
	padding: 0;
	transition: color 133ms var(--timing-function-ease-out-expo),
		transform 133ms var(--timing-function-ease-out-expo);
	margin-right: 8px;
}

.dialog__close-button:hover,
.dialog__close-button:focus {
	color: var(--color-grey-700);
	transform: scale(1.1);
	outline: none;
}

.dialog__close-button:active {
	transform: scale(1);
}

.dialog__content {
	flex: 1;
	padding: 32px;

	/* NOTE:
		Sometimes this element may contain children which handles its own overflow.
		There's a flexbox item bug which means that when a scrollable element is
		inside an unkown height element, it never scrolls.
		The "min-height" property forces this element to stretch to the remaining
		height and the children can use "height: 100%" and "overflow-y: auto":
		https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336
	*/
	min-height: 0;
}

.dialog__footer {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-end;
	padding: 12px 32px;
	border-top: 1px solid var(--color-grey-300);
}

.dialog__footer button + button {
	margin-left: 16px;
}
