.input {
	border: 1px solid var(--color-grey-300);
	border-radius: 1px;
	padding: 3px 8px;
	font-size: var(--font-size-s);
	line-height: var(--line-height-s);
	color: var(--color-grey-800);
	outline: none;
	width: 100%;
	transition: border 300ms ease-in;
}

.input:focus, .input:active {
	border-color: var(--color-grey-400);
}

.input::placeholder {
	color: var(--color-grey-300);
}