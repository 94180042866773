.video-preview .button.button--icon {
	width: 100%;
	position: absolute;
	height: 100%;
}

.video-preview .button.button--icon svg {
	margin: auto;
}

.video-preview__button-icon {
	color: rgba(0, 0, 0, 0.3);
}

.video-preview__button-icon:hover,
.video-preview__button-icon:focus {
	color: rgba(0, 0, 0, 0.5);
}

.video-preview__button-icon--disabled {
	left: 0;
}

.video-preview__button-icon--disabled:hover,
.video-preview__button-icon--disabled:focus {
	color: rgba(0, 0, 0, 0.3);
}